import { mapActions, mapGetters, mapState } from "vuex";
import formMixin from "@/mixins/form";
import {
  getLocationTitle,
  isHotelPreferred,
  hasLocationPeopleAssigned,
  isLastLocation,
} from "@/helpers/creatorV3/utils";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3Container from "@/pages/page-creator-v3/components/creator-v3-container/creator-v3-container.vue";
import creatorV3RadioContent from "@/pages/page-creator-v3/components/creator-v3-radio-content/creator-v3-radio-content.vue";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3PersonTile from "@/pages/page-creator-v3/components/creator-v3-person-tile/creator-v3-person-tile.vue";
import creatorV3RoomMultiManagement from "../creator-v3-room-multi-management/creator-v3-room-multi-management.vue";
import creatorV3RoomOptionsManagement from "../creator-v3-room-options-management/creator-v3-room-options-management.vue";
import creatorV3ActionAlert from "@/pages/page-creator-v3/components/creator-v3-action-alert/creator-v3-action-alert.vue";
import creatorV3RoomMixin, {
  ROOM_CONFIGURATION_KIND,
  getRoomConfigurationKindLabel,
} from "@/helpers/creatorV3/roomMixin";
import { HOTEL_KINDS } from "@/helpers/creatorV3/accommodationMixin";
import { flatten, every } from "lodash";

export default {
  mixins: [creatorV3RoomMixin, formMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getLocationTitle,
      isHotelPreferred,
      hasLocationPeopleAssigned,
      ROOM_CONFIGURATION_KIND,
      getRoomConfigurationKindLabel,
      showMultiRoomManagementPanel: false,
      showRoomManagementPanel: false,
      selectedRoom: null,
    };
  },
  components: {
    creatorV3Tag,
    creatorV3Tags,
    creatorV3Container,
    creatorV3RadioContent,
    creatorV3OptionsPanel,
    creatorV3PersonTile,
    creatorV3RoomMultiManagement,
    creatorV3RoomOptionsManagement,
    creatorV3ActionAlert,
  },
  computed: {
    ...mapGetters("creatorV3", ["people"]),
    ...mapState("creatorV3", ["offer"]),
    title() {
      return isHotelPreferred(this.location) ? "Pokój" : "Apartament";
    },
    roomTags() {
      return flatten(
        this.location.rooms.map((room) =>
          this.getRoomTags({ location: this.location, room })
        )
      );
    },
    showCopyRoomsConfigurationToOtherLocations() {
      const isOnlyHotels = every(
        this.offer.locations,
        (location) => location.accommodation.preference === HOTEL_KINDS.HOTEL
      );
      const isOnlyApartments = every(
        this.offer.locations,
        (location) =>
          location.accommodation.preference === HOTEL_KINDS.APARTMENT
      );
      return (
        this.offer.locations.length > 1 &&
        !isLastLocation(this.location) &&
        (isOnlyHotels || isOnlyApartments) &&
        this.location.rooms.length > 1 &&
        this.isValid
      );
    },
  },
  methods: {
    ...mapActions("creatorV3", [
      "resetLocationRoomsToDefaults",
      "copyRoomsConfigurationToOtherLocations",
    ]),
    onActionAlertClick() {
      this.copyRoomsConfigurationToOtherLocations(this.location);
    },
  },
  watch: {
    "location.roomConfiguration"(value) {
      this.resetLocationRoomsToDefaults(this.location);
    },
  },
};
