import { mapActions, mapState } from "vuex";
import { MODALS } from "@/store/modules/modal";

const CHECK_INTERVAL = 30 * 1000;

export default {
  computed: {
    ...mapState("plan", ["currentPlan"]),
    pageStyleModifiers() {
      if (this.currentPlan.id && this.currentPlan.background) {
        return {
          "background-image": `url('${this.currentPlan.background}')`,
        };
      }
      return {};
    },
  },
  methods: {
    ...mapActions("plan", ["getPlanBySign", "clearPlan", "checkAvailability"]),
    ...mapActions("modal", ["setCurrentModal"]),
    setupPlanChecker() {
      /*
        uses setup timestamp to send requests in interval to timestamp and if
        plan modification date is greater than timestamp sent popup is shown
       */
      this.timestamp = +new Date();
      this.checkPlan();
      this.interval = setInterval(this.checkPlan, CHECK_INTERVAL);
    },
    showPlanDoesNotExistModalIfApiReturnsError() {
      if (!this.currentPlan.id) {
        this.$router.push({ name: "home" });
      }
      this.setCurrentModal({
        name: MODALS.PLAN_EXPIRED,
      });
    },
    showPlanChanged() {
      this.setCurrentModal({
        name: MODALS.PLAN_CHANGED,
      });
    },
    checkPlan() {
      const options = {
        id: this.currentPlan.id,
        timestamp: this.timestamp,
      };
      this.checkAvailability(options).then((data) => {
        if (!data.is_available) this.showPlanChanged();
      });
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.clearPlan();
  },
};
