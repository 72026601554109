export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "",
    },
  },
  computed: {
    classModifiers() {
      return {
        "confirmation-content": true,
        [`confirmation-content--${this.theme}`]: this.theme,
      };
    },
  },
};
