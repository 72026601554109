export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
    },
  },
  computed: {
    iconModifiers() {
      return {
        "creator-v3-container__icon": true,
        [`creator-v3-container__icon--${this.theme}`]: this.theme,
      };
    },
  },
};
