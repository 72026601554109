import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";

export default {
  components: {
    alertBoxV3,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: Boolean,
    placeholder: String,
    maxLength: Number,
    value: String,
    kind: String,
    required: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    limitExceeded() {
      return this.maxLength
        ? this.maxLength <= (this.value || "").length
        : false;
    },
    classModifiers() {
      return {
        "textarea-v3": true,
        "textarea-v3--has-label": !!this.label,
        [`textarea-v3--${this.theme}`]: this.theme,
        "textarea-v3--is-incorrect": this.incorrect,
        "textarea-v3--is-filled": this.value,
        "textarea-v3--is-focused": this.focus,
        "textarea-v3--is-disabled": this.disabled,
      };
    },
    isOneLine() {
      if (this.kind !== "one-line") return false;
      if (this.value && this.value.length >= 30) return false;
      if (this.value && this.value.indexOf("\n") > -1) return false;
      return true;
    },
  },
  methods: {
    handleBlur(e) {
      this.focus = false;
      this.$emit("blur", e.target.name);
    },
    handleFocus(e) {
      this.focus = true;
      this.$emit("focus", e.target.name);
    },
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
  },
};
