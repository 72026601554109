export default {
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickRegister() {
      this.$emit("changeComponent", "accountRegister");
    },
    clickLogin() {
      this.$emit("changeComponent", "accountLogin");
    },
  },
};
