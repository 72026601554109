import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import multiselect from "@alias-npm/vue-multiselect";
import alertBoxV3 from "../alert-box-v3/alert-box-v3.vue";

const DEBOUNCE_TIME = 500;
const MIN_QUERY_LENGTH = 3;

export default {
  components: {
    alertBoxV3,
    multiselect,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    value: [String, Number],
    label: String,
    theme: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
    disabledAirports: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      focus: false,
      isLoading: false,
      selectedAirports: [],
      airports: [],
      lastQuery: "",
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutDesktop"]),
    showNoResults() {
      return (
        !this.isLoading && (this.lastQuery || "").length >= MIN_QUERY_LENGTH
      );
    },
    classModifiers() {
      return {
        "form-airport-picker-v3": true,
        "form-airport-picker-v3__multiselect": true,
        "form-airport-picker-v3--has-label": !!this.label,
        "form-airport-picker-v3--disabled": this.disabled,
        [`form-airport-picker-v3--${this.theme}`]: this.theme,
        "form-airport-picker-v3--is-empty": this.selectedAirports.length === 0,
        "form-airport-picker-v3--is-filled": this.selectedAirports.length > 0,
        "form-airport-picker-v3--is-max": this.reachedMax,
      };
    },
    reachedMax() {
      return this.selectedAirports.length >= this.max;
    },
  },
  methods: {
    ...mapActions("searching", ["getClosestAirports"]),
    clearAll() {
      this.selectedAirports = [];
    },
    optionLabel(option) {
      return `${option.id} | ${option.name} (${option.distance}km)`;
    },
    selectedAirportsHandler(value) {
      const input = value.map((i) => `${i.name} (${i.id})`).join(", ");
      this.$emit("input", input);
    },
    onOpen() {
      const input = this.$refs.multiselect.$el.querySelector(
        ".multiselect__input"
      );
      if (!input) {
        return;
      }
      this.$nextTick(() => {
        input.value = this.lastQuery;
      });
    },
    remove(index) {
      this.selectedAirports.splice(index, 1);
    },
    disabledAirportsHandler() {
      this.asyncFind(this.lastQuery);
    },
  },
  created() {
    if (this.value) {
      this.selectedAirports = this.value.split(",").map((option) => {
        const [name, id] = option.split(" (");
        return {
          id: id.replace(")", ""),
          name: name.trim(),
        };
      });
    }
    this.asyncFind = debounce(
      (query) => {
        if (!query || query.length < MIN_QUERY_LENGTH) return;
        this.lastQuery = query;
        this.isLoading = true;
        this.getClosestAirports(query).then((response) => {
          const disabledAirportsIds = this.disabledAirports
            ? this.disabledAirports.split(",").map((option) => {
                const id = option.split(" (")[1];
                return id.replace(")", "");
              })
            : [];
          this.airports = response.items.filter(
            (item) => !disabledAirportsIds.includes(item.id)
          );
          this.isLoading = false;
        });
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
  },
  watch: {
    selectedAirports: {
      handler: "selectedAirportsHandler",
    },
    disabledAirports: {
      handler: "disabledAirportsHandler",
    },
  },
};
