import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";

export default {
  mixins: [formMixin],
  components: {
    creatorV3Highlight,
  },
  data() {
    return {
      maxNumberOfAirlines: 5,
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
  methods: {
    getCounterText(items) {
      return `${items.split(",").length} / ${this.maxNumberOfAirlines}`;
    },
  },
};
