import formErrorsV3 from "@/components/v3/form-errors-v3/form-errors-v3.vue";

export default {
  components: {
    formErrorsV3,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: Boolean,
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    incorrect: Boolean,
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    focusable: {
      type: Boolean,
      default: true,
      required: false,
    },
    placeholder: String,
    maxLength: Number,
    max: Number,
    min: Number,
    value: [String, Number],
    theme: {
      type: String,
      default: "",
    },
    onlyDigits: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
      required: false,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    onUpdate: {
      type: Function,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    updateModelOnBlur: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      touched: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        "input-v3": true,
        [`input-v3--${this.theme}`]: this.theme,
        "input-v3--has-label": !!this.label,
        "input-v3--is-incorrect": this.incorrect,
        "input-v3--is-filled": this.value,
        "input-v3--is-focused": this.focus && this.focusable,
        "input-v3--is-disabled": this.disabled,
        "input-v3--hide-border": this.hideBorder,
      };
    },
    hasIcon() {
      return !!this.$slots.icon;
    },
    hasLabelSuffix() {
      return !!this.$slots["label-suffix"];
    },
  },
  methods: {
    handleBlur(e) {
      setTimeout(() => {
        this.focus = false;
        this.$emit("blur", e.target.name);
        if (this.updateModelOnBlur) {
          this.updateValue(e);
        }
      }, 200);
    },
    handleFocus(e) {
      this.focus = true;
      this.touched = true;
      this.$emit("focus", e.target.name);
    },
    updateValue(e) {
      if (this.updateModelOnBlur && this.focus) {
        return;
      }
      const value = this.onlyDigits
        ? e.target.value.replace(/[^\d.]/gi, "")
        : e.target.value;
      this.$emit("input", value);
      if (this.onUpdate) {
        this.onUpdate(value);
      }
    },
    resetValue() {
      this.$refs.input.value = "";
      this.$emit("input", "");
    },
  },
};
