import { mapActions, mapState } from "vuex";
import formMixin from "@/mixins/form";
import {
  getLocationTitle,
  pickAccordionItemByTag,
  isFirstLocation,
  isLastLocation,
} from "@/helpers/creatorV3/utils";
import { TRANSPORT_TO_TYPE, TRANSPORT_TO_TYPE_LABEL } from "@/constants";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3CarMixin from "@/helpers/creatorV3/carMixin";
import creatorV3Accordion from "@/pages/page-creator-v3/components/creator-v3-accordion/creator-v3-accordion.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3ActionAlert from "@/pages/page-creator-v3/components/creator-v3-action-alert/creator-v3-action-alert.vue";

export default {
  mixins: [formMixin, creatorV3CarMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  components: {
    creatorV3Accordion,
    creatorV3ActionAlert,
    creatorV3OptionsPanel,
    creatorV3Tag,
    creatorV3Tags,
  },
  data() {
    return {
      isLastLocation,
      showCarPanel: false,
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
    tags() {
      return this.getCarTags(this.location.car);
    },
    transportToChoices() {
      return [
        TRANSPORT_TO_TYPE.PLANE,
        TRANSPORT_TO_TYPE.INDIVIDUAL,
        TRANSPORT_TO_TYPE.SUGGESTION,
        TRANSPORT_TO_TYPE.CAR,
      ];
    },
    title() {
      return getLocationTitle({ location: this.location, extended: true });
    },
  },
  methods: {
    ...mapActions("creatorV3", ["copyLocationTransportOptionsToOthers"]),
    onActionAlertClick() {
      this.copyLocationTransportOptionsToOthers(this.location);
    },
    onTagSelected(tag) {
      this.showCarPanel = true;

      this.$nextTick(() => {
        pickAccordionItemByTag({
          accordionRef: this.$refs.carItemsAccordion,
          nonZeroIndexTags: [
            this.getCarTagsCarTypes(this.location.car),
            this.getCarTagsCarAdditions(this.location.car),
            this.getCarTagsOthers(this.location.car),
          ],
          tag,
        });
      });
    },
    transportToLabel(type) {
      return TRANSPORT_TO_TYPE_LABEL[type];
    },
    isTransportChoiceDisabled(type) {
      if (!isFirstLocation(this.location)) {
        return false;
      }

      return (
        (!this.offer.withoutFlight && type !== TRANSPORT_TO_TYPE.PLANE) ||
        (this.offer.withoutFlight && type !== TRANSPORT_TO_TYPE.INDIVIDUAL)
      );
    },
  },
};
