import confirmationPageMixin from "../mixin";

export default {
  mixins: [confirmationPageMixin],
  metaInfo() {
    return {
      title: "404",
    };
  },
};
