export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
    },
    checked: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.checked === this.value;
    },
    classModifiers() {
      return {
        "radio-v3": true,
        "radio-v3--disabled": this.disabled,
        "radio-v3--checked": this.isChecked,
      };
    },
  },
  methods: {
    onChange() {
      if (this.disabled) return;
      this.$emit("input", this.checked);
    },
  },
};
