import http from "@/services/api/http";

export default {
  submitOffer(payload) {
    return http.post("offers/submit", payload);
  },
  analyzeOffer(payload) {
    return http.post("offers/creator-analysis", payload);
  },
  getCreatorDataBySign(sign) {
    return http.get(`offers/creator-data/${sign}`);
  },
};
