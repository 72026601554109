import { uniqBy } from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import config from "@/config";
import planPinMixin from "@/mixins/planPin";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import guideNavigationTile from "@/components/guide/guide-navigation-tile/guide-navigation-tile.vue";
import guideNavigationTileSmall from "@/components/guide/guide-navigation-tile-small/guide-navigation-tile-small.vue";
import guideCategory from "@/components/guide/guide-category/guide-category.vue";
import { MODALS } from "@/store/modules/modal";
import { isIos, isAndroid } from "@/helpers/user-agent";
import ls, { KEYS } from "@/helpers/localStorage";

export default {
  mixins: [planPinMixin],
  props: {
    sign: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      default: null,
    },
  },
  components: {
    guideCategory,
    guideNavigationTile,
    guideNavigationTileSmall,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 5.5,
        centeredSlides: false,
        breakpoints: {
          1024: {
            slidesPerView: 4.5,
          },
        },
      },
    };
  },
  computed: {
    ...mapState("plan", ["currentGuide"]),
    ...mapGetters("app", ["isLayoutMobile"]),
    classModifiers() {
      return {
        "page-plan-guide": true,
        "page-plan-guide--no-access": this.guardCheckRequired,
      };
    },
    downloadUrl() {
      return `${config.apiUrl}offers/pdf/guide/${this.sign}`;
    },
    categoryTitle() {
      if (!this.category) return "";
      const category = this.availableCategories.find(
        (i) => i.kind === this.category
      );
      return category ? category.label : "";
    },
    pageStyleModifiers() {
      if (
        this.currentGuide &&
        this.currentGuide.plan &&
        this.currentGuide.plan.background &&
        !this.guardCheckRequired
      ) {
        return {
          "background-image": `url('${this.currentGuide.plan.background}')`,
        };
      }
      return {};
    },
    availableCategories() {
      const categoriesFromGuide = uniqBy(
        this.currentGuide.sections,
        (i) => i.kind
      );
      return categoriesFromGuide.map((i) => {
        return {
          ...i,
          label: i.kind_display,
        };
      });
    },
    availableCategoriesSmallNavigation() {
      return [{ id: 0, label: "", kind: "home" }, ...this.availableCategories];
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    ...mapActions("plan", ["getGuideByPlanSign"]),
    sectionsForCategory(category) {
      return this.currentGuide.sections.filter((i) => i.kind === category);
    },
    categoryHandler(value) {
      if (!value) return;

      this.$nextTick(() => {
        if (!this.$refs.swiper || !this.$refs.swiper.swiper) return;
        const category = this.availableCategoriesSmallNavigation.find(
          (i) => i.kind === value
        );
        const index = this.availableCategoriesSmallNavigation.indexOf(category);
        this.$refs.swiper.swiper.slideTo(index);
        this.activeIndex = index;
      });
    },
  },
  created() {
    this.getGuideByPlanSign(this.sign).then((data) => {
      const showAttachHomeGuidePopup =
        (isIos || isAndroid) && !ls.get(KEYS.SHOW_GUIDE_INFO, false);
      if (showAttachHomeGuidePopup) {
        ls.set(KEYS.SHOW_GUIDE_INFO, true);
        this.setCurrentModal({
          name: MODALS.GUIDE_INFO,
          options: {
            isIos,
            isAndroid,
          },
        });
      }
    });
  },
  watch: {
    category: {
      handler: "categoryHandler",
      immediate: true,
    },
    currentGuide(value) {
      if (value) {
        this.checkPlanAccess(value.plan);
      }
    },
  },
};
