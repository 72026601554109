import { mapGetters } from "vuex";
import loginMixin from "@/mixins/loginMixin";

export default {
  mixins: [loginMixin],
  props: {
    step: {
      type: Number,
      default: -1,
    },
    steps: {
      type: Array,
      require: true,
    },
  },
  computed: {
    ...mapGetters("user", ["isSuperUser"]),
    classModifiers() {
      return {
        "plan-details-side-nav": true,
        "plan-details-side-nav--login-active": this.loginActive,
      };
    },
  },
  watch: {
    loginActive(active) {
      if (!active) {
        this.loginRequired(false);
        this.loginComponent = this.getInitialComponent();
      }
      this.$emit("loginActive", active);
    },
    isLoginRequired(isLoginRequired) {
      this.loginActive = isLoginRequired;
    },
  },
  methods: {
    isStepActive(index) {
      return index <= this.steps.length;
    },
    classModifier(index) {
      return {
        "plan-details-side-nav__list-item": true,
        "plan-details-side-nav__list-item--filled": this.isStepActive(index),
        "plan-details-side-nav__list-item--current": this.step === index,
      };
    },
    jumpToStep(index) {
      if (this.isStepActive(index) || this.isSuperUser) {
        this.$emit("step", index);
      }
    },
  },
};
