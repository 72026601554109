import store from "@/store";

const interceptorResponseSuccess = (response) => {
  const { config } = response;

  if (config.loadingGlobalStarted) {
    store.dispatch("app/decrLoaderCounter");
  }
  return response;
};

const interceptorResponseError = (error) => {
  if (error.config.loadingGlobalStarted) {
    store.dispatch("app/decrLoaderCounter");
  }

  if (error.response) {
    console.log("API", error.response.status, error.response.config.url); // eslint-disable-line no-console
  } else if (error.request) {
    console.log("The request was made but no response was received"); // eslint-disable-line no-console
  } else {
    console.log(
      "Something happened in setting up the request that triggered an error"
    ); // eslint-disable-line no-console
  }

  return Promise.reject(error);
};

export { interceptorResponseSuccess, interceptorResponseError };
