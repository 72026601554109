import { mapActions, mapGetters } from "vuex";
import { STATIC_PAGES } from "@/store/modules/staticPage";
import socialButtons from "@/components/social-buttons/social-buttons.vue";
import authRedirectMixin from "@/mixins/authRedirect";
import formMixin from "@/mixins/form";

export default {
  mixins: [authRedirectMixin, formMixin],
  components: {
    socialButtons,
  },
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      STATIC_PAGES,
      showPassword: false,
      fields: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        newsletter: false,
      },
    };
  },
  computed: {
    ...mapGetters("app", ["isInAppFBBrowser"]),
    passwordFieldTypeModifier() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    ...mapActions("staticPage", ["setStaticPage"]),
    ...mapActions("user", ["login", "register", "loginWithOauthToken"]),
    loginAfterRegistration() {
      const payload = {
        email: this.fields.email,
        password: this.fields.password,
      };
      return this.login(payload);
    },
    registrationSuccessful() {
      this.stayOrGoToCreator();
      this.$emit("changeComponent", "accountCreated");
    },
    onSubmit() {
      const payload = {
        email: this.fields.email,
        password: this.fields.password,
        first_name: this.fields.firstName,
        last_name: this.fields.lastName,
        has_newsletter: this.fields.newsletter,
      };

      this.formDisable();
      this.register(payload)
        .then(this.loginAfterRegistration)
        .then(this.registrationSuccessful)
        .catch(this.formHandleErrors);
    },
    clickLogin() {
      this.$emit("changeComponent", "accountLogin");
    },
    onSocialSignIn(payload) {
      this.loginWithOauthToken(payload)
        .then(this.registrationSuccessful)
        .catch(this.formHandleErrors);
    },
  },
};
