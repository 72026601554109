import { range } from "lodash";
import formMixin from "@/mixins/form";
import accordionToggles from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-toggles/accordion-toggles.vue";

export default {
  mixins: [formMixin],
  props: {
    stars: {},
    onUpdate: Function,
  },
  components: {
    accordionToggles,
  },
  data() {
    return {
      range,
      starChoices: [1, 2, 3, 4, 5],
      fields: {
        starsValue: this.stars,
      },
    };
  },
  methods: {
    onToggleChange(name, checked) {
      if (checked && !this.isToggleChecked(name)) {
        this.fields.starsValue.push(name);
      } else if (!checked && this.isToggleChecked(name)) {
        const foundIndex = this.fields.starsValue.indexOf(name);
        this.fields.starsValue.splice(foundIndex, 1);
      }

      if (typeof this.onUpdate !== "undefined") {
        this.onUpdate({
          stars: this.fields.starsValue,
        });
      }
    },
    isToggleChecked(name) {
      return this.fields.starsValue.includes(name);
    },
    classModifiers(name) {
      return {
        "accordion-hotel-standard__toggle": true,
        "accordion-hotel-standard__toggle--selected":
          this.isToggleChecked(name),
      };
    },
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          stars: this.fields.starsValue,
        });
      },
      deep: true,
    },
  },
};
