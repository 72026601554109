import http from "@/services/api/http";

export default {
  getInfoTextList() {
    return http.get("searching/info-text-list");
  },
  getClosestAirports(q) {
    return http.get("searching/autocomplete/closest-airports", {
      params: { q },
    });
  },
  getAirlines(q) {
    return http.get("searching/autocomplete/airline", {
      params: { q },
    });
  },
};
