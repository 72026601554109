import { mapActions } from "vuex";
import { MODALS } from "@/store/modules/modal";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    showHint() {
      if (this.disabled) {
        return;
      }
      this.setCurrentModal({
        name: MODALS.HINT,
        options: {
          text: this.text,
          showDontShowAgain: false,
        },
      });
    },
  },
  computed: {
    iconClassModifier() {
      return {
        "hint-v3-text__icon": true,
        "hint-v3-text__icon--disabled": this.disabled,
      };
    },
  },
};
