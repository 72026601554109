export default {
  props: {
    theme: {
      type: String,
    },
  },
  computed: {
    classModifiers() {
      return {
        "creator-v3-tag": true,
        [`creator-v3-tag--${this.theme}`]: this.theme,
      };
    },
  },
};
