import formMixin from "@/mixins/form";
import {
  HOTEL_KINDS,
  HOTEL_KINDS_LABEL,
} from "@/helpers/creatorV3/accommodationMixin";

export default {
  mixins: [formMixin],
  props: {
    preference: {},
    customEnabled: {},
    custom: {},
    onUpdate: Function,
  },
  data() {
    return {
      HOTEL_KINDS,
      HOTEL_KINDS_LABEL,
      choices: [HOTEL_KINDS.HOTEL, HOTEL_KINDS.APARTMENT],
      fields: {
        preferenceValue: this.preference,
        customEnabledValue: this.customEnabled,
        customValue: this.custom,
      },
    };
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          preference: this.fields.preferenceValue,
          customEnabled: this.fields.customEnabledValue,
          custom: this.fields.customValue,
        });
      },
      deep: true,
    },
  },
};
