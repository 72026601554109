const MODAL_OPEN_CLASS = "modal-open";

export default {
  props: {
    closeButton: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "",
    },
    transitionName: {
      type: String,
      default: "modal",
    },
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
    classModifiers() {
      return {
        modal: true,
        [`modal--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onBeforeTransitionEnter() {
      document.documentElement.classList.add(MODAL_OPEN_CLASS);
      document.body.classList.add(MODAL_OPEN_CLASS);
    },
    onAfterTransitionLeave() {
      document.documentElement.classList.remove(MODAL_OPEN_CLASS);
      document.body.classList.remove(MODAL_OPEN_CLASS);
    },
  },
};
