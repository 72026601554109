import { mapGetters, mapState } from "vuex";
import { getCreatorSteps } from "@/helpers/creatorV3/utils";
import creatorV3Container from "@/pages/page-creator-v3/components/creator-v3-container/creator-v3-container.vue";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import config from "@/config";
import creatorV3StepsSummaryMixin from "@/helpers/creatorV3/stepsSummaryMixin";

export default {
  mixins: [creatorV3StepsSummaryMixin],
  components: {
    alertBoxV3,
    creatorV3Container,
  },
  computed: {
    ...mapGetters("user", ["isSuperUser"]),
    ...mapState("creatorV3", ["indexes", "hintStepOverviewNavigationShown"]),
    steps() {
      return getCreatorSteps();
    },
    allowForcefulNavigation() {
      return config.creatorV3.debug || this.isSuperUser;
    },
  },
  methods: {
    // isInNextSteps(index) {
    //   return index > this.indexes.completed + 1;
    // },
    isInNextSteps(index) {
      return index > this.indexes.current;
    },
    stepClassModifier(index) {
      return {
        "creator-v3-steps-overview__step": true,
        "creator-v3-steps-overview__step--current":
          index === this.indexes.current,
        "creator-v3-steps-overview__step--hoverable":
          !this.isInNextSteps(index),
        "creator-v3-steps-overview__step--next": this.isInNextSteps(index),
      };
    },
    bulletClassModifiers(index) {
      return {
        "creator-v3-steps-overview__bullet": true,
        "creator-v3-steps-overview__bullet--filled": !this.isInNextSteps(index),
      };
    },
    iconClassModifier(step) {
      return {
        "creator-v3-steps-overview__icon": true,
        [`creator-v3-steps-overview__icon--${step.name}`]: true,
      };
    },
    showNavigationHint(index) {
      return (
        !this.hintStepOverviewNavigationShown && index === this.indexes.current
      );
    },
    onNavigate(index) {
      if (this.isInNextSteps(index) && !this.allowForcefulNavigation) {
        return;
      }
      this.$emit("navigateToStepIndex", index);
    },
  },
};
