import { mapActions, mapState } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import creatorV3CarMixin from "@/helpers/creatorV3/carMixin";
import { pickAccordionItemByTag } from "@/helpers/creatorV3/utils";
import formMixin from "@/mixins/form";
import { HINT_MODAL, MODALS } from "@/store/modules/modal";
import moment from "moment";
import { DATE_FORMAT_CREATOR_V3, CAR_RENT } from "@/constants";

const stepMeta = {
  name: "car",
  title: "Samochód",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3CarMixin, formMixin],
  data() {
    return {
      ...stepMeta,
      CAR_RENT,
      showOptions: false,
      optionsViewed: false,
    };
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
    tags() {
      return this.getCarTags(this.offer.car);
    },
    isValid() {
      if (this.offer.car.rentalType === CAR_RENT.RENT) {
        return this.isDriverAgeValid(this.offer.car);
      }
      if (this.offer.car.rentalType === CAR_RENT.CUSTOM) {
        return (
          this.offer.car.dateFrom !== null &&
          this.offer.car.dateTo !== null &&
          this.isDriverAgeValid(this.offer.car)
        );
      }
      return true;
    },
  },
  methods: {
    ...mapActions("modal", ["setHintModal", "setCurrentModal"]),
    onOptionsClick() {
      this.optionsViewed = true;
      this.showOptions = true;
    },
    onTagSelected(tag) {
      this.onOptionsClick();

      this.$nextTick(() => {
        pickAccordionItemByTag({
          accordionRef: this.$refs.carItemsAccordion,
          nonZeroIndexTags: [
            this.getCarTagsCarTypes(this.offer.car),
            this.getCarTagsCarAdditions(this.offer.car),
            this.getCarTagsOthers(this.offer.car),
          ],
          tag,
        });
      });
    },
    onStepNext() {
      if (!!this.offer.car.rentalType && !this.optionsViewed) {
        this.setHintModal({
          text: "Pamiętaj, że klikając na ‘Wybierz opcje samochodu’ możesz zdefiniować wiek kierowcy, a także rodzaj i wyposażenie samochodu!",
          type: HINT_MODAL.CAR_OPTIONS,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return {
            preventNavigation: true,
          };
        }
      }
    },
    formatDate(date) {
      return date ? moment(date).format(DATE_FORMAT_CREATOR_V3) : null;
    },
    openCalendarModal() {
      this.setCurrentModal({
        name: MODALS.DATE_CALENDAR,
        options: {
          onDateUpdateFn: (val = {}) => {
            this.offer.car.dateFrom = this.formatDate(val.start);
            this.offer.car.dateTo = this.formatDate(val.end);
          },
          dateFrom:
            this.offer.car.dateFrom !== null
              ? moment(this.offer.car.dateFrom, DATE_FORMAT_CREATOR_V3).toDate()
              : null,
          dateTo:
            this.offer.car.dateTo !== null
              ? moment(this.offer.car.dateTo, DATE_FORMAT_CREATOR_V3).toDate()
              : null,
          labelFrom: "Odbiór",
          labelTo: "Zwrot",
          footerMessageFrom: "Wybierz datę odbioru",
          footerMessageTo: "Wybierz datę zwrotu",
          minDate: moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3).toDate(),
          maxDate: moment(this.offer.dateTo, DATE_FORMAT_CREATOR_V3).toDate(),
        },
      });
    },
  },
};
