import { mapState } from "vuex";
import stepMixin from "@/mixins/step";
import formMixin from "@/mixins/form";
import creatorV3Container from "@/pages/page-creator-v3/components/creator-v3-container/creator-v3-container.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import creatorV3Separator from "@/pages/page-creator-v3/components/creator-v3-separator/creator-v3-separator.vue";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3Accordion from "@/pages/page-creator-v3/components/creator-v3-accordion/creator-v3-accordion.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3RadioContent from "@/pages/page-creator-v3/components/creator-v3-radio-content/creator-v3-radio-content.vue";

export default {
  mixins: [formMixin, stepMixin],
  components: {
    creatorV3Highlight,
    creatorV3Container,
    creatorV3Separator,
    creatorV3OptionsPanel,
    creatorV3Accordion,
    creatorV3Tag,
    creatorV3Tags,
    creatorV3RadioContent,
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
};
