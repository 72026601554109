import { uniq } from "lodash";
import planPinGuard from "@/components/plan/plan-pin-guard/plan-pin-guard.vue";
import ls, { KEYS } from "@/helpers/localStorage";

const PLANS_WITH_ACCESS_GRANTED_SIZE = 100;

export default {
  components: {
    planPinGuard,
  },
  data() {
    return {
      guardCheckDone: false,
      guardCheckRequired: false,
    };
  },
  methods: {
    getPlansWithAccessIds() {
      return ls.get(KEYS.PLANS_WITH_ACCESS_GRANTED, []);
    },
    checkPlanAccess(plan) {
      this.guardCheckDone = true;
      this.guardCheckRequired = !this.getPlansWithAccessIds().includes(plan.id);
      return this.guardCheckRequired;
    },
    markPlanWithAccess({ plan }) {
      try {
        /* append value at the beginning of the list and make sure its unique */
        const newResults = uniq([plan.id, ...this.getPlansWithAccessIds()]);
        const result = [...newResults.slice(0, PLANS_WITH_ACCESS_GRANTED_SIZE)];
        ls.set(KEYS.PLANS_WITH_ACCESS_GRANTED, result);
        this.guardCheckRequired = false;
        return result;
      } catch (e) {
        return [];
      }
    },
  },
};
