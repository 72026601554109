import store from "@/store";
import { every, some, sumBy } from "lodash";
import creatorV3People from "@/pages/page-creator-v3/steps/creator-v3-people/creator-v3-people.vue";
import creatorV3Flight from "@/pages/page-creator-v3/steps/creator-v3-flight/creator-v3-flight.vue";
import creatorV3Date from "@/pages/page-creator-v3/steps/creator-v3-date/creator-v3-date.vue";
import creatorV3Budget from "@/pages/page-creator-v3/steps/creator-v3-budget/creator-v3-budget.vue";
import creatorV3ExtraInformation from "@/pages/page-creator-v3/steps/creator-v3-extra-information/creator-v3-extra-information.vue";
import creatorV3Contact from "@/pages/page-creator-v3/steps/creator-v3-contact/creator-v3-contact.vue";
import creatorV3Car from "@/pages/page-creator-v3/steps/creator-v3-car/creator-v3-car.vue";
import creatorV3Destination from "@/pages/page-creator-v3/steps/creator-v3-destination/creator-v3-destination.vue";
import creatorV3Summary from "@/pages/page-creator-v3/steps/creator-v3-summary/creator-v3-summary.vue";
import creatorV3Accommodation from "@/pages/page-creator-v3/steps/creator-v3-accommodation/creator-v3-accommodation.vue";
import creatorV3Room from "@/pages/page-creator-v3/steps/creator-v3-room/creator-v3-room.vue";
import { HOTEL_KINDS } from "@/helpers/creatorV3/accommodationMixin";
import { PEOPLE_TYPE } from "@/constants";

export const getLocationIndex = (location) =>
  store.state.creatorV3.offer.locations.map((i) => i.id).indexOf(location.id);
export const isFirstLocation = (location) => getLocationIndex(location) === 0;
export const isLastLocation = (location) =>
  getLocationIndex(location) ===
  store.state.creatorV3.offer.locations.length - 1;

export const isHotelPreferred = (location) =>
  location.accommodation.preference === HOTEL_KINDS.HOTEL;

export const getLocationTitle = ({ location, extended = false }) => {
  if (extended && location.value) return location.value;
  if (isFirstLocation(location)) return "Pierwsze miejsce pobytu";
  if (isLastLocation(location)) return "Ostatnie miejsce pobytu";
  return `Dodatkowe miejsce ${getLocationIndex(location)}`;
};

export const isLocationSwappableToTop = (location) =>
  !isFirstLocation(location);
export const isLocationSwappableToBottom = (location) =>
  !isLastLocation(location);
export const isLocationRemovable = (location) =>
  isLocationSwappableToBottom(location) && isLocationSwappableToTop(location);
export const getLocationNightsDisplay = (location) => {
  const from = location.nightsRange[0];
  const to = location.nightsRange[1];

  return from === to ? from : `${from}-${to}`;
};

export const hasLocationPeopleAssigned = (location) => {
  const allRoomsHavePersonAssigned = every(
    location.rooms,
    (room) => room.people?.length > 0
  );
  const allRoomHaveAdults = every(
    location.rooms,
    (room) =>
      room.people?.filter((person) => person.type === PEOPLE_TYPE.ADULT)
        .length > 0
  );
  const allPersonsAreAssigned =
    sumBy(location.rooms, (room) => room.people?.length) ===
    store.getters["creatorV3/people"].length;
  return (
    allRoomsHavePersonAssigned && allRoomHaveAdults && allPersonsAreAssigned
  );
};

export const pickAccordionItemByTag = ({
  accordionRef,
  nonZeroIndexTags,
  tag,
}) => {
  let index = 0;

  nonZeroIndexTags.forEach((options, indexAlt) => {
    if (options.includes(tag)) {
      index = indexAlt + 1;
    }
  });

  if (accordionRef && index > 0) {
    accordionRef.onItemSelect(index);
  }
};

export const getCreatorSteps = () => {
  const hasAnyLocationCarPicked = some(
    store.state.creatorV3.offer.locations,
    (i) => i.hasCar
  );

  const results = [
    creatorV3People,
    creatorV3Flight,
    creatorV3Date,
    creatorV3Destination,
  ];

  if (!hasAnyLocationCarPicked) {
    results.push(creatorV3Car);
  }

  results.push(creatorV3Accommodation);
  if (!store.state.creatorV3.offer.withoutHotel) {
    results.push(creatorV3Room);
  }

  results.push(creatorV3Budget);
  results.push(creatorV3ExtraInformation);
  results.push(creatorV3Contact);
  results.push(creatorV3Summary);

  return results;
};
