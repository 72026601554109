import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import creatorV3CarMixin from "@/helpers/creatorV3/carMixin";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";

export default {
  components: {
    alertBoxV3,
  },
  mixins: [formMixin, creatorV3CarMixin],
  props: {
    driverAge30to65: {},
    driverAgeCustom: {},
    onUpdate: Function,
  },
  data() {
    return {
      fields: {
        driverAge30to65Value: this.driverAge30to65,
        driverAgeCustomValue: this.driverAgeCustom,
      },
      showWarning: false,
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          driverAge30to65: this.fields.driverAge30to65Value,
          driverAgeCustom: this.fields.driverAgeCustomValue,
        });
        this.showWarning =
          this.$refs.carDriverAge?.touched &&
          !this.isDriverAgeValid(this.offer.car);
      },
      deep: true,
    },
  },
};
