import { mapActions, mapGetters } from "vuex";
import pageHeaderMenuMobile from "@/components/page-header-menu-mobile/page-header-menu-mobile.vue";
import { STATIC_PAGES } from "@/store/modules/staticPage";
import loginMixin from "@/mixins/loginMixin";

export default {
  mixins: [loginMixin],
  data() {
    return {
      STATIC_PAGES,
      hamburgerActive: false,
    };
  },
  components: {
    pageHeaderMenuMobile,
  },
  watch: {
    $route: function watchRouter() {
      this.hamburgerActive = false;
    },
    hamburgerActive(active) {
      if (active) {
        this.loginActive = false;
      }
    },
    loginActive(active) {
      if (active) {
        this.hamburgerActive = false;
      } else {
        this.loginRequired(false);
        this.loginComponent = this.getInitialComponent();
      }
    },
    isLoginRequired(isLoginRequired) {
      this.loginActive = isLoginRequired;
    },
  },
  computed: {
    ...mapGetters("app", [
      "isLayoutDesktop",
      "isLayoutMobile",
      "areInspirationsEnabled",
    ]),
    loginButtonClassModifiers() {
      return {
        "page-header__login-button": true,
        "page-header__login-button--close":
          this.loginActive && this.isLayoutMobile,
      };
    },
    hamburgerButtonClassModifiers() {
      return {
        "page-header__hamburger-image": true,
        "page-header__hamburger-image--close": this.hamburgerActive,
      };
    },
  },
  methods: {
    ...mapActions("staticPage", ["setStaticPage"]),
    onLoginButtonClick() {
      this.loginActive = !this.loginActive;
    },
    onClose() {
      this.loginActive = false;
    },
    onIconClicked() {
      this.hamburgerActive = !this.hamburgerActive;
    },
  },
};
