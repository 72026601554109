export default {
  props: {
    fixedHeight: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classModifiers() {
      return {
        "creator-v3-highlight": true,
        "creator-v3-highlight--fixed-height": this.fixedHeight,
      };
    },
  },
};
