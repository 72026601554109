import { stripTags } from "@/helpers/utils";

export default {
  props: {
    items: {
      type: Array,
      required: true,
      validator: (value) => value.length > 0,
    },
    activeTab: {
      default: null,
    },
    theme: {
      type: String,
      default: "default",
    },
    hideToggleValue: {
      type: Boolean,
      default: false,
    },
    hideToggleEnabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.activeTab !== null) {
      this.onChange({
        item: this.items.find((i) => i.name === this.activeTab),
      });
    } else {
      this.onChange({ item: this.items[0] });
    }
  },
  data() {
    return {
      stripTags,
      currentTab: this.activeTab,
      isEnabled: this.hideToggleValue,
    };
  },
  computed: {
    classModifiers() {
      return {
        tabs: true,
        "tabs--multiple": this.items.length > 1,
        [`tabs--${this.theme}`]: this.theme,
      };
    },
    hideToggleClassModifiers() {
      return {
        tabs__toggle: true,
        "tabs__toggle--opened": this.isEnabled,
      };
    },
  },
  watch: {
    activeTab(tab) {
      this.onChange({ item: this.items.find((i) => i.name === tab) });
    },
  },
  methods: {
    tabClass(item) {
      return {
        "tabs__link--active": item.name === this.currentTab,
        "tabs__link--disabled": !!item.disabled,
      };
    },
    onChange({ item, initial = true }) {
      if (item.disabled) return;
      this.currentTab = item.name;
      this.$emit("tabChange", item.name);
      if (this.hideToggleEnabled && this.items.length === 1 && !initial) {
        // so user can activeate/deactivate single tabs on click
        this.onToggleClick();
      }
    },
    onToggleClick() {
      this.isEnabled = !this.isEnabled;
      this.$emit("toggleChange", this.isEnabled);
    },
  },
};
