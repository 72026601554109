import api from "@/services/api";
import socialButtons from "@/components/social-buttons/social-buttons.vue";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  components: {
    socialButtons,
  },
  data() {
    return {
      showResetPassword: true,
      payload: {
        password: null,
        password_confirmation: null,
        sign: this.$route.params.sign,
      },
    };
  },
  methods: {
    sendResetPassword() {
      if (this.payload.password) {
        if (this.payload.password !== this.payload.password_confirmation) {
          this.formErrors.push("Hasła się różnią");
          return;
        }
        this.formDisable();
        api.users
          .postResetPassword(this.payload)
          .then(() => {
            this.showResetPassword = false;
            this.formEnable();
          })
          .catch(() => {
            this.formErrors.push(
              "Błędny link do resetowania hasła lub link wygasł."
            );
            this.formEnable();
          });
      }
    },
    clickLogin() {
      this.$emit("changeComponent", "accountLogin");
      this.$router.replace({ name: "home" });
    },
  },
};
