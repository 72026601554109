import stepMixin from "@/mixins/step";

export const DEFAULT_STEPPER = {
  // main creator flow
  firstLevelStepsCount: 0,
  firstLevelCurrentStepIndex: 0,
  firstLevelEdgeStepIndex: 0,
  // inside steps
  secondLevelStepsCount: 0,
  secondLevelCurrentStepIndex: 0,
  secondLevelEdgeStepIndex: 0,
  // step multiple elements like places or rooms
  thirdLevelStepsCount: 0,
  thirdLevelCurrentStepIndex: 0,
};

export default {
  mixins: [stepMixin],
  props: {
    flowIsBackMode: {
      type: Boolean,
      default: false,
    },
    stepper: {
      type: Object,
      default: () => {
        return { ...DEFAULT_STEPPER };
      },
    },
  },
  data() {
    return {
      currentStepIndex: this.initialStepIndex || 0,
      currentStepIsValid: true,
      previousStepIndex: null,
      isBackMode: this.flowIsBackMode,
    };
  },
  computed: {
    isFirstStep() {
      return this.currentStepIndex === 0;
    },
    isLastStep() {
      return this.currentStepIndex === this.lastStepIndex;
    },
    initialStepIndex() {
      return this.isBackMode ? this.lastStepIndex : 0;
    },
    lastStepIndex() {
      return this.steps ? this.steps.length - 1 : 0;
    },
    steps() {
      return [];
    },
    stepsLevel() {
      return null;
    },
    stepsCount() {
      return this.steps.length;
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    nextStep() {
      return this.currentStepIndex < this.lastStepIndex
        ? this.steps[this.currentStepIndex + 1]
        : null;
    },
    previousStep() {
      return this.currentStepIndex > 0
        ? this.steps[this.currentStepIndex - 1]
        : null;
    },
    isValid() {
      return this.currentStepIsValid;
    },
  },
  watch: {
    steps() {
      this.flowStepperChanged();
    },
    currentStepIndex() {
      this.flowStepperChanged();
    },
  },
  methods: {
    setFirstLevelStepsCount(count) {
      this.stepper.firstLevelStepsCount = count;
      this.stepper.secondLevelCurrentStepIndex = 0;
      this.stepper.secondLevelStepsCount = 0;
      this.stepper.thirdLevelCurrentStepIndex = 0;
      this.stepper.thirdLevelStepsCount = 0;
    },
    setFirstLevelCurrentStepIndex(index) {
      this.stepper.secondLevelCurrentStepIndex = index;
      this.stepper.firstLevelEdgeStepIndex =
        index > this.stepper.firstLevelEdgeStepIndex
          ? index
          : this.stepper.firstLevelEdgeStepIndex;
    },
    setSecondLevelStepsCount(count) {
      this.stepper.secondLevelStepsCount = count;
      this.stepper.secondLevelEdgeStepIndex = 0;
    },
    setSecondLevelCurrentStepIndex(index) {
      this.stepper.secondLevelCurrentStepIndex = index;
      this.stepper.secondLevelEdgeStepIndex =
        index > this.stepper.secondLevelEdgeStepIndex
          ? index
          : this.stepper.secondLevelEdgeStepIndex;
    },
    setThirdLevelStepsCount(count) {
      this.stepper.thirdLevelStepsCount = count;
    },
    setThirdLevelCurrentStepIndex(index) {
      this.stepper.thirdLevelCurrentStepIndex = index;
    },
    onStepValidate(value) {
      this.currentStepIsValid = value;
    },
    onStepPrevious() {
      this.isBackMode = true;
      if (
        this.$refs.step &&
        typeof this.$refs.step.onStepPrevious === "function" &&
        this.$refs.step.onStepPrevious()
      ) {
        return true;
      }

      if (this.currentStepIndex === 0) return this.onFirstStepPrevious();

      this.previousStepIndex = this.currentStepIndex;
      this.currentStepIndex -= 1;
      return true;
    },
    async onStepNext() {
      this.isBackMode = false;
      if (
        this.$refs.step &&
        typeof this.$refs.step.onStepNext === "function" &&
        (await this.$refs.step.onStepNext())
      ) {
        return true;
      }

      if (this.currentStepIndex === this.lastStepIndex) {
        return this.onLastStepNext();
      }

      this.previousStepIndex = this.currentStepIndex;
      this.currentStepIndex += 1;
      return true;
    },
    onFirstStepPrevious() {
      return false;
    },
    onLastStepNext() {
      return false;
    },
    flowStepperChanged() {
      switch (this.stepsLevel) {
        case 1:
          this.setFirstLevelStepsCount(this.stepsCount);
          this.setFirstLevelCurrentStepIndex(this.currentStepIndex);
          if (
            this.isBackMode &&
            this.previousStepIndex === null &&
            this.stepper.secondLevelStepsCount > 0
          ) {
            this.setSecondLevelCurrentStepIndex(
              this.stepper.secondLevelStepsCount - 1
            );
          }
          break;
        case 2:
          this.setSecondLevelStepsCount(this.stepsCount);
          this.setSecondLevelCurrentStepIndex(this.currentStepIndex);
          if (
            this.isBackMode &&
            this.previousStepIndex === null &&
            this.stepper.thirdLevelStepsCount > 0
          ) {
            this.setThirdLevelCurrentStepIndex(
              this.stepper.thirdLevelStepsCount - 1
            );
          }
          break;
        case 3:
          this.setThirdLevelStepsCount(this.stepsCount);
          this.setThirdLevelCurrentStepIndex(this.currentStepIndex);
          break;
        default:
          break;
      }
    },
  },
  created() {
    this.currentStepIndex = this.initialStepIndex || 0;
    this.flowStepperChanged();
  },
};
