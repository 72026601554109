import { mapActions, mapGetters, mapState } from "vuex";
import pageFooter from "@/components/page-footer/page-footer.vue";
import config from "@/config";
import { loadScript } from "@/helpers/utils";
import appPartners from "./components/app-partners/app-partners.vue";
import appReviews from "./components/app-reviews/app-reviews.vue";
import imageLandingMobile from "@/assets/v2/images/main-mobile.webp";
import imageLandingDesktop from "@/assets/v2/images/main-desktop.webp";
import imageSection1 from "@/assets/v3/images/section-1.webp";
import imageSection1Mobile from "@/assets/v3/images/section-1-mobile.webp";
import imageSection2 from "@/assets/v3/images/section-2.webp";
import imageSection2Mobile from "@/assets/v3/images/section-2-mobile.webp";
import imageSection3 from "@/assets/v3/images/section-3.webp";
import imageSection3Mobile from "@/assets/v3/images/section-3-mobile.webp";
import imageSection4 from "@/assets/v3/images/section-4.webp";
import imageSection4Mobile from "@/assets/v3/images/section-4-mobile.webp";

const MOBILE_LINE_BALL_SIZE_MARGIN = 50;
const MOBILE_LINE_SHOW_FROM_WIDTH = 320;
const MOBILE_LINE_SHOW_TO_WIDTH = 640;

export default {
  components: {
    appPartners,
    appReviews,
    pageFooter,
  },
  data() {
    return {
      imageLandingMobile,
      imageLandingDesktop,
      imageSection1,
      imageSection1Mobile,
      imageSection2,
      imageSection2Mobile,
      imageSection3,
      imageSection3Mobile,
      imageSection4,
      imageSection4Mobile,
    };
  },
  methods: {
    ...mapActions("app", ["setBackRouter"]),
    clearLine() {
      const line = this.$refs.mobileLine;
      if (!line) return;
      line.style.display = "none";
    },
    resizeHandler() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName("body")[0].clientWidth;
      const shouldHideLine =
        width <= MOBILE_LINE_SHOW_FROM_WIDTH ||
        width >= MOBILE_LINE_SHOW_TO_WIDTH;
      if (shouldHideLine) {
        this.clearLine();
        return;
      }
      const from = this.$refs.scrollFrom;
      const to = this.$refs.scrollTo;
      const line = this.$refs.mobileLine;
      if (!from || !to || !line) {
        this.clearLine();
        return;
      }
      const rectFrom = from.getBoundingClientRect();
      const rectTo = to.getBoundingClientRect();
      const yTop = rectFrom.y + window.scrollY;
      const yBottom = rectTo.y + window.scrollY;

      line.style.display = "block";
      line.style.height = `${yBottom - yTop}px`;
      line.style.top = `${yTop - MOBILE_LINE_BALL_SIZE_MARGIN}px`;
    },
  },
  computed: {
    ...mapGetters("app", ["isLayoutDesktop", "areReviewsEnabled"]),
    ...mapState("app", ["width"]),
    appReviewsKey() {
      return `app-reviews-width-${this.width}`;
    },
    elementToAppearToleranceMargin() {
      return this.isLayoutDesktop ? 150 : 400;
    },
  },
  created() {
    document.body.classList.add("recaptcha-visible");
    loadScript(config.captchaUrl, "grecaptcha");
    this.setBackRouter(this.$router.currentRoute.name);
    document.documentElement.classList.add("no-full-height");
    document.body.classList.add("no-full-height");
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    setTimeout(() => {
      this.resizeHandler();
    }, 1500);
  },
  beforeDestroy() {
    document.documentElement.classList.remove("no-full-height");
    document.body.classList.remove("no-full-height");
    document.removeEventListener("scroll", this.onScroll);
    document.body.classList.remove("recaptcha-visible");
    window.removeEventListener("resize", this.resizeHandler);
  },
};
