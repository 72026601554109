export default {
  logs: process.env.VUE_APP_LOGS === "true",
  basePath: process.env.VUE_APP_BASE_PATH,
  apiUrl: process.env.VUE_APP_API_URL,
  assetsUrl: process.env.VUE_APP_ASSETS_URL,
  googleAppClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  facebookAppClientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
  captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  captchaUrl: `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_CAPTCHA_SITE_KEY}`,
  googleMapsUrl: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GMAPS_CODE}&libraries=places&language=pl&callback=Function.prototype`,
  domainFull: process.env.VUE_APP_DOMAIN_FULL,
  creatorV3: {
    enabled: true,
    debug: process.env.VUE_APP_CREATOR_V3_DEBUG === "true",
  },
  planDetailsV3: {
    enabled: process.env.VUE_APP_PLAN_DETAILS_V3 === "true",
  },
  allianzMaxDisabled: process.env.VUE_APP_ALLIANZ_MAX_DISABLED === "true",
  sentry: {
    enabled: process.env.VUE_APP_SENTRY_ENABLED === "true",
    debug: process.env.VUE_APP_SENTRY_DEBUG === "true",
    dsn: process.env.VUE_APP_SENTRY_DSN,
  },
  authFacebookSdkUrl: "https://connect.facebook.net/en_US/sdk.js",
  authGoogleSdkUrl: "https://apis.google.com/js/platform.js",
  gtm: {
    enabled: process.env.VUE_APP_GTM_TRACKING_ENABLED === "true",
    code: process.env.VUE_APP_GTM_CODE,
  },
};
