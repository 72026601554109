import { flatten } from "lodash";
import store from "@/store";

export const HOTEL_KINDS = {
  HOTEL: "hotel",
  APARTMENT: "apartment",
};

export const HOTEL_KINDS_LABEL = {
  [HOTEL_KINDS.HOTEL]: "Preferuję hotele",
  [HOTEL_KINDS.APARTMENT]: "Preferuję apartamenty",
};

export const HOTEL_LOCATIONS = [
  {
    title: "Centrum",
    name: "center",
  },
  {
    title: "Poza centrum, łatwy dojazd",
    name: "outside",
  },
  {
    title: "Blisko atrakcji",
    name: "party",
  },
  {
    title: "Przy lotnisku",
    name: "airport",
  },
  {
    title: "Przy plaży",
    name: "beach",
  },
  {
    title: "Poza miastem / na łonie natury",
    name: "nature",
  },
];

export const HOTEL_EQUIPMENTS = [
  {
    title: "Wysoka ocena gości",
    name: "high-rating",
  },
  {
    title: "Wi-Fi",
    name: "wifi",
  },
  {
    title: "Basen",
    name: "swimming-pool",
  },
  {
    title: "Parking",
    name: "car-park",
  },
  {
    title: "Transfer z lotniska",
    name: "from-airport",
  },
  {
    title: "Room service",
    name: "room-service",
  },
  {
    title: "Spa",
    name: "spa",
  },
  {
    title: "Fitness",
    name: "fitness",
  },
  {
    title: "Tylko dla dorosłych",
    name: "adults-only",
  },
  {
    title: "Przyjazny dla dzieci",
    name: "child-friendly",
  },
  {
    title: "Przyjazny dla zwierząt",
    name: "pet-friendly",
  },
  {
    title: "Udogodnienia dla niepełnosprawnych",
    name: "facilities",
  },
];

export const getHotelLocationsOptions = (location) => {
  const offer = store.state.creatorV3.offer;
  const settings = store.state.creatorV3.offerSettingsPerLocation;
  const index = offer.locations.map((i) => i.id).indexOf(location.id);
  const params = settings[index];
  return index !== -1 && typeof params !== "undefined"
    ? HOTEL_LOCATIONS.filter((i) =>
        params.hotel_location_districts.includes(i.name)
      )
    : HOTEL_LOCATIONS;
};

export default {
  methods: {
    getAccommodationAccordionItems(location) {
      const accommodation = location.accommodation;
      return [
        {
          title: "Rodzaj",
          component: "accordionHotelKind",
          props: {
            preference: accommodation.preference,
            customEnabled: accommodation.customEnabled,
            custom: accommodation.custom,
            onUpdate: (val) => {
              accommodation.preference = val.preference;
              accommodation.customEnabled = val.customEnabled;
              accommodation.custom = val.custom;
            },
            tags: this.getAccommodationTagsKind(accommodation),
          },
        },
        {
          title: "Standard",
          component: "accordionHotelStandard",
          props: {
            stars: accommodation.stars,
            onUpdate: (val) => {
              accommodation.stars = val.stars;
            },
            tags: this.getAccommodationTagsStandard(accommodation),
          },
        },
        {
          title: "Położenie",
          component: "accordionHotelLocation",
          props: {
            options: getHotelLocationsOptions(location),
            locations: accommodation.locations,
            district: accommodation.district,
            onUpdate: (val) => {
              accommodation.locations = val.locations;
              accommodation.district = val.district;
            },
            tags: this.getAccommodationTagsLocation(accommodation),
          },
        },
        {
          title: "Wyposażenie",
          component: "accordionToggles",
          props: {
            toggles: HOTEL_EQUIPMENTS,
            onUpdate: (val) => {
              accommodation.equipment = val;
            },
            value: accommodation.equipment,
            tags: this.getAccommodationTagsEquipment(accommodation),
          },
        },
        {
          title: "Specjalne potrzeby",
          component: "formInputV3",
          props: {
            label:
              "Napisz nam, jeśli zależy Ci na rzeczy, której nie było na liście.",
            placeholder: "np. wynajem roweru",
            name: "creator-accommodation-special-needs",
            onUpdate: (val) => {
              accommodation.others = val;
            },
            value: accommodation.others,
            tags: this.getAccommodationTagsOthers(accommodation),
          },
        },
      ];
    },
    getAccommodationTagsKind(accommodation) {
      return [
        HOTEL_KINDS_LABEL[accommodation.preference],
        accommodation.customEnabled &&
        accommodation.preference === HOTEL_KINDS.HOTEL
          ? accommodation.custom
          : "",
      ].filter((i) => !!i);
    },
    getAccommodationTagsStandard(accommodation) {
      const results = [];

      for (const stars of accommodation.stars.slice(0).sort()) {
        results.push("&#9733;".repeat(stars));
      }

      return results;
    },
    getAccommodationTagsLocation(accommodation) {
      return [
        ...accommodation.locations.map(
          (item) => HOTEL_LOCATIONS.find((type) => type.name === item).title
        ),
        accommodation.district,
      ].filter((i) => !!i);
    },
    getAccommodationTagsOthers(accommodation) {
      return [accommodation.others].filter((i) => !!i);
    },
    getAccommodationTagsEquipment(accommodation) {
      return accommodation.equipment.map(
        (item) => HOTEL_EQUIPMENTS.find((type) => type.name === item).title
      );
    },
    getAccommodationTags(location) {
      return flatten(
        this.getAccommodationAccordionItems(location).map(
          (item) => item.props.tags
        )
      );
    },
  },
};
