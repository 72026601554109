import formLocationAutocomplete from "@/components/forms/location-autocomplete/location-autocomplete.vue";
import formInput from "@/components/forms/input/input.vue";
import formSubmit from "@/components/forms/submit/submit.vue";
import formCheckbox from "@/components/forms/checkbox/checkbox.vue";
import formRadio from "@/components/forms/radio/radio.vue";
import formQuantity from "@/components/forms/quantity/quantity.vue";
import formDatepicker from "@/components/forms/datepicker/datepicker.vue";
import formTextarea from "@/components/forms/textarea/textarea.vue";
import formErrors from "@/components/forms/errors/errors.vue";
import formSelect from "@/components/forms/select/select.vue";
import formGooglePlacesAutocomplete from "@/components/forms/google-places-autocomplete/google-places-autocomplete.vue";
import formAirportPicker from "@/components/forms/form-airport-picker/form-airport-picker.vue";
import formToggle from "@/components/forms/toggle/toggle.vue";
import formInputV3 from "@/components/v3/input-v3/input-v3.vue";
import formNumberInputV3 from "@/components/v3/number-input-v3/number-input-v3.vue";
import formDatepickerV3 from "@/components/v3/datepicker-v3/datepicker-v3.vue";
import formAirportPickerV3 from "@/components/v3/form-airport-picker-v3/form-airport-picker-v3.vue";
import formAirlinePickerV3 from "@/components/v3/form-airline-picker-v3/form-airline-picker-v3.vue";
import formToggleV3 from "@/components/v3/toggle-v3/toggle-v3.vue";
import formRangeInput from "@/components/generics/range-input/main";
import formRadioV3 from "@/components/v3/radio-v3/radio-v3.vue";
import hintV3Text from "@/components/v3/hint-v3-text/hint-v3-text.vue";
import formTextareaV3 from "@/components/v3/textarea-v3/textarea-v3.vue";
import formLocationSearchV3 from "@/components/v3/location-search-v3/location-search-v3.vue";

const formMixin = {
  components: {
    formLocationAutocomplete,
    formErrors,
    formInput,
    formSubmit,
    formCheckbox,
    formRadio,
    formQuantity,
    formDatepicker,
    formTextarea,
    formGooglePlacesAutocomplete,
    formSelect,
    formAirportPicker,
    formToggle,
    formRangeInput,
    formInputV3,
    formNumberInputV3,
    formDatepickerV3,
    formAirportPickerV3,
    formAirlinePickerV3,
    formToggleV3,
    formRadioV3,
    hintV3Text,
    formTextareaV3,
    formLocationSearchV3,
  },
  data() {
    return {
      formErrors: [],
      formEnabled: true,
    };
  },
  computed: {
    formIsDisabled() {
      return !this.formEnabled;
    },
  },
  methods: {
    formEnable() {
      this.formEnabled = true;
    },
    formDisable() {
      this.formReset();
      this.formEnabled = false;
    },
    formReset() {
      this.formErrors = [];
    },
    formHandleErrors(error) {
      this.formEnable();
      if (error && error.response && error.response.data) {
        Object.values(error.response.data).forEach((errors) => {
          errors.forEach((e) => {
            this.formErrors.push(e);
          });
        });
      }
    },
  },
};

export default formMixin;
