import { mapActions, mapState } from "vuex";
import { every } from "lodash";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3Accordion from "@/pages/page-creator-v3/components/creator-v3-accordion/creator-v3-accordion.vue";
import creatorV3ActionAlert from "@/pages/page-creator-v3/components/creator-v3-action-alert/creator-v3-action-alert.vue";
import {
  getLocationTitle,
  isHotelPreferred,
  isLastLocation,
} from "@/helpers/creatorV3/utils";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3RoomMixin from "@/helpers/creatorV3/roomMixin";
import { HOTEL_KINDS } from "@/helpers/creatorV3/accommodationMixin";

export default {
  mixins: [creatorV3RoomMixin],
  props: {
    room: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },
  components: {
    creatorV3ActionAlert,
    creatorV3Tag,
    creatorV3Accordion,
    creatorV3OptionsPanel,
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
    copyRoomOptionsToOtherLocationsPromptMessage() {
      return `Skopiuj opcje ${
        isHotelPreferred(this.location) ? "pokoju" : "apartamentu"
      } do kolejnych miejsc`;
    },
    copyRoomOptionsToNextRoomsPromptMessage() {
      return `Skopiuj opcje ${
        isHotelPreferred(this.location) ? "pokoju" : "apartamentu"
      } do kolejnych ${
        isHotelPreferred(this.location) ? "pokojów" : "apartamentów"
      } w tym miejscu`;
    },
    showCopyRoomOptionsToOtherLocations() {
      const isOnlyHotels = every(
        this.offer.locations,
        (location) => location.accommodation.preference === HOTEL_KINDS.HOTEL
      );
      const isOnlyApartments = every(
        this.offer.locations,
        (location) =>
          location.accommodation.preference === HOTEL_KINDS.APARTMENT
      );
      return (
        this.offer.locations.length > 1 &&
        !isLastLocation(this.location) &&
        (isOnlyHotels || isOnlyApartments) &&
        this.location.rooms.length === 1
      );
    },
    showCopyRoomOptionsToNextRooms() {
      const roomIndex = this.location.rooms
        .map((i) => i.id)
        .indexOf(this.room.id);
      const isLastRoom = roomIndex === this.location.rooms.length - 1;
      return this.location.rooms.length > 1 && !isLastRoom;
    },
  },
  methods: {
    ...mapActions("creatorV3", [
      "copyRoomOptionsToOtherLocations",
      "copyRoomOptionsToNextRooms",
    ]),
    onCopyRoomOptionsToOtherLocations() {
      this.copyRoomOptionsToOtherLocations({
        location: this.location,
        room: this.room,
      });
    },
    onCopyRoomOptionsToNextRooms() {
      this.copyRoomOptionsToNextRooms({
        location: this.location,
        room: this.room,
      });
    },
  },
  data() {
    return {
      getLocationTitle,
      isHotelPreferred,
    };
  },
};
