import { mapActions, mapState } from "vuex";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fields: {
        firstName: "",
        email: "",
        description: this.description,
      },
    };
  },
  computed: {
    ...mapState("user", ["details"]),
    ...mapState("plan", ["currentPlan"]),
  },
  methods: {
    ...mapActions("plan", ["createSuggestion"]),
    onSubmit() {
      if (this.formIsDisabled) return;
      const payload = {
        first_name: this.fields.firstName,
        email: this.fields.email,
        description: this.fields.description,
      };
      const data = {
        id: this.currentPlan.id,
        payload,
      };
      this.formDisable();
      this.createSuggestion(data)
        .then(() => this.$router.push({ name: "suggestion-confirmation" }))
        .catch(() => this.formEnable());
    },
  },
  mounted() {
    if (!(this.details && this.details.id)) return;
    this.fields.firstName = this.details.first_name;
    this.fields.email = this.details.email;
  },
};
