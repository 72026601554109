import ls, { KEYS } from "@/helpers/localStorage";
import api from "@/services/api";

const TOKEN_EXPIRATION = 60 * 60 * 24 * 7;

export default {
  namespaced: true,
  getters: {
    isLoggedIn: (state) => !!state.token,
    isSuperUser: (state) => !!state.details.is_superuser,
  },
  state: {
    transactions: [],
    token: ls.get(KEYS.TOKEN),
    isLoginRequired: false,
    details: {},
  },
  mutations: {
    login(state, user) {
      state.token = user.token;
      ls.set(KEYS.TOKEN, user.token, TOKEN_EXPIRATION);
    },
    logout(state) {
      state.token = null;
      state.details = {};
      ls.del(KEYS.TOKEN);
    },
    setToken(state, data) {
      state.token = data;
      ls.set(KEYS.TOKEN, data, TOKEN_EXPIRATION);
    },
    setUserDetails(state, details) {
      state.details = details;
    },
    loginRequired(state, isLoginRequired = true) {
      state.isLoginRequired = isLoginRequired;
    },
    clearUserTransactions(state) {
      state.transactions = [];
    },
    setUserTransactions(state, items) {
      state.transactions = items;
    },
  },
  actions: {
    login({ commit }, payload) {
      return api.users.postUsersToken(payload).then((response) => {
        commit("login", response.data);
        return response.data;
      });
    },
    register({ commit }, payload) {
      return api.users
        .postUsersRegister(payload)
        .then((response) => response.data);
    },
    logout({ commit }) {
      commit("logout");
    },
    setToken({ commit }, payload) {
      commit("setToken", payload);
    },
    getUserDetails({ commit }) {
      return api.users.getUsersDetails().then((response) => {
        commit("setUserDetails", response.data);
        return response.data;
      });
    },
    loginWithOauthToken({ commit }, { provider, accessToken }) {
      return api.users
        .getVerifyOauthAccessToken(provider, accessToken)
        .then((response) => {
          commit("login", response.data);
          return response.data;
        });
    },
    loginRequired({ commit }, isLoginRequired = true) {
      commit("loginRequired", isLoginRequired);
    },
    getUserTransactions({ commit }) {
      return api.plans.getUserTransactions().then((response) => {
        const { transactions } = response.data;
        commit("setUserTransactions", transactions);
        return transactions;
      });
    },
    clearUserTransactions({ commit }) {
      commit("clearUserTransactions");
    },
  },
};
