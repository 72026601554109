import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import routes from "@/router/routes";
import config from "@/config";

Vue.use(Router);
Vue.use(Meta);

const RouterInstance = new Router({
  base: `${config.basePath}`,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.path !== from.path) {
      return { x: 0, y: 0 };
    }
    return {};
  },
  routes,
});

export default RouterInstance;
