/* eslint-disable max-len */

import http from "@/services/api/http";

export default {
  checkPlanPin(id, payload) {
    return http.post(`offers/guard/check/${id}`, payload);
  },
  resendPlanPin(id, payload = {}) {
    return http.post(`offers/guard/resend/${id}`, payload);
  },
};
