import formToggleV3 from "@/components/v3/toggle-v3/toggle-v3.vue";
import ls, { KEYS } from "@/helpers/localStorage";

export default {
  components: {
    formToggleV3,
  },
  props: {
    type: {
      type: String,
      required: false,
    },
    showDontShowAgain: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dontShowAgain: false,
    };
  },
  methods: {
    onClose() {
      if (this.type && this.dontShowAgain) {
        // set expiry in 30 days
        const ttl = 30 * 24 * 3600;
        ls.set(KEYS.getModalShownKey(this.type), true, ttl);
      }
      this.$emit("close");
    },
  },
};
