export default {
  props: {
    icon: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: "",
    },
    shouldPreventDefault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    target() {
      return this.link === "#" ? "_self" : "_blank";
    },
    classModifiers() {
      return {
        "plan-segment-link": true,
        [`plan-segment-link--${this.theme}`]: this.theme,
      };
    },
    iconClassModifiers() {
      return {
        "plan-segment-link__icon": true,
        [`plan-segment-link__icon--${this.icon}`]: this.icon,
      };
    },
  },
  methods: {
    onClick(event) {
      event.stopPropagation();
      if (this.shouldPreventDefault) {
        event.preventDefault();
      }
      this.$emit("click");
    },
  },
};
