/* eslint-disable max-len */

import http from "@/services/api/http";

export default {
  getEmphasized() {
    return http.get("offers/plans/emphasized");
  },
  getInspirations({
    page = 5,
    perPage = 10,
    countries = [],
    regions = [],
    kinds = [],
    seasons = [],
    isPriceAscending = true,
    term = "",
  }) {
    return http.get("offers/plans/inspirations", {
      params: {
        page,
        perPage,
        countries,
        regions,
        kinds,
        seasons,
        priceAscending: isPriceAscending.toString(),
        term,
      },
    });
  },
  getInspirationsFilters({ regions = [] } = {}) {
    return http.get("offers/plans/inspirations-filters", {
      params: {
        regions,
      },
    });
  },
  getPlanBySign(sign) {
    return http.get(`offers/plans/available-via-link/${sign}`);
  },
  getInspirationBySign(sign) {
    return http.get(`offers/plans/inspirations/available-via-link/${sign}`);
  },
  getCreatorDataBySign(sign) {
    return http.get(`offers/plans/creator-data/${sign}`);
  },
  getGuideByPlanSign(sign) {
    return http.get(`offers/guides/available-via-link/${sign}`);
  },
  checkAvailability(id, timestamp) {
    return http.get(`offers/plans/availability/${id}?timestamp=${timestamp}`);
  },
  checkPromoCode(code) {
    return http.get(`offers/promo-codes/availability/${code}`);
  },
  createSuggestion(id, payload) {
    return http.post(`offers/plans/suggestion/${id}`, payload);
  },
  getUserTransactions() {
    return http.get("offers/user-transactions");
  },
  submitNotInterestedFeedback(sign, payload) {
    return http.post(`offers/question/feedback/${sign}`, payload);
  },
};
