import http from "@/services/api/http";

export default {
  getCreatorDataByWidgetId(widgetId) {
    return http.get(`advertising/widget/creator-data/${widgetId}`);
  },
  signupForNewsletter(payload) {
    return http.post("common/newsletter/signup", payload);
  },
  getReviews() {
    return http.get("common/reviews");
  },
};
