import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    bedrooms: {},
    onUpdate: Function,
  },
  data() {
    return {
      fields: {
        bedroomsValue: this.bedrooms,
      },
    };
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          bedrooms: this.fields.bedroomsValue,
        });
      },
      deep: true,
    },
  },
};
