const ACTION_CLOSE = "close";
const ACTION_RELOAD = "reload";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: "Zamknij",
    },
    buttonAction: {
      type: String,
      required: false,
      default: ACTION_CLOSE,
    },
  },
  methods: {
    onButtonClick() {
      switch (this.buttonAction) {
        case ACTION_CLOSE:
          this.$emit("close");
          break;
        case ACTION_RELOAD:
          window.location.reload();
          break;
        default:
          break;
      }
    },
  },
};
