/* eslint-disable arrow-body-style */

export const saveFile = ({ file, filename }) => {
  const a = document.createElement("a");
  a.href = file;
  a.style.display = "none";
  a.setAttribute("download", "fileName");
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  a.parentNode.removeChild(a);
};

export const exportToJsonFile = ({ data, filename = "data.json" }) => {
  const userAgent = navigator.userAgent;
  const stringifiedData = JSON.stringify(data);
  const blob = new window.Blob([stringifiedData], {
    type: "application/javascript",
  });

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    window.navigator.msSaveBlob(blob, filename);
  } else if (
    userAgent.indexOf("Safari") !== -1 &&
    userAgent.indexOf("Chrom") === -1
  ) {
    const reader = new window.FileReader();
    reader.onloadend = (e) => {
      saveFile({ file: e.target.result, filename });
    };
    reader.readAsDataURL(blob);
  } else {
    const csvURL = window.URL.createObjectURL(blob);
    saveFile({ file: csvURL, filename });
    window.URL.revokeObjectURL(csvURL);
  }
};

export const importFromJsonFile = ({ file }) => {
  return new Promise((resolve, reject) => {
    if (!file) reject(new Error("No File provided"));
    const reader = new window.FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      try {
        const data = JSON.parse(reader.result);
        resolve(data);
      } catch (error) {
        reject(new Error("Failed to parse file"));
      }
    };
  });
};
