var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"creator-v3-location-tile"},[_c('div',{staticClass:"creator-v3-location-tile__container"},[_c('div',{staticClass:"creator-v3-location-tile__indicator",class:{
        'creator-v3-location-tile__indicator--top': _vm.isFirstLocation(_vm.location),
        'creator-v3-location-tile__indicator--middle':
          !_vm.isFirstLocation(_vm.location) && !_vm.isFirstLocation(_vm.location),
        'creator-v3-location-tile__indicator--bottom':
          _vm.isLastLocation(_vm.location),
      }}),_c('div',{staticClass:"creator-v3-location-tile__title"},[_vm._v(" "+_vm._s(_vm.getLocationTitle({ location: _vm.location, extended: false }))+" ")]),_c('div',{staticClass:"creator-v3-location-tile__content"},[_c('form-location-search-v3',{model:{value:(_vm.location.value),callback:function ($$v) {_vm.$set(_vm.location, "value", $$v)},expression:"location.value"}}),_c('div',[_c('span',{staticClass:"creator-v3-location-tile__nights-label"},[_vm._v("Noclegi")]),_c('creator-v3-highlight',{staticClass:"creator-v3-location-tile__highlight"},[_vm._v(" "+_vm._s(_vm.getLocationNightsDisplay(_vm.location))+" ")]),_c('span',{staticClass:"creator-v3-location-tile__nights-link",on:{"click":function($event){_vm.showNightsPanel = true}}},[_vm._v("Zmień")])],1),_c('button',{staticClass:"button-v3 button-v3--secondary button-v3--full-width creator-v3-location-tile__options",on:{"click":_vm.onShowTransportPanel}},[_vm._v(" Wybierz opcje transportu ")]),(_vm.location.transport !== null)?_c('creator-v3-tags',{key:`transport-${_vm.location.id}-${_vm.tags.join()}`,model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}):_vm._e()],1)]),_c('div',{staticClass:"creator-v3-location-tile__controls"},[_c('div',{staticClass:"creator-v3-location-tile__control creator-v3-location-tile__control--up",class:{
        'creator-v3-location-tile__control--visible':
          _vm.isLocationSwappableToTop(_vm.location),
      },on:{"click":function($event){return _vm.$emit('up')}}}),_c('div',{staticClass:"creator-v3-location-tile__control creator-v3-location-tile__control--remove",class:{
        'creator-v3-location-tile__control--visible':
          _vm.isLocationRemovable(_vm.location),
      },on:{"click":function($event){return _vm.$emit('remove')}}}),_c('div',{staticClass:"creator-v3-location-tile__control creator-v3-location-tile__control--down",class:{
        'creator-v3-location-tile__control--visible':
          _vm.isLocationSwappableToBottom(_vm.location),
      },on:{"click":function($event){return _vm.$emit('down')}}})]),(_vm.showNightsPanel)?_c('creator-v3-options-panel',{attrs:{"showOverlay":false,"closeButtonDisabled":!_vm.areLocationNightRangesValid},on:{"close":function($event){_vm.showNightsPanel = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Noclegi ")]},proxy:true},{key:"content",fn:function(){return [_c('creator-v3-locations-nights')]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" Dostosuj liczbę noclegów ")]},proxy:true}],null,false,3235476139)}):_vm._e(),(_vm.showTransportPanel)?_c('creator-v3-options-panel',{attrs:{"showOverlay":false},on:{"close":function($event){_vm.showTransportPanel = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Opcje transportu ")]},proxy:true},{key:"content",fn:function(){return [_c('creator-v3-location-transport',{attrs:{"location":_vm.location}})]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" Wybierz opcje transportu ")]},proxy:true}],null,false,2804064917)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }