import { mapActions, mapGetters, mapState } from "vuex";
import { range } from "lodash";
import moment from "moment";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import creatorV3CarMixin from "@/helpers/creatorV3/carMixin";
import creatorV3LocationTile from "./components/creator-v3-location-tile/creator-v3-location-tile.vue";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import { HINT_MODAL, MODALS, VALIDATION_MODAL } from "@/store/modules/modal";
import {
  getLocationTitle,
  getLocationNightsDisplay,
  getCreatorSteps,
  getLocationIndex,
} from "@/helpers/creatorV3/utils";
import { DATE_FORMAT_CREATOR_V3, TRANSPORT_TO_TYPE_LABEL } from "@/constants";
import planInspirationCtaWidget from "@/components/plan/plan-inspiration-cta-widget/plan-inspiration-cta-widget.vue";

const stepMeta = {
  name: "destination",
  title: "Cel podróży",
};

const MAX_NUMBER_OF_LOCATIONS = 10;

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3CarMixin],
  components: {
    alertBoxV3,
    creatorV3LocationTile,
    planInspirationCtaWidget,
  },
  data() {
    return {
      ...stepMeta,
      MAX_NUMBER_OF_LOCATIONS,
      anyTransportPanelViewed: false,
      showPlanPanel: false,
    };
  },
  computed: {
    ...mapGetters("creatorV3", [
      "isAnyLocationFilled",
      "nightsDisplay",
      "isAnyLocationFilled",
      "areAllLocationsFilled",
    ]),
    ...mapState("modal", ["currentModal"]),
    ...mapState("creatorV3", ["offerSettingsPerLocation"]),
    locationTagInfo() {
      const results = [];

      for (const item of this.offer.locations) {
        let tags = [
          getLocationTitle({ location: item, extended: true }),
          getLocationNightsDisplay(item),
          item.transport ? TRANSPORT_TO_TYPE_LABEL[item.transport] : "",
        ];

        if (item.hasCar) {
          tags = [
            ...tags,
            "Chcę tu wynająć samochód",
            ...this.getCarTags(item.car),
          ];
        }
        results.push({
          id: item.id,
          tags,
        });
      }
      return results.filter((i) => !!i);
    },
    showInvalidNightsForSeveralPlacesWarning() {
      return (
        this.offer.nightsRange[1] === 1 ||
        this.offer.nightsRange[0] === 0 ||
        this.offer.nightsRange[0] === 1
      );
    },
    isValid() {
      return this.areAllLocationsFilled;
    },
    maxLocationQuantityInRespectToTotalReached() {
      return this.offer.locations.length >= MAX_NUMBER_OF_LOCATIONS;
    },
    maxLocationQuantityInRespectToNightsReached() {
      return this.offer.locations.length >= this.offer.nightsRange[0];
    },
    maxLocationsReached() {
      return (
        this.maxLocationQuantityInRespectToTotalReached ||
        this.maxLocationQuantityInRespectToNightsReached
      );
    },
    recommendTargetPlacesField: {
      get() {
        return this.offer.recommendTargetPlaces;
      },
      set(value) {
        this.offer.recommendTargetPlaces = value;
        if (value) {
          this.resetToOneLocation();
        } else {
          this.resetToTwoLocations();
        }
      },
    },
    severalTargetPlacesField: {
      get() {
        return this.offer.severalTargetPlaces;
      },
      set(value) {
        this.offer.severalTargetPlaces = value;
        if (value) {
          this.resetToTwoLocations();
        } else {
          this.offer.recommendTargetPlaces = false;
          this.resetToOneLocation();
        }
      },
    },
    datesMonths() {
      const startMonth =
        moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3).month() + 1;
      const endMonth =
        moment(this.offer.dateTo, DATE_FORMAT_CREATOR_V3).month() + 1;
      if (endMonth >= startMonth) {
        return range(startMonth, endMonth + 1);
      } else {
        return [...range(startMonth, 13), ...range(1, endMonth + 1)];
      }
    },
  },
  methods: {
    ...mapActions("modal", [
      "setHintModal",
      "setValidationModal",
      "clearModal",
    ]),
    ...mapActions("creatorV3", [
      "insertLocation",
      "resetToOneLocation",
      "resetToTwoLocations",
      "moveLocationDown",
      "moveLocationTop",
      "removeLocation",
      "analyzeOffer",
    ]),
    addLocation() {
      if (this.maxLocationsReached) return;
      this.insertLocation();
    },
    closePlanPanel() {
      const shouldShowHint =
        !this.anyTransportPanelViewed && this.isAnyLocationFilled;
      if (shouldShowHint) {
        this.setHintModal({
          text: "Pamiętaj, że klikając na ‘Wybierz opcje transportu’ możesz zdefiniować rodzaj transferu do tego miejsca, wynajem samochodu w tym miejscu, itp.",
          type: HINT_MODAL.TRANSPORT_OPTIONS,
        });
        if (!this.currentModal.name) {
          this.showPlanPanel = false;
        }
      } else {
        this.showPlanPanel = false;
      }
    },
    onTransportPanelShown() {
      this.anyTransportPanelViewed = true;
    },
    getFirstLocationOutOfSeason() {
      for (const location of this.offer.locations) {
        const index = getLocationIndex(location);
        const params = this.offerSettingsPerLocation[index];
        const seasonMonths = (params || {}).season_months || [];
        if (seasonMonths.length === 0) {
          continue;
        }
        const monthsOutOfSeason = this.datesMonths.filter(
          (month) => !seasonMonths.includes(month)
        );
        const isOutOfSeason = monthsOutOfSeason.length;
        if (isOutOfSeason) {
          return {
            value: location.value,
            seasonMonths,
            monthsOutOfSeason,
          };
        }
      }
    },
    async onStepNext() {
      await this.analyzeOffer();
      const location = this.getFirstLocationOutOfSeason();
      if (!location) {
        return;
      }
      this.setValidationModal({
        options: {
          type: VALIDATION_MODAL.DATES_OUT_OF_SEASON,
          customOptions: {
            locationValue: location.value,
            seasonMonthsTags: this.getSeasonMonthsTags(location.seasonMonths),
            monthsOutOfSeason: location.monthsOutOfSeason.map((month) =>
              this.getMonthName(month)
            ),
          },
          rightButtonFn: () => {
            const stepNames = getCreatorSteps().map((step) => step.name);
            const currentStepIndex = stepNames.indexOf("destination");
            this.clearModal();
            this.$emit("step", currentStepIndex + 1);
          },
          rightButtonText: "Nie, idę dalej",
          leftButtonFn: () => {
            const stepNames = getCreatorSteps().map((step) => step.name);
            const stepIndex = stepNames.indexOf("date");
            this.clearModal();
            this.$emit("step", stepIndex);
          },
          leftButtonText: "Zmieniam daty",
          actionsTitle: "Czy chcesz zmienić daty?",
        },
      });
      if (this.currentModal.name === MODALS.VALIDATION) {
        return {
          preventNavigation: true,
        };
      }
    },
    getMonthName(month) {
      return moment()
        .month(month - 1)
        .format("MMMM");
    },
    getSeasonMonthsTags(months) {
      if (months.length === 0) return [];
      if (months.length === 12) return ["cały rok"];

      const periods = [];
      let period_index_from = -1;
      let period_index_to = -1;

      for (let index = 0; index < months.length; index++) {
        if (period_index_from === -1) {
          period_index_from = index;
          period_index_to = index + 1;
        }
        const current = months[index];
        const next = months[index + 1];

        if (typeof next !== "undefined" && next === current + 1) {
          period_index_to += 1;
        } else {
          periods.push(months.slice(period_index_from, period_index_to));
          period_index_from = -1;
          period_index_to = -1;
        }
      }

      return periods.map((period) => {
        return period.length === 1
          ? this.getMonthName(period[0])
          : `${this.getMonthName(period[0])}-${this.getMonthName(
              period[period.length - 1]
            )}`;
      });
    },
  },
};
