import "@/styles/global.scss";

import { get } from "lodash";
import { mapActions, mapState } from "vuex";
import { authorizeApi } from "@/services/api/http";
import pageHeader from "@/components/page-header/page-header.vue";
import appModals from "@/app/app-modals/app-modals.vue";
import loaderMixin from "@/mixins/loader";
import config from "@/config";
import pageFooter from "@/components/page-footer/page-footer.vue";

const RESIZE_HANDLER_TIMEOUT = 500;

export default {
  mixins: [loaderMixin],
  components: {
    appModals,
    pageHeader,
    pageFooter,
  },
  metaInfo() {
    const description =
      "Getaway to osobisty asystent podróży i przyszłość podróżowania. Skorzystaj z kreatora podróży, a nasi eksperci przygotują dla Ciebie indywidualny i całkowicie dowolny plan wyjazdu.";
    const title =
      "Twój asystent i planer podróży, planowanie podróży i wyjazdu, podróże szyte na miarę";
    const image = `${config.domainFull}/static/images/landing-logo.png`;
    return {
      title,
      titleTemplate: "Getaway.pl - %s",
      meta: [
        {
          property: "og:site_name",
          content: "getaway.pl",
          vmid: "og:site_name",
        },
        {
          property: "og:url",
          content: config.domainFull,
          vmid: "og:url",
        },
        {
          property: "og:title",
          content: title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: image,
          vmid: "og:image",
        },
        {
          name: "description",
          content: description,
          vmid: "description",
        },
        {
          name: "twitter:title",
          content: title,
          vmid: "twitter:title",
        },
        {
          name: "twitter:description",
          content: description,
          vmid: "twitter:description",
        },
        {
          name: "twitter:image",
          content: image,
          vmid: "twitter:image",
        },
        {
          name: "keywords",
          content:
            "podróż, organizacja, wyjazd, concierge, asystent, urlop, przygotowanie, wakacje, plan, na miarę, turysta",
          vmid: "keywords",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    hasFullOverflow() {
      return !this.$route.meta.appContentNoFullOverflow;
    },
    hasFullHeight() {
      return !this.$route.meta.appContentNoFullHeight;
    },
    hasFooter() {
      return get(this.$route, "meta.appHasFooter", false);
    },
    hasHeader() {
      return get(this.$route, "meta.appHasHeader", true);
    },
    classModifiers() {
      return {
        app: true,
        "app--full-height": this.hasFullHeight,
        "app--no-header": !this.hasHeader,
        "app--full-overflow": this.hasFullOverflow,
      };
    },
  },
  watch: {
    token(value) {
      authorizeApi(value);
      if (value) {
        this.getUserDetails();
      }
    },
  },
  created() {
    const promises = [this.getSettings(), this.getInfoTextMap()];
    authorizeApi(this.token);
    if (this.token) {
      promises.push(this.getUserDetails());
    }
    Promise.all(promises);
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  methods: {
    ...mapActions("app", ["setLayoutWidth", "getSettings"]),
    ...mapActions("searching", ["getInfoTextMap"]),
    ...mapActions("user", ["getUserDetails"]),
    resizeHandler() {
      setTimeout(() => {
        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.getElementsByTagName("body")[0].clientWidth;
        this.setLayoutWidth(width);
      }, RESIZE_HANDLER_TIMEOUT);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
