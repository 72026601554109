import { debounce } from "lodash";
import http from "@/services/api/http";
import formInput from "@/components/forms/input/input.vue";

const DEBOUNCE_TIME = 500;

export default {
  components: {
    formInput,
  },
  props: {
    minChars: {
      type: Number,
      default: 3,
    },
    source: {
      type: String,
      required: true,
    },
    queryParamName: {
      type: String,
      default: "q",
    },
    limit: {
      type: Number,
      default: 5,
    },
    selectFirst: {
      type: Boolean,
      default: false,
    },
    nameProperty: {
      type: String,
      default: "title",
    },
    placeholder: String,
    label: String,
    theme: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      query: this.value,
      current: -1,
      hasSearched: false,
      loading: false,
      focus: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        input: true,
        "input--loading": this.loading,
        [`input--${this.theme}`]: this.theme,
        "input--incorrect": this.incorrect,
        "input--has-label": !!this.label,
      };
    },
    inputBoxModifiers() {
      return {
        "input__box--is-focused": this.focus,
        "input__box--is-filled": this.value,
      };
    },
    inputModifiers() {
      return {
        input__value: true,
        "input__value--autocomplete": true,
      };
    },
    hasItems() {
      return this.items.length > 0;
    },
    isEmpty() {
      return !this.query;
    },
    isDirty() {
      return !!this.query;
    },
  },
  methods: {
    fetch() {
      const src = this.queryParamName ? this.source : this.source + this.query;

      const params = this.queryParamName
        ? Object.assign({ [this.queryParamName]: this.query }, this.data)
        : this.data;

      const request = http.get(src, { params });
      return Promise.race([request]);
    },
    reset() {
      this.hasSearched = false;
      this.focus = false;
      this.items = [];
      this.loading = false;
    },
    setActive(index) {
      this.current = index;
    },
    activeClass(index) {
      return {
        "autocomplete__item--active": this.current === index,
      };
    },
    hit() {
      if (this.current !== -1) {
        const value = this.items[this.current][this.nameProperty];
        this.query = value;
        this.hasSearched = false;
        this.$emit("hit", this.items[this.current]);
        this.$emit("input", value);
      }
    },
    up() {
      if (this.current > 0) {
        this.current = this.current - 1;
      } else if (this.current === -1) {
        this.current = this.items.length - 1;
      } else {
        this.current = -1;
      }
    },
    down() {
      if (this.current < this.items.length - 1) {
        this.current = this.current + 1;
      } else {
        this.current = -1;
      }
    },
    focused(e) {
      this.focus = true;
      this.$emit("focus", e.target.name);
    },
    itemHighlightedText(item) {
      const text = item[this.nameProperty];
      const indexStart = text.toLowerCase().indexOf(this.query.toLowerCase());
      const indexEnd = indexStart + this.query.length - 1;
      const before = text.slice(0, indexStart);
      const matched = text.slice(indexStart, indexEnd + 1);
      const after = text.slice(indexEnd + 1);
      return `${before}<em>${matched}</em>${after}`;
    },
  },
  created() {
    this.asyncFind = debounce(
      (e) => {
        this.$emit("input", e.target.value);
        if (this.loading) return;
        if (!this.query) {
          this.reset();
          return;
        }
        if (this.minChars && this.query.length < this.minChars) {
          this.items = [];
          return;
        }

        this.loading = true;

        this.fetch()
          .then((response) => {
            if (response && this.query) {
              this.hasSearched = true;
              const data = response.data.items;
              this.items = this.limit ? data.slice(0, this.limit) : data;
              this.current = -1;
              this.loading = false;

              if (this.selectFirst) {
                this.down();
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
  },
};
