import { mapGetters, mapActions } from "vuex";
import ls, { KEYS } from "@/helpers/localStorage";

export default {
  data() {
    return {
      steps: [
        {
          title: "Kreator wyjazdów",
          kind: "creator",
          description:
            "W prosty sposób podaj nam kluczowe informacje o Twojej podróży (miejsce docelowe, liczbę osób, daty, standard hotelu, dodatkowe usługi, budżet, itp.).",
        },
        {
          title: "Propozycja wyjazdu",
          kind: "plan",
          description:
            "Getaway przygotuje specjalnie dla Ciebie propozycję wyjazdu spełniającą Twoje wymagania. Sprawdzimy, wybierzemy i dopasujemy wszystkie dostępne opcje i usługi!",
        },
        {
          title: "Interaktywny przewodnik",
          kind: "guide",
          description:
            "Po zakupie wyjazdu, otrzymasz od nas interaktywny przewodnik na smartfona zawierający wszystkie niezbędne informacje, potwierdzenia i wskazówki.",
        },
      ],
      mobileStepIndex: 0,
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
    mobileStep() {
      return this.steps[this.mobileStepIndex];
    },
    isLastMobileStep() {
      return this.mobileStepIndex === this.steps.length - 1;
    },
  },
  methods: {
    ...mapActions("modal", ["clearModal"]),
    stepImageClassModifiers(step) {
      return {
        "onboarding-modal__step-image": true,
        [`onboarding-modal__step-image--${step.kind}`]: step.kind,
      };
    },
    stepIndicatorClassModifiers(step) {
      return {
        "onboarding-modal__step-indicator": true,
        [`onboarding-modal__step-indicator--${step.kind}`]: step.kind,
      };
    },
    close() {
      ls.set(KEYS.SHOW_ONBOARDING, true);
      this.clearModal();
    },
    next() {
      if (this.isLastMobileStep) {
        return this.close();
      }
      this.mobileStepIndex += 1;
    },
  },
};
