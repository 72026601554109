import formMixin from "@/mixins/form";
import { countries } from "@/helpers/countries";
import moment from "moment/moment";

export default {
  mixins: [formMixin],
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    pesel: {
      type: Boolean,
      default: false,
    },
    visa: {
      type: Boolean,
      default: false,
    },
    passport: {
      type: Boolean,
      default: false,
    },
    eu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const today = moment();
    const yesterday = today.subtract(1, "days");

    return {
      dateOfBirthDisabledDates: {
        from: today.toDate(),
      },
      documentDisabledDates: {
        to: yesterday.toDate(),
      },
      optionsCountries: countries
        .map((i) => {
          return {
            label: i.name,
            value: i.code,
          };
        })
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
  },
  computed: {
    classModifiers() {
      return {
        "plan-person": true,
        "plan-person--preview": this.preview,
      };
    },
    identityCardPlaceholder() {
      return this.passport ? "Numer paszportu" : "Numer dowodu lub paszportu";
    },
  },
};
