import { mapActions } from "vuex";
import { loadScript } from "@/helpers/utils";
import { REGEXP_EMAIL } from "@/constants";
import config from "@/config";

export default {
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isSubmitted: false,
      isLoading: false,
      fields: {
        email: "",
        first_name: "",
      },
    };
  },
  computed: {
    isValid() {
      return (
        !this.isLoading &&
        REGEXP_EMAIL.test(this.fields.email) &&
        !!this.fields.first_name
      );
    },
    classModifiers() {
      return {
        "newsletter-form": true,
        [`newsletter-form--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    ...mapActions("app", ["signupForNewsletter"]),
    submitEntry() {
      this.isLoading = true;
      loadScript(config.captchaUrl, "grecaptcha")
        .then((grecaptcha) => {
          grecaptcha
            .execute(config.captchaSiteKey, { action: "newsletter" })
            .then((token) => {
              this.signupForNewsletter({
                ...this.fields,
                captcha_token: token,
              }).then(() => {
                this.isSubmitted = true;
              });
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
