import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    progressFn: {
      type: Function,
      required: true,
    },
    skipFn: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      owuRead: false,
      peopleConsent: false,
    };
  },
  computed: {
    canProgress() {
      return this.owuRead && this.peopleConsent;
    },
  },
  methods: {
    onProgress() {
      this.progressFn();
    },
    onSkip() {
      this.skipFn();
    },
  },
};
