import { mapActions, mapGetters, mapState } from "vuex";
import accountLogin from "@/components/account/account-login/account-login.vue";
import accountRegister from "@/components/account/account-register/account-register.vue";
import forgotPassword from "@/pages/page-register/forgot-password/forgot-password.vue";
import accountCreated from "@/pages/page-register/account-created/account-created.vue";
import checkMail from "@/pages/page-register/check-mail/check-mail.vue";
import resetPassword from "@/pages/page-register/reset-password/reset-password.vue";
import accountActivated from "@/pages/page-register/account-activated/account-activated.vue";
import myAccount from "@/components/account/my-account/my-account.vue";

export default {
  components: {
    accountLogin,
    accountRegister,
    forgotPassword,
    accountCreated,
    checkMail,
    resetPassword,
    accountActivated,
    myAccount,
  },
  data() {
    return {
      loginActive: false,
      loginComponent: null,
    };
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    ...mapState("user", ["isLoginRequired", "details"]),
  },
  methods: {
    ...mapActions("user", ["logout", "loginRequired"]),
    setLoginActive() {
      this.loginActive = true;
    },
    getInitialComponent() {
      if (this.$router.currentRoute.name === "reset-password") {
        this.loginActive = true;
        return "resetPassword";
      } else if (this.$router.currentRoute.name === "activate") {
        this.loginActive = true;
        return "accountActivated";
      }
      return this.isLoggedIn ? "myAccount" : "accountLogin";
    },
    onChangeComponent(componentName) {
      this.loginComponent = componentName;
    },
    onLogoutUser() {
      this.logout();
      this.loginActive = false;
    },
  },
  mounted() {
    this.loginComponent = this.getInitialComponent();
  },
};
