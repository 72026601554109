import { mapGetters, mapState } from "vuex";
import socialButtons from "@/components/social-buttons/social-buttons.vue";
import userTransactionsTiles from "@/components/user-transactions-tiles/user-transactions-tiles.vue";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  components: {
    socialButtons,
    userTransactionsTiles,
  },
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInputs: false,
      showTravels: false,
      isTileSelected: false,
    };
  },
  computed: {
    ...mapGetters("app", ["areAccountMyTripsEnabled"]),
    ...mapState("user", ["details"]),
    inputModifiers() {
      return {
        "my-account__arrow": !this.showInputs,
        "my-account__arrow--active": this.showInputs,
      };
    },
    travelModifiers() {
      return {
        "my-account__arrow": !this.showTravels,
        "my-account__arrow--active": this.showTravels,
      };
    },
    title() {
      return this.details.first_name
        ? `Cześć, ${this.details.first_name}`
        : "Cześć";
    },
  },
  methods: {
    toggleInputs() {
      this.showInputs = !this.showInputs;
    },
    toggleTravels() {
      this.showTravels = !this.showTravels;
    },
    closeMenu() {
      this.$router.push({ name: "creator" });
      this.$emit("close");
    },
    logout() {
      this.$emit("logoutUser");
    },
    onTileSelected(value) {
      this.isTileSelected = value;
    },
  },
};
