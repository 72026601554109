import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    infoTextMap: null,
  },
  mutations: {
    setInfoTextMap(state, data) {
      const result = data.reduce((obj, item) => {
        obj[item.id] = item.text;
        return obj;
      }, {});
      state.infoTextMap = result;
    },
  },
  actions: {
    getInfoTextMap({ commit }) {
      return api.searching.getInfoTextList().then((response) => {
        commit("setInfoTextMap", response.data);
      });
    },
    getClosestAirports({ commit }, q) {
      return api.searching
        .getClosestAirports(q)
        .then((response) => response.data);
    },
    getAirlines(_, q) {
      return api.searching.getAirlines(q).then((response) => response.data);
    },
  },
};
