import { mapGetters } from "vuex";

export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("plan", ["price", "provision", "totalPrice"]),
    totalPricePerPerson() {
      return this.totalPrice / this.plan.people_count;
    },
  },
};
