import { mapActions, mapState } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import creatorV3FlightMixin from "@/helpers/creatorV3/flightMixin";
import { HINT_MODAL, MODALS } from "@/store/modules/modal";
import { pickAccordionItemByTag } from "@/helpers/creatorV3/utils";

const stepMeta = {
  name: "flight",
  title: "Lot",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3FlightMixin],
  data() {
    return {
      ...stepMeta,
      maxNumberOfCities: 5,
      showOptions: false,
      optionsViewed: false,
    };
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
    isValid() {
      if (this.offer.withoutFlight === true) return true;
      if (this.offer.cityOfDeparture) {
        return true;
      }
      return false;
    },
    classModifier() {
      return {
        "creator-v3-flight": true,
        "creator-v3-flight--without-flight": this.offer.withoutFlight,
      };
    },
    accordionItems() {
      return [
        {
          title: "Klasa lotu",
          component: "accordionFlightClass",
          props: {
            tags: this.tagsFlightClass,
          },
        },
        {
          title: "Miasto powrotu inne niż miasto wylotu",
          component: "accordionCityOfArrival",
          props: {
            tags: this.tagsCityOfArrival,
          },
        },
        {
          title: "Preferowane linie lotnicze",
          component: "accordionPreferredAirlines",
          props: {
            tags: this.tagsFlightLines,
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("modal", ["setHintModal"]),
    ...mapActions("creatorV3", ["recalculateLocationTransports"]),
    getCounterText(cities) {
      return `${cities.split(",").length} / ${this.maxNumberOfCities}`;
    },
    onOptionsClick() {
      this.optionsViewed = true;
      this.showOptions = true;
    },
    onTagSelected(tag) {
      this.onOptionsClick();

      this.$nextTick(() => {
        pickAccordionItemByTag({
          accordionRef: this.$refs.flightItemsAccordion,
          nonZeroIndexTags: [this.tagsCityOfArrival, this.tagsFlightLines],
          tag,
        });
      });
    },
    onStepNext() {
      if (!this.offer.withoutFlight && !this.optionsViewed) {
        this.setHintModal({
          text: "Pamiętaj, że klikając na ‘Wybierz opcje lotu’ możesz wybrać klasę lotu, preferowane loty bezpośrednie i inne opcje!",
          type: HINT_MODAL.FLIGHT_OPTIONS,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return {
            preventNavigation: true,
          };
        }
      }
    },
  },
  watch: {
    "offer.withoutFlight"(value) {
      this.recalculateLocationTransports();
    },
  },
};
