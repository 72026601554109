import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import multiselect from "@alias-npm/vue-multiselect";

const DEBOUNCE_TIME = 500;

export default {
  components: {
    multiselect,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showResetButton: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    value: [String, Number],
    label: String,
    theme: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
    },
  },
  data() {
    return {
      focus: false,
      isActive: false,
      isLoading: false,
      selectedAirports: [],
      airports: [],
      term: "",
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutDesktop"]),
    classModifiers() {
      return {
        "form-airport-picker": true,
        "form-airport-picker--active": this.isActive,
        "form-airport-picker--has-label": !!this.label,
        "form-airport-picker--disabled": this.disabled,
        [`form-airport-picker--${this.theme}`]: this.theme,
        "form-airport-picker--is-empty": this.selectedAirports.length === 0,
        "form-airport-picker--is-filled": this.selectedAirports.length > 0,
      };
    },
  },
  methods: {
    ...mapActions("searching", ["getClosestAirports"]),
    onOpen() {
      this.isActive = true;
    },
    onClose() {
      this.isActive = false;
    },
    limitText(count) {
      return `i ${count} więcej`;
    },
    clearAll() {
      this.selectedAirports = [];
    },
    optionLabel(option) {
      return `${option.id} | ${option.name} (${option.distance}km)`;
    },
    selectedAirportsHandler(value) {
      const input = value.map((i) => `${i.name} (${i.id})`).join(", ");
      this.$emit("input", input);
    },
  },
  created() {
    if (this.value) {
      this.selectedAirports = this.value.split(",").map((option) => {
        const [name, id] = option.split(" (");
        return {
          id: id.replace(")", ""),
          name: name.trim(),
        };
      });
    }
    this.asyncFind = debounce(
      (query) => {
        if (!query || query.length < 3) return;
        this.isLoading = true;
        this.getClosestAirports(query).then((response) => {
          this.airports = response.items;
          this.isLoading = false;
        });
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
  },
  watch: {
    selectedAirports: {
      handler: "selectedAirportsHandler",
    },
  },
};
