import store from "@/store";

const interceptorRequestSetup = (config) => {
  store.dispatch("app/incrLoaderCounter");
  config.loadingGlobalStarted = true;
  return config;
};

const interceptorRequestError = (error) => {
  return Promise.reject(error);
};

export { interceptorRequestSetup, interceptorRequestError };
