import api from "@/services/api";
import socialButtons from "@/components/social-buttons/social-buttons.vue";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  components: {
    socialButtons,
  },
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForgotPassword: true,
      email: "",
    };
  },
  methods: {
    clickRegister() {
      this.$emit("changeComponent", "accountRegister");
    },
    clickLogin() {
      this.$emit("changeComponent", "accountLogin");
    },
    sendToMail() {
      if (this.email) {
        this.formDisable();

        api.users
          .postForgottenPassword({
            email: this.email,
          })
          .then(() => {
            this.showForgotPassword = false;
            this.formEnable();
          })
          .catch(() => {
            this.formErrors.push("Nie znaleziono podanego adresu e-mail");
            this.formEnable();
          });
      }
    },
  },
};
