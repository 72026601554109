import { mapGetters } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  props: {
    step: {
      type: Number,
      default: -1,
    },
    steps: {
      type: Array,
      require: true,
    },
    isValid: {
      type: Boolean,
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2.2,
        touchRatio: 0.2,
        loop: false,
        slideToClickedSlide: false,
      },
    };
  },
  computed: {
    ...mapGetters("user", ["isSuperUser"]),
  },
  methods: {
    stepHandler(index) {
      if (this.$refs.swiper && this.$refs.swiper.swiper) {
        this.$refs.swiper.swiper.slideTo(index);
      }
    },
    isStepActive(index) {
      return index <= this.steps.length;
    },
    classModifier(index) {
      return {
        "plan-details-mobile-nav__item": true,
        "plan-details-mobile-nav__item--filled": this.isStepActive(index),
        "plan-details-mobile-nav__item--current": this.step === index,
      };
    },
  },
  watch: {
    step: {
      handler: "stepHandler",
      immediate: true,
    },
  },
};
