import { mapActions, mapGetters, mapState } from "vuex";
import {
  getLocationTitle,
  isHotelPreferred,
  hasLocationPeopleAssigned,
} from "@/helpers/creatorV3/utils";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import creatorV3RoomMixin from "@/helpers/creatorV3/roomMixin";
import creatorV3PersonTile from "@/pages/page-creator-v3/components/creator-v3-person-tile/creator-v3-person-tile.vue";
import creatorV3RoomPeopleManagement from "../creator-v3-room-people-management/creator-v3-room-people-management.vue";
import creatorV3RoomOptionsManagement from "../creator-v3-room-options-management/creator-v3-room-options-management.vue";
import hintV3Text from "@/components/v3/hint-v3-text/hint-v3-text.vue";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";

export default {
  mixins: [creatorV3RoomMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      getLocationTitle,
      isHotelPreferred,
      hasLocationPeopleAssigned,
      showRoomManagementPanel: false,
      showPeopleManagementPanel: false,
      selectedRoomId: null,
    };
  },
  components: {
    alertBoxV3,
    creatorV3Highlight,
    creatorV3Tag,
    creatorV3Tags,
    creatorV3OptionsPanel,
    creatorV3PersonTile,
    creatorV3RoomPeopleManagement,
    creatorV3RoomOptionsManagement,
    hintV3Text,
  },
  computed: {
    ...mapGetters("creatorV3", ["people"]),
    ...mapState("creatorV3", ["offer", "roomCopyAttempts"]),
    selectedRoom() {
      return this.selectedRoomId !== null
        ? this.location.rooms.find((i) => i.id === this.selectedRoomId)
        : null;
    },
    roomTagInfo() {
      return this.location.rooms.map((item) => {
        return {
          room: item,
          roomCopyAttempts: this.roomCopyAttempts,
          tags: this.getRoomTags({ location: this.location, room: item }),
        };
      });
    },
    maximumNumberOfRoomsReached() {
      return this.location.rooms.length >= this.offer.adults.length;
    },
  },
  methods: {
    ...mapActions("creatorV3", ["addRoom", "removeRoom"]),
    showRoomOptions(roomId) {
      this.selectedRoomId = roomId;
      this.showRoomManagementPanel = true;
    },
  },
};
