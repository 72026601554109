import http from "@/services/api/http";

export default {
  postUsersToken(payload) {
    return http.post("users/token", payload);
  },
  postUsersTokenVerify() {
    return http.get("users/token/verify");
  },
  postUsersRegister(payload) {
    return http.post("users/register", payload);
  },
  getUsersDetails() {
    return http.get("users/details");
  },
  getVerifyOauthAccessToken(provider, token) {
    return http.get(`users/social/jwt/${provider}?access_token=${token}`);
  },
  postForgottenPassword(payload) {
    return http.post("users/forgotten-password", payload);
  },
  postResetPassword(payload) {
    return http.post("users/reset-password", payload);
  },
  postActivate(payload) {
    return http.post("users/activate", payload);
  },
};
