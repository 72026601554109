import { mapActions, mapGetters, mapState } from "vuex";

const FILTERS = {
  REGION: "region",
  PRICE: "price",
  COUNTRY: "country",
  KIND: "kind",
  SEASON: "season",
};

export default {
  data() {
    return {
      FILTERS,
      isSeasonsFilterOpen: false,
      isPricesFilterOpen: false,
      isRegionFilterOpen: false,
      isCountriesFilterOpen: false,
      isKindFilterOpen: false,
    };
  },
  computed: {
    ...mapGetters("app", ["areInspirationSeasonsEnabled"]),
    ...mapState("plan", [
      "inspirationsFilters",
      "selectedRegions",
      "selectedCountries",
      "isPriceAscending",
      "selectedKinds",
      "selectedSeasons",
    ]),
    classModifiers() {
      return {
        "plans-inspirations-filters": true,
        "plans-inspirations-filters--with-seasons":
          this.areInspirationSeasonsEnabled,
      };
    },
    ascendingPriceClassModifiers() {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected": this.isPriceAscending,
      };
    },
    descendingPriceClassModifiers() {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected": !this.isPriceAscending,
      };
    },
    selectedTags() {
      return [
        ...this.selectedRegions.map((i) => {
          return {
            key: `${FILTERS.REGION}-${i}`,
            id: i,
            label: this.inspirationsFilters.regions.find((j) => j.value === i)
              .title,
            filter: FILTERS.REGION,
          };
        }),
        ...this.selectedCountries.map((i) => {
          return {
            key: `${FILTERS.COUNTRY}-${i}`,
            id: i,
            label: i,
            filter: FILTERS.COUNTRY,
          };
        }),
        ...this.selectedKinds.map((i) => {
          return {
            key: `${FILTERS.KIND}-${i}`,
            id: i,
            label: this.inspirationsFilters.kinds.find((j) => j.value === i)
              .title,
            filter: FILTERS.KIND,
          };
        }),
        ...this.selectedSeasons.map((i) => {
          return {
            key: `${FILTERS.SEASON}-${i}`,
            id: i,
            label: this.inspirationsFilters.seasons.find(
              (j) => j.value === parseInt(i, 10)
            ).title,
            filter: FILTERS.SEASON,
          };
        }),
      ];
    },
  },
  methods: {
    ...mapActions("plan", [
      "toggleRegion",
      "togglePrice",
      "toggleCountry",
      "toggleKind",
      "toggleSeason",
      "clearSelectedCountries",
      "clearSelectedRegions",
      "clearSelectedKinds",
      "clearSelectedSeasons",
    ]),
    clearAllFilters() {
      this.isRegionFilterOpen = false;
      this.isCountriesFilterOpen = false;
      this.isKindFilterOpen = false;
      this.isPricesFilterOpen = false;
      this.clearSelectedCountries();
      this.clearSelectedRegions();
      this.clearSelectedKinds();
      this.clearSelectedSeasons();
    },
    clickRegions() {
      this.isCountriesFilterOpen = false;
      this.isKindFilterOpen = false;
      this.isPricesFilterOpen = false;
      this.isSeasonsFilterOpen = false;
      this.isRegionFilterOpen = !this.isRegionFilterOpen;
    },
    clickCountries() {
      this.isRegionFilterOpen = false;
      this.isKindFilterOpen = false;
      this.isPricesFilterOpen = false;
      this.isSeasonsFilterOpen = false;
      this.isCountriesFilterOpen = !this.isCountriesFilterOpen;
    },
    clickKinds() {
      this.isRegionFilterOpen = false;
      this.isCountriesFilterOpen = false;
      this.isPricesFilterOpen = false;
      this.isSeasonsFilterOpen = false;
      this.isKindFilterOpen = !this.isKindFilterOpen;
    },
    clickPrices() {
      this.isRegionFilterOpen = false;
      this.isCountriesFilterOpen = false;
      this.isKindFilterOpen = false;
      this.isSeasonsFilterOpen = false;
      this.isPricesFilterOpen = !this.isPricesFilterOpen;
    },
    clickSeasons() {
      this.isRegionFilterOpen = false;
      this.isCountriesFilterOpen = false;
      this.isKindFilterOpen = false;
      this.isPricesFilterOpen = false;
      this.isSeasonsFilterOpen = !this.isSeasonsFilterOpen;
    },
    filterModifiers(name) {
      let active = false;
      let open = false;

      switch (name) {
        case FILTERS.REGION:
          active = this.selectedRegions.length > 0;
          open = this.isRegionFilterOpen;
          break;
        case FILTERS.PRICE:
          active = this.isPriceAscending;
          open = this.isPricesFilterOpen;
          break;
        case FILTERS.COUNTRY:
          active = this.selectedCountries.length > 0;
          open = this.isCountriesFilterOpen;
          break;
        case FILTERS.KIND:
          active = this.selectedKinds.length > 0;
          open = this.isKindFilterOpen;
          break;
        case FILTERS.SEASON:
          active = this.selectedSeasons.length > 0;
          open = this.isSeasonsFilterOpen;
        default:
          break;
      }
      return {
        "plans-inspirations-filters__filter": true,
        [`plans-inspirations-filters__filter--${name}`]: name,
        "plans-inspirations-filters__filter--active": active,
        "plans-inspirations-filters__filter--opened": open,
      };
    },
    kindOptionClassModifiers(option) {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected":
          this.selectedKinds.includes(option.value),
      };
    },
    regionOptionClassModifiers(option) {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected":
          this.selectedRegions.includes(option.value),
      };
    },
    countryOptionClassModifiers(option) {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected":
          this.selectedCountries.includes(option),
      };
    },
    seasonOptionClassModifiers(option) {
      return {
        "plans-inspirations-filters__option": true,
        "plans-inspirations-filters__option--selected":
          this.selectedSeasons.includes(option.value),
      };
    },
    clickOutsideKinds() {
      this.isKindFilterOpen = false;
    },
    clickOutsideCountry() {
      this.isCountriesFilterOpen = false;
    },
    clickOutsideRegion() {
      this.isRegionFilterOpen = false;
    },
    clickOutsidePrices() {
      this.isPricesFilterOpen = false;
    },
    clickOutsideSeasons() {
      this.isSeasonsFilterOpen = false;
    },
    clearFilter(tag) {
      switch (tag.filter) {
        case FILTERS.REGION:
          this.toggleRegion(
            this.inspirationsFilters.regions.find((i) => i.value === tag.id)
          );
          break;
        case FILTERS.COUNTRY:
          this.toggleCountry(tag.id);
          break;
        case FILTERS.KIND:
          this.toggleKind(
            this.inspirationsFilters.kinds.find((i) => i.value === tag.id)
          );
          break;
        case FILTERS.SEASON:
          this.toggleSeason(
            this.inspirationsFilters.seasons.find((i) => i.value === tag.id)
          );
          break;
        default:
          break;
      }
    },
  },
  beforeDestroy() {
    this.clearSelectedCountries();
    this.clearSelectedRegions();
    this.clearSelectedKinds();
    this.clearSelectedSeasons();
  },
};
