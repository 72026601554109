import { mapGetters } from "vuex";

export default {
  props: {
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    active(active) {
      if (active) {
        document.addEventListener("click", this.clickOutside, false);
      } else {
        document.removeEventListener("click", this.clickOutside, false);
      }
    },
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
    showCloseButton() {
      return this.theme === "simple" ? this.isLayoutMobile : true;
    },
    classModifiers() {
      return {
        "question-tag": true,
        [`question-tag--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    toggleActive() {
      this.active = true;
    },
    toggleInactive() {
      if (this.simple && !this.isLayoutMobile) {
        this.active = false;
      }
    },
    toggle(e) {
      if (!this.simple) {
        e.stopPropagation();
        e.preventDefault();
      }
      this.active = !this.active;
    },
    clickOutside(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.active = false;
      }
    },
  },
};
