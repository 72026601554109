/* eslint-disable arrow-body-style, max-len, no-mixed-operators */

import { every, first, omit, range, sumBy, throttle } from "lodash";
import api from "@/services/api";
import { FIELDS, REGEXP_EMAIL, SEGMENTS } from "@/constants";
import { groupByKeepOrder } from "@/helpers/utils";
import { DEFAULT_COUNTRY } from "@/helpers/countries";

const PRESELECTED_KINDS = [FIELDS.SELECT, FIELDS.RADIO];
function getFieldsAmount(fields) {
  let amount = 0;

  fields.forEach((field) => {
    if (field.markup.selected) {
      switch (field.kind) {
        case FIELDS.SELECT:
          amount += field.markup.selected.value;
          break;
        case FIELDS.RADIO:
          amount += field.markup.selected.value;
          break;
        case FIELDS.CHECKBOX:
          amount += field.markup.options[0].value;
          break;
        default:
          break;
      }
    }
  });

  return amount;
}

export function getSegmentAmount(segment) {
  const baseAmount = parseFloat(segment.amount);
  const fieldsAmount = getFieldsAmount(segment.extra_fields);
  return baseAmount + fieldsAmount;
}

export function hasPlanInsurance(plan) {
  const insuranceSegment = first(
    plan.segments
      .filter((i) => i.selected && !i.skipped)
      .filter((i) => i.kind === SEGMENTS.INSURANCE)
  );

  return insuranceSegment ? getSegmentAmount(insuranceSegment) > 0 : false;
}

const INSPIRATIONS_DEFAULT = {
  data: [],
  page: 0,
  totalCount: 0,
  canLoadMore: true,
};
const INSPIRATIONS_FILTERS_DEFAULT = {
  countries: [],
  regions: [],
  kinds: [],
  seasons: [],
};
const INSPIRATIONS_THROTTLE_TIME = 500;

const Utils = {
  plan: {
    validate(plan) {
      if (!plan.is_available) return false;
      if (!plan.termsAccepted) return false;
      if (plan.hasInvoice && !Utils.invoice.validate(plan.invoice)) {
        return false;
      }
      if (!Utils.person.validate(plan.mainPerson, plan)) return false;
      if (
        plan.otherPersons.length > 0 &&
        !every(plan.otherPersons, (i) => Utils.person.validate(i, plan))
      ) {
        return false;
      }

      return true;
    },
    make(data) {
      const result = Object.assign(
        {
          code: null,
          codeName: "",
          mainPerson: Utils.person.make({ full: true }),
          otherPersons: range(data.people_count - 1).map(() =>
            Utils.person.make({})
          ),
          invoice: Utils.invoice.make(),
          termsAccepted: false,
          hasInvoice: false,
          preview: false,
        },
        data
      );
      // select each first segment from group
      const segments = groupByKeepOrder(result.segments, "section");
      const selectedSegments = segments.map((i) => i[0].id);
      result.segments = result.segments.map((i) => {
        return {
          ...Utils.segment.make(i),
          selected: selectedSegments.includes(i.id),
        };
      });
      return result;
    },
    makePreview(data) {
      const result = Object.assign({}, data);
      result.preview = true;
      result.segments = result.segments
        .filter((i) => i.selected)
        .map((j) => {
          return {
            ...j,
            can_be_deactivated: false,
            extra_fields: j.extra_fields.filter(
              (field) => field.markup.selected
            ),
          };
        });
      return result;
    },
  },
  segment: {
    make(data) {
      return Object.assign(
        {
          skipped: false,
          selected: false,
          extra_fields: data.extra_fields.map((field) => {
            const title = field.markup.title;
            const selected =
              PRESELECTED_KINDS.includes(field.kind) && !field.required
                ? field.markup.options[0]
                : null;

            return {
              ...field,
              label: title,
              name: `${field.id}_${field.kind}`,
              markup: {
                ...field.markup,
                selected,
              },
            };
          }),
        },
        omit(data, ["extra_fields"])
      );
    },
  },
  invoice: {
    validate(invoice) {
      return (
        invoice.name &&
        invoice.address &&
        invoice.zipCode &&
        invoice.city &&
        invoice.nip
      );
    },
    make() {
      return {
        name: "",
        address: "",
        zipCode: "",
        city: "",
        nip: "",
        other: "",
      };
    },
  },
  person: {
    validate(person, plan) {
      if (person.full) {
        return (
          person.firstName &&
          person.lastName &&
          person.dateOfBirth.value !== null &&
          person.email &&
          REGEXP_EMAIL.test(person.email) &&
          person.phoneNumber &&
          // (hasPlanInsurance(plan) ? person.peselNumber : true) &&
          (!plan.trip_to_eu
            ? person.identityCard &&
              person.identityCardExpirationDate.value !== null
            : true) &&
          (plan.requires_visa
            ? person.visaNumber && person.visaExpirationDate.value !== null
            : true)
        );
      }

      return (
        person.firstName &&
        person.lastName &&
        person.dateOfBirth.value !== null &&
        // (hasPlanInsurance(plan) ? person.peselNumber : true) &&
        (!plan.trip_to_eu
          ? person.identityCard &&
            person.identityCardExpirationDate.value !== null
          : true) &&
        (plan.requires_visa
          ? person.visaNumber && person.visaExpirationDate.value !== null
          : true)
      );
    },
    make({ full = false }) {
      return {
        firstName: "",
        lastName: "",
        dateOfBirth: { value: null },
        identityCard: "",
        identityCardExpirationDate: { value: null },
        peselNumber: "",
        phoneNumber: "",
        email: "",
        visaNumber: "",
        visaExpirationDate: { value: null },
        residency: DEFAULT_COUNTRY,
        full,
      };
    },
  },
};

export default {
  namespaced: true,
  state: {
    currentGuide: null,
    currentPlan: {},
    currentTransaction: {},
    emphasizedPlans: [],
    inspirations: Object.create(INSPIRATIONS_DEFAULT),
    inspirationsFilters: Object.create(INSPIRATIONS_FILTERS_DEFAULT),
    selectedCountries: [],
    selectedRegions: [],
    selectedKinds: [],
    selectedSeasons: [],
    isPriceAscending: true,
    searchTerm: "",
  },
  mutations: {
    setPlan(state, payload) {
      state.currentPlan = Utils.plan.make(payload);
    },
    setPlanSimplePreview(state, payload) {
      state.currentPlan = {
        ...Utils.plan.make(payload),
        preview: true,
      };
    },
    setTransaction(state, payload) {
      state.currentTransaction = payload;
      state.currentPlan = Utils.plan.makePreview(payload.plan_data);
    },
    setGuide(state, data) {
      state.currentGuide = data;
    },
    clearPlan(state) {
      state.currentPlan = {};
    },
    clearTransaction(state) {
      state.currentTransaction = {};
    },
    selectSegment(state, { selected, segments }) {
      const indexesToUnselect = segments
        .filter((i) => i.id !== selected.id)
        .map((i) => i.id);
      const indexesToSelect = [selected.id];
      const newSegments = state.currentPlan.segments.slice();

      newSegments.forEach((item) => {
        if (indexesToSelect.includes(item.id)) {
          item.selected = true;
        } else if (indexesToUnselect.includes(item.id)) {
          item.selected = false;
        }
      });
      state.currentPlan = {
        ...state.currentPlan,
        segments: newSegments,
      };
    },
    setPromoCode(state, { code, codeName }) {
      state.currentPlan = {
        ...state.currentPlan,
        code,
        codeName,
      };
    },
    setEmphasized(state, data) {
      state.emphasizedPlans = data;
    },
    clearEmphasized(state) {
      state.emphasizedPlans = [];
    },
    setInspirations(state, payload) {
      const data = state.inspirations.data.concat(payload.results || []);
      const page = payload.page;
      const totalCount = payload.count;
      const canLoadMore = totalCount > data.length;

      state.inspirations = {
        ...state.inspirations,
        data,
        page,
        totalCount,
        canLoadMore,
      };
    },
    clearInspirations(state) {
      state.inspirations = Object.create(INSPIRATIONS_DEFAULT);
    },
    setInspirationsFilters(state, data) {
      state.inspirationsFilters = data;
    },
    setPlanFieldRequiredError(state, { segment, fieldIndex }) {
      const segments = [...(state.currentPlan.segments || [])];
      const segmentIndex = segments.indexOf(segment);
      segment.extra_fields[fieldIndex] = {
        ...segment.extra_fields[fieldIndex],
        error: true,
      };

      segments[segmentIndex] = {
        ...segment,
      };
      state.currentPlan.segments = segments;
    },
    clearPlanFieldRequiredError(state, { segment, fieldIndex }) {
      const segments = [...(state.currentPlan.segments || [])];
      const segmentIndex = segments.indexOf(segment);
      segment.extra_fields[fieldIndex] = {
        ...segment.extra_fields[fieldIndex],
        error: false,
      };

      segments[segmentIndex] = {
        ...segment,
      };
      state.currentPlan.segments = segments;
    },
    togglePrice(state, value) {
      state.isPriceAscending = value;
    },
    toggleKind(state, option) {
      if (state.selectedKinds.includes(option.value)) {
        state.selectedKinds = [
          ...state.selectedKinds.filter((i) => i !== option.value),
        ];
      } else {
        state.selectedKinds = [...state.selectedKinds, option.value];
      }
    },
    toggleRegion(state, option) {
      if (state.selectedRegions.includes(option.value)) {
        state.selectedRegions = [
          ...state.selectedRegions.filter((i) => i !== option.value),
        ];
      } else {
        state.selectedRegions = [...state.selectedRegions, option.value];
      }
    },
    toggleCountry(state, option) {
      if (state.selectedCountries.includes(option)) {
        state.selectedCountries = [
          ...state.selectedCountries.filter((i) => i !== option),
        ];
      } else {
        state.selectedCountries = [...state.selectedCountries, option];
      }
    },
    toggleSeason(state, option) {
      const specialWholeYearOptionValue = -1;

      if (option.value === specialWholeYearOptionValue) {
        state.inspirationsFilters.seasons.forEach((f) => {
          if (
            !state.selectedSeasons.includes(f.value) &&
            f.value !== specialWholeYearOptionValue
          ) {
            state.selectedSeasons = [...state.selectedSeasons, f.value];
          }
        });
      } else if (state.selectedSeasons.includes(option.value)) {
        state.selectedSeasons = [
          ...state.selectedSeasons.filter((i) => i !== option.value),
        ];
      } else {
        state.selectedSeasons = [...state.selectedSeasons, option.value];
      }
    },
    clearSelectedCountries(state) {
      state.selectedCountries = [];
    },
    clearSelectedRegions(state) {
      state.selectedRegions = [];
    },
    clearSelectedKinds(state) {
      state.selectedKinds = [];
    },
    clearSelectedSeasons(state) {
      state.selectedSeasons = [];
    },
    setInitialInspirationFilters(state, data) {
      state.selectedCountries = data.selectedCountries;
      state.selectedRegions = data.selectedRegions;
      state.selectedKinds = data.selectedKinds;
      state.selectedSeasons = data.selectedSeasons;
      state.isPriceAscending = data.isPriceAscending;
      state.searchTerm = data.searchTerm;
    },
    setSearchTerm(state, value) {
      state.searchTerm = value;
    },
  },
  getters: {
    selectedSegments: (state) =>
      (state.currentPlan.segments || []).filter((i) => i.selected),
    selectedNotSkippedSegments: (state, getters) =>
      getters.selectedSegments.filter((i) => !i.skipped),
    price(state, getters) {
      const segments = getters.selectedNotSkippedSegments;
      return sumBy(segments, (i) => getSegmentAmount(i));
    },
    provision: (state, getters) =>
      getters.price *
      ((state.currentPlan.provision || 0) / 100) *
      ((100 - (state.currentPlan.code || 0)) / 100),
    totalPrice: (state, getters) => getters.price + getters.provision,
    isCurrentPlanValid: (state) => Utils.plan.validate(state.currentPlan),
    isCurrentPlanInspiration: (state) =>
      state.currentPlan.type && state.currentPlan.type === "plan-inspiration",
    currentPlanSegments: (state) => state.currentPlan.segments || [],
    groupedPlanSegmentsBySection: (state, getters) =>
      groupByKeepOrder(getters.currentPlanSegments, "section"),
  },
  actions: {
    clearEmphasized({ commit }) {
      commit("clearEmphasized");
    },
    getEmphasized({ commit }) {
      return api.plans.getEmphasized().then((response) => {
        const results = response.data.results;
        commit("setEmphasized", results);
        return results;
      });
    },
    clearInspirations({ commit }) {
      commit("clearInspirations");
    },
    getInspirations: throttle(({ state, commit }, { reset = false } = {}) => {
      const page = reset ? 1 : state.inspirations.page + 1;
      const canLoadMore = reset ? true : state.inspirations.canLoadMore;
      if (canLoadMore) {
        return api.plans
          .getInspirations({
            page,
            countries: state.selectedCountries,
            regions: state.selectedRegions,
            kinds: state.selectedKinds,
            seasons: state.selectedSeasons,
            isPriceAscending: state.isPriceAscending,
            term: state.searchTerm,
          })
          .then((response) => {
            if (reset) commit("clearInspirations");
            commit("setInspirations", Object.assign(response.data, { page }));
          });
      }
      return Promise.resolve(1);
    }, INSPIRATIONS_THROTTLE_TIME),
    getInspirationsFilters({ commit }, { regions = [] }) {
      return api.plans.getInspirationsFilters({ regions }).then((response) => {
        commit("setInspirationsFilters", response.data);
        return response.data;
      });
    },
    getInspirationBySign({ commit }, sign) {
      return api.plans.getInspirationBySign(sign).then((response) => {
        commit("setPlan", response.data);
        return response.data;
      });
    },
    getPlanBySign({ commit }, sign) {
      return api.plans.getPlanBySign(sign).then((response) => {
        const data = response.data;
        if (data.is_available) {
          commit("setPlan", response.data);
        } else {
          commit("setPlanSimplePreview", response.data);
        }

        return response.data;
      });
    },
    getGuideByPlanSign({ commit }, sign) {
      return api.plans.getGuideByPlanSign(sign).then((response) => {
        commit("setGuide", response.data);
        return response.data;
      });
    },
    getCreatorDataFromInspiration({ commit }, sign) {
      return api.plans
        .getCreatorDataBySign(sign)
        .then((response) => response.data);
    },
    checkAvailability({ commit }, { id, timestamp }) {
      return api.plans
        .checkAvailability(id, timestamp)
        .then((response) => response.data);
    },
    createSuggestion({ commit }, { id, payload }) {
      return api.plans
        .createSuggestion(id, payload)
        .then((response) => response.data);
    },
    clearPlan({ commit }) {
      commit("clearPlan");
    },
    selectSegment({ commit }, { selected, segments }) {
      commit("selectSegment", { selected, segments });
    },
    checkPromoCode({ commit }, code) {
      return api.plans.checkPromoCode(code).then((response) => response.data);
    },
    setPromoCode({ commit }, { code = null, codeName = "" }) {
      commit("setPromoCode", { code, codeName });
    },
    payForPlan({ commit }, { planId, payload }) {
      return api.transactions
        .newPayment(planId, payload)
        .then((response) => response.data);
    },
    getTransactionById({ commit }, id) {
      return api.transactions.getTransactionById(id).then((response) => {
        commit("setTransaction", response.data);
        return response.data;
      });
    },
    clearTransaction({ commit }) {
      commit("clearTransaction");
    },
    setPlanFieldRequiredError({ commit }, { segment, fieldIndex }) {
      commit("setPlanFieldRequiredError", { segment, fieldIndex });
    },
    clearPlanFieldRequiredError({ commit }, { segment, fieldIndex }) {
      commit("clearPlanFieldRequiredError", { segment, fieldIndex });
    },
    toggleRegion({ commit }, value) {
      commit("toggleRegion", value);
    },
    toggleCountry({ commit }, value) {
      commit("toggleCountry", value);
    },
    toggleKind({ commit }, value) {
      commit("toggleKind", value);
    },
    togglePrice({ commit }, value) {
      commit("togglePrice", value);
    },
    toggleSeason({ commit }, value) {
      commit("toggleSeason", value);
    },
    clearSelectedKinds({ commit }) {
      commit("clearSelectedKinds");
    },
    clearSelectedRegions({ commit }) {
      commit("clearSelectedRegions");
    },
    clearSelectedCountries({ commit }) {
      commit("clearSelectedCountries");
    },
    clearSelectedSeasons({ commit }) {
      commit("clearSelectedSeasons");
    },
    setInitialInspirationFilters({ commit }, data) {
      commit("setInitialInspirationFilters", data);
    },
    setSearchTerm({ commit }, value) {
      commit("setSearchTerm", value);
    },
    checkPlanPin({ commit }, { id, payload }) {
      return api.guard
        .checkPlanPin(id, payload)
        .then((response) => response.data);
    },
    resendPlanPin({ commit }, { id, payload }) {
      return api.guard
        .resendPlanPin(id, payload)
        .then((response) => response.data);
    },
    submitNotInterestedFeedback({ commit }, { sign, payload }) {
      return api.plans
        .submitNotInterestedFeedback(sign, payload)
        .then((response) => response.data);
    },
  },
};
