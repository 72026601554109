export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_CREATOR_V3 = "DD.MM.YYYY";

export const REGEXP_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PLAN_TYPE = {
  INSPIRATION: "plan-inspiration",
};
export const HEADLINES = {
  FLIGHT: "flight",
  HOTEL: "hotel",
  CAR: "car",
  TRANSFER: "transfer",
  DATE: "date",
  STAR: "star",
};
export const SEGMENTS = {
  FLIGHT: "flight",
  HOTEL: "hotel",
  CAR: "car",
  TRANSFER: "transfer",
  INSURANCE: "insurance",
  OTHER: "other",
};

export const SEGMENTS_MULTIPLE = [SEGMENTS.FLIGHT, SEGMENTS.HOTEL];
export const FIELDS = {
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SELECT: "select",
};

export const FLIGHT_CLASS = {
  ECONOMIC: "economic",
  BUSINESS: "business",
};

export const FLIGHT_CLASS_LABEL = {
  [FLIGHT_CLASS.ECONOMIC]: "Ekonomiczna",
  [FLIGHT_CLASS.BUSINESS]: "Biznes",
};

export const DEFAULT_MIN_NIGHTS = 7;
export const DEFAULT_MAX_NIGHTS = 9;

export const TRANSPORT_TO_TYPE = {
  PLANE: "plane",
  OTHER: "other",
  INDIVIDUAL: "individual",
  SUGGESTION: "suggestion",
  CAR: "car",
};

export const TRANSPORT_TO_TYPE_LABEL = {
  [TRANSPORT_TO_TYPE.PLANE]: "Samolotem",
  [TRANSPORT_TO_TYPE.OTHER]: "Innym transportem",
  [TRANSPORT_TO_TYPE.INDIVIDUAL]: "Na własną rękę",
  [TRANSPORT_TO_TYPE.SUGGESTION]: "Transportem sugerowanym przez Getaway",
  [TRANSPORT_TO_TYPE.CAR]: "Wynajętym samochodem",
};

export const PEOPLE_TYPE = {
  ADULT: "adult",
  CHILD: "child",
};

export const KEY_CODES = {
  TAB: 9,
};

export const CAR_RENT = {
  RENT: "rent",
  CUSTOM: "rent_custom",
};

export default {
  DATE_FORMAT,
  FIELDS,
  HEADLINES,
  SEGMENTS,
  SEGMENTS_MULTIPLE,
  FLIGHT_CLASS,
  FLIGHT_CLASS_LABEL,
  TRANSPORT_TO_TYPE,
  TRANSPORT_TO_TYPE_LABEL,
};
