/* eslint-disable no-mixed-operators */

import formInputMixin from "@/components/forms/input/input";
import formInput from "@/components/forms/input/input.vue";
import { loadScript } from "@/helpers/utils";
import config from "@/config";

export default {
  mixins: [formInputMixin],
  components: {
    formInput,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue: function watchInputValue(value) {
      this.$emit("input", value);
    },
  },
  mounted() {
    loadScript(config.googleMapsUrl, "google").then((google) => {
      const inputHtml = this.$refs.autocomplete.$refs.input;
      const autocomplete = new google.maps.places.Autocomplete(inputHtml);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          this.inputValue = place.formatted_address;
        }
      });
    });
  },
};
