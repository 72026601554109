import pageCreatorV3 from "@/pages/page-creator-v3/page-creator-v3.vue";
import pageHome from "@/pages/page-home/page-home.vue";

import pageConfirmation from "@/pages/confirmation/page-confirmation/page-confirmation.vue";
import pagePlanDetails from "@/pages/page-plan-details/page-plan-details.vue";
import pagePlanDetailsV3 from "@/pages/page-plan-details-v3/page-plan-details-v3.vue";
import pagePlanGuide from "@/pages/page-plan-guide/page-plan-guide.vue";
import pageSuggestionConfirmation from "@/pages/confirmation/page-suggestion-confirmation/page-suggestion-confirmation.vue";
import pagePaymentConfirmation from "@/pages/confirmation/page-payment-confirmation/page-payment-confirmation.vue";
import pagePaymentCustomConfirmation from "@/pages/confirmation/page-payment-custom-confirmation/page-payment-custom-confirmation.vue";
import pagePaymentError from "@/pages/confirmation/page-payment-error/page-payment-error.vue";
import pageInspirations from "@/pages/page-inspirations/page-inspirations.vue";
import pageNotFound from "@/pages/confirmation/page-not-found/page-not-found.vue";
import pageInspirationDetails from "@/pages/page-inspiration-details/page-inspiration-details.vue";
import pageQuotationYes from "@/pages/confirmation/page-quotation-yes/page-quotation-yes.vue";
import pageQuotationNo from "@/pages/confirmation/page-quotation-no/page-quotation-no.vue";
import pageQuotationAlreadyReplied from "@/pages/confirmation/page-quotation-already-replied/page-quotation-already-replied.vue";
import pageQuestionNotinterested from "@/pages/confirmation/page-question-notinterested/page-question-notinterested.vue";
import pagePinResendConfirmation from "@/pages/confirmation/page-pin-resend/page-pin-resend.vue";
import pagePlanInsurancePackages from "@/pages/page-plan-insurance-packages/page-plan-insurance-packages.vue";
import pageEsim from "@/pages/confirmation/page-esim/page-esim.vue";
import store from "@/store";
import { STATIC_PAGES } from "@/store/modules/staticPage";
import config from "@/config";

let routes = [
  {
    path: "/",
    name: "home",
    component: pageHome,
  },
  {
    path: "/reset-password/:sign",
    name: "reset-password",
    component: pageHome,
  },
  {
    path: "/activate/:sign",
    name: "activate",
    component: pageHome,
  },
  {
    path: "/creator",
    name: "creator",
    component: pageCreatorV3,
    meta: {
      appHasHeader: false,
      appContentNoFullHeight: false,
      appHasFooter: false,
      appContentNoFullOverflow: true,
    },
  },
  {
    path: "/creator/from-inspiration/:sign",
    name: "creator-from-inspiration",
    component: pageCreatorV3,
    meta: {
      appHasHeader: false,
      appContentNoFullHeight: false,
      appHasFooter: false,
      appContentNoFullOverflow: true,
    },
    props: (route) => ({
      sign: route.params.sign,
      signFromInspiration: true,
    }),
  },
  {
    path: "/creator/partner/:widgetId",
    name: "creator-from-partner",
    component: pageCreatorV3,
    meta: {
      appHasHeader: false,
      appContentNoFullHeight: false,
      appHasFooter: false,
      appContentNoFullOverflow: true,
    },
    props: (route) => ({
      widgetId: route.params.widgetId,
    }),
  },
  {
    path: "/creator/overview/:sign",
    name: "creator-overview",
    component: pageCreatorV3,
    meta: {
      appHasHeader: false,
      appContentNoFullHeight: false,
      appHasFooter: false,
      appContentNoFullOverflow: true,
    },
    props: (route) => ({
      sign: route.params.sign,
      signFromInspiration: false,
    }),
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: pageConfirmation,
  },
  {
    path: "/quotation/yes",
    name: "quotation-yes",
    component: pageQuotationYes,
  },
  {
    path: "/quotation/no",
    name: "quotation-no",
    component: pageQuotationNo,
  },
  {
    path: "/quotation/already-replied",
    name: "quotation-already-replied",
    component: pageQuotationAlreadyReplied,
  },
  {
    path: "/question/notinterested",
    name: "question-not-interested",
    component: pageQuestionNotinterested,
  },
  {
    path: "/payment/confirmation",
    name: "payment-confirmation",
    component: pagePaymentConfirmation,
  },
  {
    path: "/payment/error",
    name: "payment-error",
    component: pagePaymentError,
  },
  {
    path: "/payment/custom/confirmation",
    name: "payment-custom-confirmation",
    component: pagePaymentCustomConfirmation,
  },
  {
    path: "/payment/custom/error",
    name: "payment-custom-error",
    component: pagePaymentError,
  },
  {
    path: "/suggestion/confirmation",
    name: "suggestion-confirmation",
    component: pageSuggestionConfirmation,
  },
  {
    path: "/pin/resend/confirmation",
    name: "pin-resend-confirmation",
    component: pagePinResendConfirmation,
  },
  {
    path: "/static/terms",
    name: "static-terms",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("staticPage/setStaticPage", STATIC_PAGES.TERMS)
        .then(() => {
          next();
        });
    },
  },
  {
    path: "/static/privacy-policy",
    name: "static-privacy-policy",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("staticPage/setStaticPage", STATIC_PAGES.PRIVACY_POLICY)
        .then(() => {
          next();
        });
    },
  },
  {
    path: "/static/about-us",
    name: "static-about-us",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("staticPage/setStaticPage", STATIC_PAGES.ABOUT_US)
        .then(() => {
          next();
        });
    },
  },
  {
    path: "/static/idea",
    name: "static-idea",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store.dispatch("staticPage/setStaticPage", STATIC_PAGES.IDEA).then(() => {
        next();
      });
    },
  },
  {
    path: "/static/prices",
    name: "static-prices",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("staticPage/setStaticPage", STATIC_PAGES.PAYMENTS)
        .then(() => {
          next();
        });
    },
  },
  {
    path: "/static/eu",
    name: "static-eu",
    component: pageHome,
    beforeEnter: (to, from, next) => {
      store
        .dispatch("staticPage/setStaticPage", STATIC_PAGES.PROJECT)
        .then(() => {
          next();
        });
    },
  },
  {
    path: "/plan/insurance-packages/:sign",
    name: "page-plan-insurance-packages",
    component: pagePlanInsurancePackages,
    props: (route) => ({
      sign: route.params.sign,
    }),
  },
  {
    path: "/plan/details/:sign",
    name: "page-plan-details",
    component: pagePlanDetails,
    meta: {
      appContentNoFullHeight: true,
      appHasHeader: false,
    },
    props: (route) => ({
      sign: route.params.sign,
    }),
  },
  {
    path: "/transaction/:id",
    name: "page-transaction",
    component: pagePlanDetails,
    meta: {
      appContentNoFullHeight: true,
      appHasHeader: false,
    },
    props: (route) => ({
      transactionId: route.params.id,
    }),
  },
  {
    path: "/inspirations",
    name: "inspirations",
    component: pageInspirations,
    beforeEnter: (to, from, next) => {
      if (to.query) {
        const regions = to.query.regions ? to.query.regions.split(",") : [];
        store.dispatch("plan/getInspirationsFilters", { regions }).then(() => {
          store
            .dispatch("plan/setInitialInspirationFilters", {
              selectedRegions: regions,
              selectedCountries: to.query.countries
                ? to.query.countries.split(",")
                : [],
              selectedKinds: to.query.kinds ? to.query.kinds.split(",") : [],
              selectedSeasons: to.query.seasons
                ? to.query.seasons.split(",").map((i) => parseInt(i, 10))
                : [],
              isPriceAscending: to.query.priceAsc
                ? to.query.priceAsc === "true"
                : true,
              searchTerm: to.query.term,
            })
            .then(() =>
              store.dispatch("plan/setSearchTerm", to.query.term || "")
            )
            .then(() => next());
        });
      } else {
        next();
      }
    },
    meta: {
      appContentNoFullHeight: false,
      appHasFooter: false,
    },
  },
  {
    path: "/inspiration/details/:sign",
    name: "page-inspiration-details",
    component: pageInspirationDetails,
    meta: {
      appContentNoFullHeight: true,
      appHasFooter: true,
    },
    props: (route) => ({
      sign: route.params.sign,
    }),
  },
  {
    path: "/inspiration/:sign",
    name: "page-inspiration-details-slug",
    component: pageInspirationDetails,
    meta: {
      appContentNoFullHeight: true,
      appHasFooter: true,
    },
    props: (route) => ({
      sign: route.params.sign,
    }),
  },
  {
    path: "/plan/guide/:sign",
    name: "page-plan-guide",
    component: pagePlanGuide,
    meta: {
      appContentNoFullOverflow: true,
    },
    props: (route) => ({
      sign: route.params.sign,
    }),
  },
  {
    path: "/plan/guide/:category/:sign",
    name: "page-plan-guide-category",
    component: pagePlanGuide,
    meta: {
      appContentNoFullOverflow: true,
    },
    props: (route) => ({
      sign: route.params.sign,
      category: route.params.category,
    }),
  },
  {
    path: "/esim",
    name: "esim",
    component: pageEsim,
  },
];

if (config.planDetailsV3.enabled) {
  routes = [
    ...routes,
    {
      path: "/v3/plan/details/:sign",
      name: "page-plan-details-v3",
      component: pagePlanDetailsV3,
      meta: {
        appHasHeader: false,
        appContentNoFullHeight: false,
        appHasFooter: false,
        appContentNoFullOverflow: true,
      },
      props: (route) => ({
        sign: route.params.sign,
      }),
    },
    {
      path: "/v3/transaction/:id",
      name: "page-transaction-v3",
      component: pagePlanDetailsV3,
      meta: {
        appHasHeader: false,
        appContentNoFullHeight: false,
        appHasFooter: false,
        appContentNoFullOverflow: true,
      },
      props: (route) => ({
        transactionId: route.params.id,
      }),
    },
  ];
}

routes = [
  ...routes,
  {
    path: "/:pathMatch(.*)*",
    component: pageNotFound,
    name: "not-found",
  },
];

export default routes;
