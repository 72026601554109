import users from "./api/users";
import offers from "./api/offers";
import plans from "./api/plans";
import transactions from "./api/transactions";
import settings from "./api/settings";
import searching from "./api/searching";
import advertising from "./api/advertising";
import guard from "./api/guard";
import insurance from "./api/insurance";

export default {
  users,
  offers,
  plans,
  transactions,
  settings,
  searching,
  advertising,
  guard,
  insurance,
};
