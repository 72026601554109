export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        "creator-v3-tags": true,
        "creator-v3-tags--clickable": this.clickable,
        "creator-v3-tags--inline": this.inline,
      };
    },
    filteredTags() {
      return this.value.filter(
        (tag) => tag !== null && tag !== undefined && tag !== ""
      );
    },
    displayedTags() {
      if (!this.isMounted || !this.inline) {
        return this.filteredTags;
      }
      const tagsWidth = this.$refs.tags.offsetWidth;
      const results = [];
      let tagsAccumulatedWidth = 0;
      for (const tag of this.$refs.tags.children) {
        const tagWidth = tag.offsetWidth;
        tagsAccumulatedWidth += tagWidth;
        const widthOfClippedElement = 42;
        if (tagsAccumulatedWidth + widthOfClippedElement < tagsWidth) {
          results.push(tag.innerText.trim());
        } else {
          results.push("...");
          break;
        }
      }
      return results;
    },
  },
  methods: {
    onClick(tag) {
      if (this.clickable) {
        this.$emit("tagSelected", tag);
      }
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
