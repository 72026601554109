import questionTag from "@/components/question-tag/question-tag.vue";

export default {
  props: {
    text: {
      required: true,
    },
  },
  components: {
    questionTag,
  },
};
