import imagePartnersDesktop from "@/assets/v2/images/partners-desktop.png";
import imagePartnersMobile from "@/assets/v2/images/partners-mobile.png";
import imagePartnersAws from "@/assets/v2/images/aws.png";

export default {
  data() {
    return {
      imagePartnersDesktop,
      imagePartnersMobile,
      imagePartnersAws,
    };
  },
};
