const SKIP_CREATOR_REDIRECTION_ROUTES = [
  "page-plan-details",
  "page-transaction",
  "creator-from-inspiration",
  "creator-from-partner",
  "creator",
];

const authRedirectMixin = {
  methods: {
    stayOrGoToCreator() {
      if (!SKIP_CREATOR_REDIRECTION_ROUTES.includes(this.$route.name)) {
        this.$router.push({ name: "creator" });
      }
    },
  },
};

export default authRedirectMixin;
