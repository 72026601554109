import formMixin from "@/mixins/form";
import accordionDriverAge from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-driver-age/accordion-driver-age.vue";
import accordionFlightClass from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-flight-class/accordion-flight-class.vue";
import accordionCityOfArrival from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-city-of-arrival/accordion-city-of-arrival.vue";
import accordionPreferredAirlines from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-preferred-airlines/accordion-preferred-airlines.vue";
import accordionToggles from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-toggles/accordion-toggles.vue";
import accordionHotelKind from "./components/accordion-hotel-kind/accordion-hotel-kind.vue";
import accordionHotelLocation from "./components/accordion-hotel-location/accordion-hotel-location.vue";
import accordionHotelStandard from "./components/accordion-hotel-standard/accordion-hotel-standard.vue";
import accordionRoomBedrooms from "./components/accordion-room-bedrooms/accordion-room-bedrooms.vue";
import accordionRoomBeds from "./components/accordion-room-beds/accordion-room-beds.vue";
import creatorV3AccordionItem from "@/pages/page-creator-v3/components/creator-v3-accordion-item/creator-v3-accordion-item.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";

export default {
  mixins: [formMixin],
  components: {
    accordionHotelKind,
    accordionHotelLocation,
    accordionHotelStandard,
    accordionRoomBedrooms,
    accordionRoomBeds,
    accordionDriverAge,
    accordionToggles,
    accordionFlightClass,
    accordionCityOfArrival,
    accordionPreferredAirlines,
    creatorV3AccordionItem,
    creatorV3Tags,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItem: 0,
    };
  },
  methods: {
    onItemSelect(order) {
      this.selectedItem = this.selectedItem === order ? null : order;
    },
    getClassModifier(order) {
      return {
        "creator-v3-accordion__item": true,
        "creator-v3-accordion__item--flat-bottom-border": this.isSelectedItem(
          order
        )
          ? false
          : order < this.items.length - 1 && order + 1 !== this.selectedItem,
        "creator-v3-accordion__item--flat-top-border": this.isSelectedItem(
          order
        )
          ? false
          : order > 0 && order - 1 !== this.selectedItem,
      };
    },
    isSelectedItem(order) {
      return order === this.selectedItem;
    },
  },
};
