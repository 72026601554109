import { mapActions, mapGetters } from "vuex";
import socialButtons from "@/components/social-buttons/social-buttons.vue";
import formMixin from "@/mixins/form";
import authRedirectMixin from "@/mixins/authRedirect";

export default {
  mixins: [authRedirectMixin, formMixin],
  components: {
    socialButtons,
  },
  props: {
    isLoginRequired: {
      type: Boolean,
      default: false,
    },
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: {
        email: "",
        password: "",
        rememberMe: false,
      },
    };
  },
  computed: {
    ...mapGetters("app", ["isInAppFBBrowser"]),
    rememberMeClassModifiers() {
      return {
        "account-login__remember-me": true,
        "account-login__remember-me--selected": this.fields.rememberMe,
      };
    },
  },
  methods: {
    ...mapActions("user", ["login", "loginWithOauthToken"]),
    loginSuccessful() {
      this.$emit("changeComponent", "myAccount");
    },
    onSubmit() {
      const payload = {
        email: this.fields.email,
        password: this.fields.password,
        remember_be: this.fields.rememberMe,
      };

      this.formDisable();
      this.login(payload)
        .then(this.loginSuccessful)
        .catch(this.formHandleErrors);
    },
    clickRegister() {
      this.$emit("changeComponent", "accountRegister");
    },
    clickForgot() {
      this.$emit("changeComponent", "forgotPassword");
    },
    onSocialSignIn(payload) {
      this.loginWithOauthToken(payload)
        .then(this.loginSuccessful)
        .catch(this.formHandleErrors);
    },
  },
};
