import { mapActions, mapGetters, mapState } from "vuex";
import {
  getLocationTitle,
  isHotelPreferred,
  hasLocationPeopleAssigned,
} from "@/helpers/creatorV3/utils";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import creatorV3Warning from "@/pages/page-creator-v3/components/creator-v3-warning/creator-v3-warning.vue";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { DATE_FORMAT_CREATOR_V3, PEOPLE_TYPE } from "@/constants";
import { flatten } from "lodash";
import moment from "moment";
moment.locale("pl");

export default {
  components: {
    alertBoxV3,
    creatorV3Tag,
    creatorV3Highlight,
    creatorV3Warning,
    Carousel3d,
    Slide,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHotelPreferred,
      getLocationTitle,
    };
  },
  computed: {
    ...mapGetters("creatorV3", ["people"]),
    ...mapState("creatorV3", ["offer"]),
    maximumNumberOfRoomsReached() {
      return this.location.rooms.length >= this.offer.adults.length;
    },
    location() {
      return this.offer.locations.find((i) => i.id === this.locationId);
    },
    peopleAttachedToRooms() {
      return flatten(this.location.rooms.map((room) => room.people));
    },
    availablePeople() {
      return this.people.filter(
        (person) =>
          !this.peopleAttachedToRooms.find((item) => item.id === person.id)
      );
    },
    showWarning() {
      return (
        !!this.availablePeople.length ||
        !hasLocationPeopleAssigned(this.location)
      );
    },
  },
  methods: {
    ...mapActions("creatorV3", ["updateRoomPeople", "addRoom", "removeRoom"]),
    getPersonName(person) {
      if (person.name) {
        return person.name;
      }
      if (person.type === PEOPLE_TYPE.ADULT) {
        return `Dorosły ${person.index}`;
      }
      return `Dziecko ${person.index}`;
    },
    getPersonClass(index) {
      return {
        "creator-v3-room-people-management__avatar": true,
        [`creator-v3-room-people-management__avatar--${index}`]: true,
      };
    },
    addPersonToRoom(index) {
      const room =
        this.location.rooms[this.$refs["rooms-carousel"].currentIndex];
      const person = this.availablePeople[index];
      const peopleInRoom = [...room.people, person];
      this.updateRoomPeople({
        people: peopleInRoom,
        roomId: room.id,
        locationId: this.location.id,
      });
      this.$forceUpdate();
    },
    removePerson(personId) {
      const room =
        this.location.rooms[this.$refs["rooms-carousel"].currentIndex];
      const peopleInRoom = room.people.filter((i) => i.id !== personId);
      this.updateRoomPeople({
        people: peopleInRoom,
        roomId: room.id,
        locationId: this.location.id,
      });
    },
    getAge(birthDate) {
      return moment(birthDate).from(
        this.offer.dateFrom
          ? moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3)
          : moment(),
        true
      );
    },
  },
};
