export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
  },
  computed: {
    amountDisplay() {
      return `${Number(this.transaction.amount).toLocaleString()} PLN`;
    },
    imageStyleModifiers() {
      return {
        "background-image": `linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)), url('${this.transaction.plan.background}')`,
      };
    },
    classModifier() {
      return {
        "user-transaction-tile-v3": true,
        "user-transaction-tile-v3--selected": this.selected,
      };
    },
  },
};
