export default {
  props: {
    title: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
  },
  computed: {
    classModifier() {
      return {
        "creator-v3-accordion-item": true,
        "creator-v3-accordion-item--selected": !!this.selected,
      };
    },
  },
};
