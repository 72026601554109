export default {
  props: {
    showOverlay: {
      type: Boolean,
      default: true,
    },
    closeButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
