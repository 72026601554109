export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
    },
    disabled: Boolean,
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    incorrect: Boolean,
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    showResetButton: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    maxLength: Number,
    max: Number,
    min: Number,
    value: [String, Number],
    theme: {
      type: String,
      default: "",
    },
    onlyDigits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        input: true,
        [`input--${this.theme}`]: this.theme,
        "input--incorrect": this.incorrect,
        "input--has-label": !!this.label,
      };
    },
    inputBoxModifiers() {
      return {
        "input__box--is-filled": this.value,
        "input__box--is-focused": this.focus,
        "input__box--is-disabled": this.disabled,
      };
    },
    hasIcon() {
      return !!this.$slots.icon;
    },
  },
  methods: {
    handleBlur(e) {
      setTimeout(() => {
        this.focus = false;
        this.$emit("blur", e.target.name);
      }, 200);
    },
    handleFocus(e) {
      this.focus = true;
      this.$emit("focus", e.target.name);
    },
    updateValue(e) {
      if (this.onlyDigits) {
        this.$emit("input", e.target.value.replace(/[^\d.]/gi, ""));
      } else {
        this.$emit("input", e.target.value);
      }
    },
    resetValue() {
      this.$refs.input.value = "";
      this.$emit("input", "");
    },
  },
};
