/* eslint-disable max-len */

import http from "@/services/api/http";

export default {
  getInsuranceAgreement({ id, payload }) {
    return http.get(`offers/insurance/${id}`, { params: payload });
  },
  sendInsuranceOwu({ id, payload }) {
    return http.post(`offers/insurance/${id}`, payload);
  },
};
