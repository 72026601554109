import { DatePicker } from "v-calendar";
import { mapActions, mapGetters } from "vuex";
import formInputV3 from "@/components/v3/input-v3/input-v3.vue";
import creatorV3Separator from "@/pages/page-creator-v3/components/creator-v3-separator/creator-v3-separator.vue";
import moment from "moment";

export default {
  components: {
    formInputV3,
    creatorV3Separator,
    DatePicker,
  },
  props: {
    onDateUpdateFn: {
      type: Function,
      default: () => {},
    },
    dateFrom: {
      type: Date,
    },
    dateTo: {
      type: Date,
    },
    labelFrom: {
      type: String,
      default: "Data początkowa",
    },
    labelTo: {
      type: String,
      default: "Data końcowa",
    },
    footerMessageFrom: {
      type: String,
      default: "Wybierz najwcześniejszą datę",
    },
    footerMessageTo: {
      type: String,
      default: "Wybierz najpóźniejszą datę",
    },
    minDate: {
      type: Date,
      default: moment().toDate(),
    },
    maxDate: {
      type: Date,
      default: moment().add(365, "days").toDate(),
    },
  },
  data() {
    return {
      date: {
        start: null,
        end: null,
      },
      isPicking: true,
      selectAttribute: {
        highlight: {
          start: {
            style: {
              backgroundColor: "#49b2bb",
              border: "solid 3px rgba(45, 148, 251, 0.2)",
            },
            contentStyle: {
              color: "#ffffff",
            },
          },
          base: {
            style: {
              backgroundColor: "rgba(57, 145, 153, 0.2)",
            },
            contentStyle: {
              color: "#49b2bb",
            },
          },
          end: {
            style: {
              backgroundColor: "#49b2bb",
            },
            contentStyle: {
              color: "#ffffff",
              border: "solid 3px rgba(45, 148, 251, 0.2)",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
    rows() {
      const monthsCountToDisplay =
        moment(this.maxDate).diff(moment(this.minDate), "months") + 2;
      return this.isLayoutMobile
        ? monthsCountToDisplay
        : Math.ceil(monthsCountToDisplay / 2);
    },
    columns() {
      return this.isLayoutMobile ? 1 : 2;
    },
    footerMessage() {
      if (this.isPicking) {
        return !this.date.start && !this.date.end
          ? this.footerMessageFrom
          : "Gotowe!";
      } else {
        return this.footerMessageTo;
      }
    },
  },
  methods: {
    ...mapActions("creatorV3", ["setDateCalendarModalFooter"]),
    optionsHandler(value) {
      this.setDateCalendarModalFooter(this.footerMessage);
    },
    onDayClick(e) {
      if (e.isDisabled) {
        return;
      }
      this.isPicking = !this.isPicking;
      if (this.date.start && this.date.end) {
        this.onDateUpdateFn(this.date);
      }
    },
  },
  watch: {
    footerMessage: {
      handler: "optionsHandler",
      immediate: true,
    },
    isPicking(value) {
      if (!value) {
        this.$refs.dateFrom.handleFocus({ target: { name: "" } });
        this.$refs.dateTo.handleFocus({ target: { name: "" } });
      } else {
        this.$refs.dateFrom.handleBlur({ target: { name: "" } });
        this.$refs.dateTo.handleBlur({ target: { name: "" } });
      }
    },
  },
  created() {
    this.date = {
      start: this.dateFrom,
      end: this.dateTo,
    };
  },
};
