import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import plan from "./modules/plan";
import modal from "./modules/modal";
import searching from "./modules/searching";
import user from "./modules/user";
import staticPage from "./modules/staticPage";
import advertising from "./modules/advertising";
import creatorV3 from "./modules/creatorV3";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    app,
    user,
    plan,
    modal,
    searching,
    staticPage,
    advertising,
    creatorV3,
  },
});
