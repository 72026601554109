import { mapActions, mapState } from "vuex";
import { MODALS } from "@/store/modules/modal";

export default {
  computed: {
    ...mapState("creatorV3", ["indexes"]),
    ...mapState("modal", ["currentModal"]),
    classModifier() {
      return {
        "creator-v3-progress": true,
        "creator-v3-progress--no-previous-steps": !this.isPreviousStep,
      };
    },
    isPreviousStep() {
      return this.indexes.current > 0;
    },
    isBeforePreviousStep() {
      return this.indexes.current > 1;
    },
    isNextStep() {
      return this.indexes.current + 1 !== this.indexes.total;
    },
    isAfterNextStep() {
      return this.indexes.current + 1 < this.indexes.total - 1;
    },
    isOpenStepsModal() {
      return this.currentModal.name === MODALS.CREATOR_STEPS_OVERVIEW;
    },
  },
  methods: {
    ...mapActions("modal", ["clearModal"]),
    ...mapActions("creatorV3", ["markStepOverviewNavigationHintAsDisplayed"]),
    getProgressItemStyle(isDisplayed) {
      return {
        opacity: isDisplayed ? "1" : "0",
      };
    },
    onCloseModal() {
      this.clearModal();
      this.markStepOverviewNavigationHintAsDisplayed(true);
    },
  },
};
