import moment from "moment";
import { cloneDeep, omit, merge, sumBy } from "lodash";
import api from "@/services/api";
import {
  DATE_FORMAT_CREATOR_V3,
  DEFAULT_MIN_NIGHTS,
  DEFAULT_MAX_NIGHTS,
  FLIGHT_CLASS,
  TRANSPORT_TO_TYPE,
  PEOPLE_TYPE,
} from "@/constants";
import {
  HOTEL_KINDS,
  getHotelLocationsOptions,
} from "@/helpers/creatorV3/accommodationMixin";
import {
  BED_CONFIGURATION_KIND,
  ROOM_CONFIGURATION_KIND,
  getRoomMealsOptions,
} from "@/helpers/creatorV3/roomMixin";
import { uuidv4 } from "@/helpers/utils";

const NUMBER_OF_AVATARS = 10;
const DEFAULT_NIGHTS_RANGE = [DEFAULT_MIN_NIGHTS, DEFAULT_MAX_NIGHTS];

const makeChild = ({ index = 1, avatar } = {}) => {
  return {
    id: uuidv4(),
    index,
    name: "",
    avatar,
    type: PEOPLE_TYPE.CHILD,
    birthDate: null,
  };
};

const makeAdult = ({ index = 1, avatar = 1 } = {}) => {
  return {
    id: uuidv4(),
    index,
    avatar,
    type: PEOPLE_TYPE.ADULT,
    name: "",
  };
};

const makeCar = () => {
  return {
    rentalType: null,
    type: [],
    additions: [],
    others: null,
    dateFrom: null,
    dateTo: null,
    driverAge30to65: true,
    driverAgeCustom: null,
  };
};

const makeAccommodation = () => {
  return {
    equipment: [],
    preference: HOTEL_KINDS.HOTEL,
    customEnabled: false,
    custom: "",
    others: null,
    locations: [],
    district: "",
    stars: [],
  };
};

const makeRoom = ({ people = [] } = {}) => {
  return {
    id: uuidv4(),
    others: null,
    equipment: [],
    meals: [],
    bedConfiguration: BED_CONFIGURATION_KIND.ANY,
    bedrooms: 1,
    people: people,
  };
};

const makeLocation = ({
  value = "",
  nightsRange = [1, 1],
  transport = null,
} = {}) => {
  return {
    id: uuidv4(),
    transport,
    hasCar: false,
    car: makeCar(),
    accommodation: makeAccommodation(),
    roomConfiguration: ROOM_CONFIGURATION_KIND.SINGLE,
    rooms: [makeRoom()],
    nightsRange,
    value,
  };
};

const DEFAULT_OFFER = {
  v3: true,
  adults: [makeAdult()],
  children: [],
  cityOfArrivalDifferent: false,
  cityOfDeparture: "",
  cityOfDepartureCloseToLivingPlace: false,
  cityOfArrival: "",
  dateFrom: null,
  dateTo: null,
  dateIsElastic: false,
  nightsRange: DEFAULT_NIGHTS_RANGE,
  withoutFlight: false,
  withoutHotel: false,
  budgetFrom: null,
  budgetTo: null,
  budgetSkip: false,
  car: makeCar(),
  description: "",
  acceptTermCondition: false,
  contact: {
    firstName: "",
    email: "",
    phone: "",
  },
  severalTargetPlaces: false,
  severalTargetPlacesAllowOppositeRoute: false,
  flightClass: FLIGHT_CLASS.ECONOMIC,
  flightLines: "",
  flightDirectly: false,
  locations: [
    makeLocation({
      nightsRange: DEFAULT_NIGHTS_RANGE,
      transport: TRANSPORT_TO_TYPE.PLANE,
    }),
  ],
  recommendTargetPlaces: false,
};

const checkAvailableRoomOptions = ({ obj, location }) => {
  // for now only checks if we can copy meals from room from current location to other
  // it might not be available
  const available = getRoomMealsOptions(location).map((i) => i.name);
  return {
    ...obj,
    meals: obj.meals.filter((i) => available.includes(i)),
  };
};

const checkAvailableAccommodationOptions = ({ obj, location }) => {
  // for now only checks if we can copy locations from current location to other
  // it might not be available
  const available = getHotelLocationsOptions(location).map((i) => i.name);
  return {
    ...obj,
    locations: obj.locations.filter((i) => available.includes(i)),
  };
};

export default {
  namespaced: true,
  state: {
    offer: { ...DEFAULT_OFFER },
    // settings always come for all locations in order send
    offerSettingsPerLocation: [],
    roomCopyAttempts: 0,
    locationSwapAttempts: 0,
    dateCalendarModalFooter: "",
    indexes: {
      current: 0,
      total: 0,
      completed: -1, // steps user already progressed from
    },
    hintStepOverviewNavigationShown: false,
  },
  getters: {
    nightCountMax(state) {
      if (state.offer.dateFrom === null || state.offer.dateTo === null) {
        return 0;
      }
      const date1 = moment(state.offer.dateFrom, DATE_FORMAT_CREATOR_V3);
      const date2 = moment(state.offer.dateTo, DATE_FORMAT_CREATOR_V3);
      return Math.abs(date1.diff(date2, "days"));
    },
    adultQuantity: (state) => (state.offer.adults || []).length,
    childrenQuantity: (state) => (state.offer.children || []).length,
    personsQuantity: (state) =>
      state.offer.adults.length + state.offer.children.length,
    people: (state) => [...state.offer.adults, ...state.offer.children],
    availableAvatars: (state) => {
      const usedAvatars = [
        ...state.offer.adults.map((item) => item.avatar),
        ...(state.offer.children || []).map((item) => item.avatar),
      ];
      const avatars = [...new Array(NUMBER_OF_AVATARS)].map(
        (i, index) => index + 1
      );
      return avatars.filter((item) => !usedAvatars.includes(item));
    },
    locationsNightsTotalFrom: (state) =>
      sumBy(state.offer.locations, (i) => i.nightsRange[0]),
    locationsNightsTotalTo: (state) =>
      sumBy(state.offer.locations, (i) => i.nightsRange[1]),
    areFromNightRangesValid: (state, getters) =>
      state.offer.nightsRange[0] === getters.locationsNightsTotalFrom,
    areToNightRangesValid: (state, getters) =>
      state.offer.nightsRange[1] === getters.locationsNightsTotalTo,
    areLocationNightRangesValid: (state, getters) => {
      return getters.areFromNightRangesValid && getters.areToNightRangesValid;
    },
    isAnyLocationFilled: (state) =>
      state.offer.locations.filter((i) => !!i.value).length > 0,
    areAllLocationsFilled: (state) =>
      state.offer.locations.filter((i) => !!i.value).length ===
      state.offer.locations.length,
    nightsDisplay: (state) => {
      const from = state.offer.nightsRange[0];
      const to = state.offer.nightsRange[1];

      return from === to ? from : `${from}-${to}`;
    },
  },
  mutations: {
    clearOffer(state) {
      state.offer = cloneDeep({ ...DEFAULT_OFFER });
    },
    setOffer(state, data) {
      state.offer = { ...DEFAULT_OFFER, ...data };
    },
    insertAdult(state, avatar) {
      const indexes = state.offer.adults.map((i) => i.index);
      let index = 1;
      for (let i = 1; i < 100; i++) {
        if (!indexes.includes(i)) {
          index = i;
          break;
        }
      }
      state.offer = {
        ...state.offer,
        adults: [...state.offer.adults, makeAdult({ index, avatar })],
      };
    },
    insertChild(state, avatar) {
      const indexes = state.offer.children.map((i) => i.index);
      let index = 1;
      for (let i = 1; i < 100; i++) {
        if (!indexes.includes(i)) {
          index = i;
          break;
        }
      }
      state.offer = {
        ...state.offer,
        children: [...state.offer.children, makeChild({ index, avatar })],
      };
    },
    removeAdult(state, item) {
      state.offer = {
        ...state.offer,
        adults: [...state.offer.adults.filter((i) => i.index !== item.index)],
      };
    },
    removeChild(state, item) {
      state.offer = {
        ...state.offer,
        children: [
          ...state.offer.children.filter((i) => i.index !== item.index),
        ],
      };
    },
    updateIndexes(state, data) {
      state.indexes = merge(state.indexes, data);
    },
    markStepOverviewNavigationHintAsDisplayed(state, value) {
      state.hintStepOverviewNavigationShown = value;
    },
    recalculateLocationRanges(state) {
      const nightsFrom = state.offer.nightsRange[0];
      const nightsTo = state.offer.nightsRange[1];
      const count = state.offer.locations.length;
      const minDes = Math.floor(nightsFrom / count);
      let leftMinDes = nightsFrom - count * minDes;

      const maxDes = Math.floor(nightsTo / count);
      let leftMaxDes = nightsTo - count * maxDes;

      const results = cloneDeep(state.offer.locations);

      for (const i of results) {
        const nightsFrom = minDes + Math.min(leftMinDes, 1);
        const nightsTo = maxDes + Math.min(leftMaxDes, 1);
        if (leftMinDes > 0) {
          leftMinDes--;
        }
        if (leftMaxDes > 0) {
          leftMaxDes--;
        }
        i.nightsRange = [nightsFrom, Math.max(nightsFrom, nightsTo)];
      }

      state.offer.locations = results;
    },
    recalculateLocationTransports(state) {
      const results = cloneDeep(state.offer.locations);
      for (const i of results) {
        i.transport = state.offer.withoutFlight
          ? TRANSPORT_TO_TYPE.INDIVIDUAL
          : TRANSPORT_TO_TYPE.PLANE;
      }
      state.offer.locations = results;
    },
    copyLocationTransportOptionsToOthers(state, location) {
      /* copy only to locations that follow current */
      const locationIndex = state.offer.locations.indexOf(location);
      const results = cloneDeep(state.offer.locations);

      let index = 0;
      for (const i of results) {
        if (index > locationIndex) {
          i.car = cloneDeep(location.car);
          i.hasCar = location.hasCar;
          i.transport = location.transport;
        }
        index++;
      }
      state.offer.locations = results;
    },
    copyLocationAccommodationOptionsToOthers(state, location) {
      /* copy only to locations that follow current */
      const locationIndex = state.offer.locations.indexOf(location);
      const results = cloneDeep(state.offer.locations);

      let index = 0;
      for (const currentLocation of results) {
        if (index > locationIndex) {
          currentLocation.accommodation = cloneDeep({
            ...currentLocation.accommodation,
            ...checkAvailableAccommodationOptions({
              obj: omit(location.accommodation, ["district"]),
              location: currentLocation,
            }),
          });
        }
        index++;
      }
      state.offer.locations = results;
    },
    copyRoomOptionsToOtherLocations(state, { location, room }) {
      /* copy only to locations that follow current */
      const locationIndex = state.offer.locations.indexOf(location);
      const results = cloneDeep(state.offer.locations);

      let index = 0;
      for (const currentLocation of results) {
        if (index > locationIndex) {
          currentLocation.rooms = currentLocation.rooms.map((currentRoom) => {
            return cloneDeep({
              id: currentRoom.id,
              ...checkAvailableRoomOptions({
                obj: omit(room, ["id"]),
                location: currentLocation,
              }),
            });
          });
        }
        index++;
      }
      state.roomCopyAttempts += 1;
      state.offer.locations = results;
    },
    copyRoomOptionsToNextRooms(state, { location, room }) {
      /* copy only to rooms that follow current */
      const results = cloneDeep(state.offer.locations);

      for (const currentLocation of results) {
        if (currentLocation.id !== location.id) {
          continue;
        }
        const roomIndex = currentLocation.rooms
          .map((i) => i.id)
          .indexOf(room.id);
        let index = 0;
        for (const currentRoom of currentLocation.rooms) {
          if (index > roomIndex) {
            currentLocation.rooms[index] = {
              ...checkAvailableRoomOptions({
                obj: cloneDeep(room),
                location: currentLocation,
              }),
              id: currentRoom.id,
              people: currentRoom.people,
            };
          }
          index++;
        }
      }
      state.offer.locations = results;
    },
    copyRoomsConfigurationToOtherLocations(state, location) {
      /* copy only to locations that follow current */
      const locationIndex = state.offer.locations.indexOf(location);
      const results = cloneDeep(state.offer.locations);

      let index = 0;
      for (const currentLocation of results) {
        if (index > locationIndex) {
          currentLocation.rooms = location.rooms.map((room) => {
            return {
              ...checkAvailableRoomOptions({
                obj: room,
                location: currentLocation,
              }),
              id: uuidv4(),
            };
          });
          currentLocation.roomConfiguration = ROOM_CONFIGURATION_KIND.MULTI;
        }
        index++;
      }
      state.offer.locations = results;
    },
    insertLocation(state) {
      const obj = makeLocation({
        transport: state.offer.withoutFlight
          ? TRANSPORT_TO_TYPE.INDIVIDUAL
          : TRANSPORT_TO_TYPE.PLANE,
      });
      if (state.offer.locations.length > 1) {
        state.offer = {
          ...state.offer,
          locations: [
            state.offer.locations[0],
            ...state.offer.locations.slice(1, state.offer.locations.length - 1),
            obj,
            state.offer.locations[state.offer.locations.length - 1],
          ],
        };
      } else {
        state.offer = {
          ...state.offer,
          locations: [state.offer.locations[0], obj],
        };
      }
    },
    removeLocation(state, obj) {
      const locations = [...state.offer.locations];
      const indexToSlice = locations.indexOf(obj);
      if (indexToSlice === -1) return;
      const firstSlice = locations.slice(0, indexToSlice);
      const secondSlice = locations.slice(indexToSlice + 1);
      state.offer = {
        ...state.offer,
        locations: [...firstSlice, ...secondSlice],
      };
    },
    moveLocationDown(state, location) {
      const locations = [...state.offer.locations];
      const fromIndex = locations.indexOf(location);
      if (fromIndex === -1) return;
      const toIndex = fromIndex + 1;
      const swapLocation = locations[toIndex];
      locations[fromIndex] = swapLocation;
      locations[toIndex] = location;
      state.offer = {
        ...state.offer,
        locations,
      };
      state.locationSwapAttempts += 1;
    },
    moveLocationTop(state, location) {
      const locations = [...state.offer.locations];
      const fromIndex = locations.indexOf(location);
      if (fromIndex === -1) return;
      const toIndex = fromIndex - 1;
      const swapLocation = locations[toIndex];
      locations[fromIndex] = swapLocation;
      locations[toIndex] = location;
      state.offer = {
        ...state.offer,
        locations,
      };
      state.locationSwapAttempts += 1;
    },
    resetToOneLocation(state) {
      state.offer = {
        ...state.offer,
        locations: [
          makeLocation({
            transport: state.offer.withoutFlight
              ? TRANSPORT_TO_TYPE.INDIVIDUAL
              : TRANSPORT_TO_TYPE.PLANE,
          }),
        ],
      };
    },
    resetToTwoLocations(state) {
      const transport = state.offer.withoutFlight
        ? TRANSPORT_TO_TYPE.INDIVIDUAL
        : TRANSPORT_TO_TYPE.PLANE;
      state.offer = {
        ...state.offer,
        locations: [
          makeLocation({
            transport,
          }),
          makeLocation({
            transport,
          }),
        ],
      };
    },
    updateRoomPeople(state, { people, roomId, locationId }) {
      state.offer.locations = state.offer.locations.map((location) =>
        location.id === locationId
          ? {
              ...location,
              rooms: location.rooms.map((room) =>
                room.id === roomId
                  ? {
                      ...room,
                      people,
                    }
                  : room
              ),
            }
          : location
      );
    },
    resetLocationRoomsToDefaults(state, obj) {
      const isMulti = obj.roomConfiguration === ROOM_CONFIGURATION_KIND.MULTI;
      const rooms = [
        makeRoom({
          people: isMulti
            ? []
            : [...state.offer.adults, ...state.offer.children],
        }),
      ];
      if (isMulti) {
        rooms.push(makeRoom());
      }
      const index = state.offer.locations.indexOf(obj);
      state.offer.locations[index].rooms = rooms;
    },
    reassignRoomsInPeopleToDefaultsInAllLocations(state) {
      /*
        walks through all locations and assigns default number of people for particular room
        triggered only when number of locations or people changes
       */
      let locationIndex = 0;
      for (const location of state.offer.locations) {
        let roomIndex = 0;
        for (const room of location.rooms) {
          state.offer.locations[locationIndex].rooms[roomIndex].people =
            roomIndex === 0 && location.rooms.length <= 1
              ? [...state.offer.adults, ...state.offer.children]
              : [];
          roomIndex++;
        }
        locationIndex++;
      }
    },
    addRoom(state, location) {
      const index = state.offer.locations.indexOf(location);
      state.offer.locations[index].rooms = [
        ...state.offer.locations[index].rooms,
        makeRoom(),
      ];
    },
    removeRoom(state, { location, room }) {
      const index = state.offer.locations.indexOf(location);
      state.offer.locations[index].rooms = [
        ...state.offer.locations[index].rooms.filter((i) => i.id !== room.id),
      ];
    },
    setOfferSettings(state, data) {
      state.offerSettingsPerLocation = data.results;
    },
    setDateCalendarModalFooter(state, value) {
      state.dateCalendarModalFooter = value;
    },
  },
  actions: {
    clearOffer({ commit }) {
      commit("clearOffer");
    },
    setOffer({ commit }, data) {
      commit("setOffer", data);
    },
    insertChild({ commit, dispatch, getters }) {
      commit("insertChild", getters.availableAvatars[0]);
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    insertAdult({ commit, dispatch, getters }) {
      commit("insertAdult", getters.availableAvatars[0]);
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    removeAdult({ commit, dispatch }, item) {
      commit("removeAdult", item);
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    removeChild({ commit, dispatch }, item) {
      commit("removeChild", item);
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    updateIndexes({ commit }, data) {
      commit("updateIndexes", data);
    },
    markStepOverviewNavigationHintAsDisplayed({ commit }, value) {
      commit("markStepOverviewNavigationHintAsDisplayed", value);
    },
    recalculateLocationRanges({ commit }) {
      commit("recalculateLocationRanges");
    },
    recalculateLocationTransports({ commit }) {
      commit("recalculateLocationTransports");
    },
    insertLocation({ commit, dispatch }) {
      commit("insertLocation");
      dispatch("recalculateLocationRanges");
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    removeLocation({ commit, dispatch }, obj) {
      commit("removeLocation", obj);
      dispatch("recalculateLocationRanges");
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    resetToOneLocation({ commit, dispatch }) {
      commit("resetToOneLocation");
      dispatch("recalculateLocationRanges");
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    resetToTwoLocations({ commit, dispatch }) {
      commit("resetToTwoLocations");
      dispatch("recalculateLocationRanges");
      dispatch("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    moveLocationTop({ commit }, obj) {
      commit("moveLocationTop", obj);
    },
    moveLocationDown({ commit }, obj) {
      commit("moveLocationDown", obj);
    },
    copyLocationTransportOptionsToOthers({ commit }, location) {
      commit("copyLocationTransportOptionsToOthers", location);
    },
    copyLocationAccommodationOptionsToOthers({ commit }, location) {
      commit("copyLocationAccommodationOptionsToOthers", location);
    },
    updateRoomPeople({ commit }, { people, roomId, locationId }) {
      commit("updateRoomPeople", { people, roomId, locationId });
    },
    copyRoomOptionsToOtherLocations({ commit }, { location, room }) {
      commit("copyRoomOptionsToOtherLocations", { location, room });
    },
    copyRoomOptionsToNextRooms({ commit }, { location, room }) {
      commit("copyRoomOptionsToNextRooms", { location, room });
    },
    copyRoomsConfigurationToOtherLocations({ commit }, location) {
      commit("copyRoomsConfigurationToOtherLocations", location);
    },
    getCreatorDataFromInspiration({ commit }, sign) {
      return api.plans
        .getCreatorDataBySign(sign)
        .then((response) => response.data);
    },
    getCreatorDataFromWidget({ commit }, widgetId) {
      return api.advertising
        .getCreatorDataByWidgetId(widgetId)
        .then((response) => response.data);
    },
    getCreatorDataFromOffer({ commit }, sign) {
      return api.offers
        .getCreatorDataBySign(sign)
        .then((response) => response.data);
    },
    resetLocationRoomsToDefaults({ commit, dispatch }, location) {
      commit("resetLocationRoomsToDefaults", location);
    },
    reassignRoomsInPeopleToDefaultsInAllLocations({ commit }) {
      commit("reassignRoomsInPeopleToDefaultsInAllLocations");
    },
    addRoom({ commit }, location) {
      commit("addRoom", location);
    },
    removeRoom({ commit }, { location, room }) {
      commit("removeRoom", { location, room });
    },
    submitOffer({ commit }, payload) {
      return api.offers.submitOffer(payload);
    },
    analyzeOffer({ commit, state }) {
      return api.offers
        .analyzeOffer({
          offer_data: state.offer,
        })
        .then((response) => {
          commit("setOfferSettings", response.data);
        });
    },
    setDateCalendarModalFooter({ commit }, value) {
      commit("setDateCalendarModalFooter", value);
    },
  },
};
