import { mapGetters } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import formMixin from "@/mixins/form";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import { isNaN, isNumber } from "lodash";

const BUDGET_OPTIONS = {
  OWN: "own",
  EXPERTS: "experts",
};

const stepMeta = {
  name: "budget",
  title: "Budżet",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, formMixin],
  components: {
    alertBoxV3,
  },
  data() {
    return {
      ...stepMeta,
      budgetOption: null,
      hintOwn:
        "Podaj nam zakres kwot, które planujesz wydać na cały wyjazd. Ten budżet powinien obejmować WSZYSTKIE osoby łącznie",
      hintExperts:
        "Przygotujemy dla Ciebie Propozycję wyjazdu w oparciu o Twoje wymagania i zaproponujemy jak najlepszy stosunek jakości do ceny",
      BUDGET_OPTIONS,
    };
  },
  computed: {
    ...mapGetters("creatorV3", ["personsQuantity"]),
    pricePerPersonInfo() {
      if (this.offer.budgetTo && this.offer.budgetTo.length < 3) {
        return "";
      }
      if (this.offer.budgetFrom && this.offer.budgetTo) {
        const fromPerPerson = this.roundValue(
          Number(this.offer.budgetFrom) / this.personsQuantity
        );
        const toPerPerson = this.roundValue(
          Number(this.offer.budgetTo) / this.personsQuantity
        );
        return `${fromPerPerson}-${toPerPerson} na osobę`;
      } else if (this.offer.budgetTo) {
        const toPerPerson = this.roundValue(
          Number(this.offer.budgetTo) / this.personsQuantity
        );
        return `${toPerPerson} na osobę`;
      }
      return "";
    },
    isValid() {
      if (
        this.offer.budgetSkip &&
        !this.offer.budgetFrom &&
        !this.offer.budgetTo
      ) {
        return true;
      }
      if (this.offer.budgetFrom && this.offer.budgetTo) {
        const from = parseFloat(this.offer.budgetFrom);
        const to = parseFloat(this.offer.budgetTo);
        return (
          !isNaN(from) &&
          !isNaN(to) &&
          isNumber(from) &&
          isNumber(to) &&
          from >= 0 &&
          to > 0 &&
          to >= from
        );
      }
      return false;
    },
  },
  methods: {
    roundValue(val) {
      return Math.ceil(val);
    },
  },
  watch: {
    budgetOption(value) {
      this.offer.budgetSkip = value === BUDGET_OPTIONS.EXPERTS;
    },
  },
  created() {
    this.budgetOption = this.offer.budgetSkip
      ? BUDGET_OPTIONS.EXPERTS
      : BUDGET_OPTIONS.OWN;
  },
};
