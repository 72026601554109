import { mapGetters } from "vuex";
import loginMixin from "@/mixins/loginMixin";

export default {
  mixins: [loginMixin],
  props: {
    theme: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  watch: {
    loginActive(active) {
      if (!active) {
        this.loginRequired(false);
        this.loginComponent = this.getInitialComponent();
      }
    },
    isLoginRequired(isLoginRequired) {
      this.loginActive = isLoginRequired;
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn"]),
    loginText() {
      if (!this.isLoggedIn) {
        return "Logowanie";
      } else {
        return this.details && this.details.first_name
          ? this.details.first_name
          : "Moje Konto";
      }
    },
    classModifiers() {
      return {
        "app-simple-header-v3": true,
        [`app-simple-header-v3--${this.theme}`]: this.theme,
      };
    },
    overlayClassModifiers() {
      return {
        "app-simple-header-v3__overlay": true,
        "app-simple-header-v3__overlay--transitioning": this.isTransitioning,
      };
    },
  },
  methods: {
    onLoginButtonClick() {
      this.loginActive = !this.loginActive;
    },
    onClose() {
      this.loginActive = false;
    },
    onBeforeTransitionEnter() {
      this.isTransitioning = true;
    },
    onAfterTransitionLeave() {
      this.isTransitioning = false;
    },
  },
};
