var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"creator-v3-accommodation"},[_c('creator-v3-container',{attrs:{"theme":_vm.name,"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form-radio-v3',{attrs:{"name":"hotel","checked":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Potrzebuję nocleg ")]},proxy:true}]),model:{value:(_vm.offer.withoutHotel),callback:function ($$v) {_vm.$set(_vm.offer, "withoutHotel", $$v)},expression:"offer.withoutHotel"}}),_c('creator-v3-radio-content',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.offer.withoutHotel),expression:"!offer.withoutHotel"}]},[_c('div',{staticClass:"creator-v3-accommodation__items"},_vm._l((_vm.locationTagInfo),function(info,index){return _c('div',{key:`accommodation-${info.id}`,staticClass:"creator-v3-accommodation__items-item"},[(_vm.locationTagInfo.length > 1)?_c('div',{staticClass:"creator-v3-accommodation__items-line"},[_c('div',{staticClass:"creator-v3-accommodation__items-bullet",class:{
                      'creator-v3-accommodation__items-bullet--last':
                        index === _vm.locationTagInfo.length - 1,
                    }})]):_vm._e(),_c('div',[_c('creator-v3-tag',[_vm._v(" "+_vm._s(info.title)+" ")]),_c('button',{staticClass:"button-v3 button-v3--secondary button-v3--full-width creator-v3-accommodation__options-button",on:{"click":function($event){return _vm.onShowAccommodationPanel(info.id)}}},[_vm._v(" Wybierz opcje noclegu ")]),(info.tags.length > 0)?_c('creator-v3-tags',{key:`accommodation-${info.id}-${info.tags.join()}`,model:{value:(info.tags),callback:function ($$v) {_vm.$set(info, "tags", $$v)},expression:"info.tags"}}):(_vm.locationTagInfo.length > 1)?_c('div',{staticClass:"creator-v3-accommodation__tags-white-space-filler"}):_vm._e()],1),(
                    index !== _vm.locationTagInfo.length - 1 &&
                    _vm.locationTagInfo.length > 0
                  )?_c('div',{staticClass:"creator-v3-accommodation__item-separator"}):_vm._e()])}),0)])]},proxy:true}])}),_c('creator-v3-separator',{staticClass:"creator-v3-accommodation__line"}),_c('form-radio-v3',{attrs:{"name":"flight","checked":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('hint-v3-text',{attrs:{"text":"Nie będziemy szukali dla Ciebie zakwaterowania, np. gdy jedziesz do znajomych, do swojego apartamentu albo będziesz szukać noclegu we własnym zakresie","label":"Nie potrzebuję noclegu"}})]},proxy:true}]),model:{value:(_vm.offer.withoutHotel),callback:function ($$v) {_vm.$set(_vm.offer, "withoutHotel", $$v)},expression:"offer.withoutHotel"}}),_c('creator-v3-radio-content',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.offer.withoutHotel),expression:"offer.withoutHotel"}],staticClass:"creator-v3-accommodation__no-accommodation-text"},[_vm._v(" Nie będziemy szukali dla Ciebie zakwaterowania, np. gdy jedziesz do znajomych, do swojego apartamentu albo będziesz szukać noclegu we własnym zakresie ")])]},proxy:true}])})]},proxy:true}])}),(_vm.showAccommodationPanel)?_c('creator-v3-options-panel',{on:{"close":function($event){_vm.showAccommodationPanel = false}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Opcje noclegu ")]},proxy:true},{key:"content",fn:function(){return [_c('creator-v3-location-accommodation',{attrs:{"location":_vm.selectedLocation}})]},proxy:true},{key:"footer",fn:function(){return [_vm._v(" Wybierz opcje noclegu ")]},proxy:true}],null,false,4176925895)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }