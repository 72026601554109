export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    incorrect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {},
  },
  computed: {
    isChecked() {
      return (
        this.checked &&
        this.checked.label === this.label &&
        this.checked.value === this.value
      );
    },
    classModifiers() {
      return {
        radio: true,
        "radio--is-incorrect": this.incorrect,
        "radio--checked": this.isChecked,
      };
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        label: this.label,
        value: this.value,
      });
    },
  },
};
