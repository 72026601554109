/* eslint-disable */
import store from "@/store";

export const waitForInfoTextMap = () => {
  return new Promise((resolve) => {
    const check = () => {
      if (store.state.searching.infoTextMap !== null) {
        resolve();
      } else {
        setTimeout(check, 50);
      }
    };
    check();
  });
};
