import { mapActions, mapGetters, mapState } from "vuex";
import plansInspirationsTile from "@/components/plan/plans-inspirations-tile/plans-inspirations-tile.vue";
import plansInspirationsFilters from "@/components/plan/plans-inspirations-filters/plans-inspirations-filters.vue";
import plansInspirationsSearchInput from "@/components/plan/plans-inspirations-search-input/plans-inspirations-search-input.vue";
import infiniteLoading from "vue-infinite-loading";

export default {
  components: {
    infiniteLoading,
    plansInspirationsFilters,
    plansInspirationsTile,
    plansInspirationsSearchInput,
  },
  metaInfo: {
    title: "Inspiracje",
  },
  data() {
    return {
      initialized: false,
      infiniteLoader: null,
      loaded: false,
      isFiltering: false,
      term: "",
    };
  },
  computed: {
    ...mapGetters("app", ["areInspirationSeasonsEnabled"]),
    ...mapState("plan", [
      "inspirations",
      "selectedCountries",
      "isPriceAscending",
      "selectedRegions",
      "selectedKinds",
      "selectedSeasons",
      "searchTerm",
    ]),
    classModifiers() {
      return {
        "page-inspirations": true,
        "page-inspirations--with-seasons": this.areInspirationSeasonsEnabled,
      };
    },
    items() {
      return this.inspirations.data === null ? [] : this.inspirations.data;
    },
    showNoInspirationsMessage() {
      return this.loaded && this.items.length === 0;
    },
    filtering() {
      return (
        this.selectedSeasons.toString() +
        this.selectedCountries.toString() +
        this.selectedRegions.toString() +
        this.selectedKinds.toString() +
        this.isPriceAscending.toString() +
        this.searchTerm
      );
    },
  },
  methods: {
    ...mapActions("plan", [
      "getInspirations",
      "clearInspirations",
      "getInspirationsFilters",
      "clearSelectedCountries",
      "setSearchTerm",
    ]),
    filteringChanged() {
      this.$router.push({
        name: "inspirations",
        query: {
          seasons: this.selectedSeasons.join(","),
          regions: this.selectedRegions.join(","),
          countries: this.selectedCountries.join(","),
          kinds: this.selectedKinds.join(","),
          priceAsc: this.isPriceAscending.toString(),
          term: this.searchTerm,
        },
      });
    },
    termHandler(value) {
      if (!this.initialized) return false;
      this.setSearchTerm(value);

      if (!value || value.length >= 3) {
        if (this.isFiltering) return;
        this.isFiltering = true;
        this.getInspirations({ reset: true }).then(() => {
          this.isFiltering = false;
        });
      }
    },
    selectedKindsHandler() {
      if (this.isFiltering) return;
      this.isFiltering = true;
      this.getInspirations({ reset: true }).then(() => {
        this.isFiltering = false;
      });
    },
    selectedSeasonsHandler() {
      if (this.isFiltering) return;
      this.isFiltering = true;
      this.getInspirations({ reset: true }).then(() => {
        this.isFiltering = false;
      });
    },
    selectedRegionsHandler() {
      if (this.isFiltering) return;
      this.isFiltering = true;
      this.clearSelectedCountries();
      this.getInspirationsFilters({ regions: this.selectedRegions })
        .then(() => this.getInspirations({ reset: true }))
        .then(() => {
          this.isFiltering = false;
        });
    },
    selectedCountriesHandler() {
      if (this.isFiltering) return;
      this.isFiltering = true;
      this.getInspirations({ reset: true }).then(() => {
        this.isFiltering = false;
      });
    },
    isPriceAscendingHandler() {
      if (this.isFiltering) return;
      this.isFiltering = true;
      this.getInspirations({ reset: true }).then(() => {
        this.isFiltering = false;
      });
    },
    goInfinite(infi) {
      this.infiniteLoader = infi;

      if (this.inspirations.canLoadMore) {
        this.getInspirations({ reset: false })
          .then(() => {
            this.loaded = true;
          })
          .catch(() => {
            this.infiniteLoader.complete();
            this.loaded = true;
          });
      }
    },
  },
  watch: {
    filtering: {
      handler: "filteringChanged",
    },
    selectedRegions: {
      handler: "selectedRegionsHandler",
    },
    selectedCountries: {
      handler: "selectedCountriesHandler",
    },
    selectedKinds: {
      handler: "selectedKindsHandler",
    },
    selectedSeasons: {
      handler: "selectedSeasonsHandler",
    },
    isPriceAscending: {
      handler: "isPriceAscendingHandler",
    },
    term: {
      handler: "termHandler",
    },
    items() {
      if (this.infiniteLoader) {
        this.infiniteLoader.loaded();
        if (!this.inspirations.canLoadMore) {
          this.infiniteLoader.complete();
        }
      }
    },
  },
  created() {
    this.term = this.searchTerm;
    this.$nextTick(() => {
      this.initialized = true;
    });
  },
  beforeDestroy() {
    this.clearInspirations();
  },
};
