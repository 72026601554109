const MESSAGE_RESET_TO_DEFAULT_STATE_TIME = 3000;

export default {
  props: {
    promptMessage: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      clicked: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        "creator-v3-action-alert": true,
        "creator-v3-action-alert--clicked": this.clicked,
      };
    },
  },
  methods: {
    onClick() {
      if (this.clicked) return;
      this.$emit("click");
      this.clicked = true;
      this.timeout = setTimeout(() => {
        this.clicked = false;
      }, MESSAGE_RESET_TO_DEFAULT_STATE_TIME);
    },
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
};
