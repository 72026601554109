import http from "@/services/api/http";

export default {
  newPayment(planId, payload) {
    return http.post(`offers/transactions/new/${planId}`, payload);
  },
  getTransactionById(id) {
    return http.get(`offers/transactions/${id}`);
  },
};
