import { mapActions, mapGetters } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { MODALS } from "@/store/modules/modal";

const LOOPED_SLIDES = 5;

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: "",
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
    topSwiperOptions() {
      return {
        loop: this.images.length >= 2,
        slidesPerView: this.isLayoutMobile
          ? 1
          : Math.min(this.images.length, 2),
        loopedSlides: LOOPED_SLIDES,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      };
    },
    classModifiers() {
      return {
        "plan-segment-gallery-v2": true,
        [`plan-segment-gallery-v2--${this.theme}`]: this.theme,
        [`plan-segment-gallery-v2--${this.kind}`]: this.kind,
      };
    },
    showNavigation() {
      return this.images.length >= 2;
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    onImageClick(image) {
      if (this.isLayoutMobile) return;
      this.setCurrentModal({
        name: MODALS.PLAN_GALLERY,
        options: {
          current: image,
          kind: this.kind,
          images: this.images,
        },
      });
    },
    imageKey(src, index) {
      return `${src}_${index}`;
    },
  },
};
