export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: -1,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantity: this.value,
    };
  },
  computed: {
    disableMinClassModifier() {
      return this.disabled === true || this.quantity <= this.min
        ? "quantity__button--disabled"
        : null;
    },
    disableMaxClassModifier() {
      if (this.disabled === true) {
        return "quantity__button--disabled";
      }
      if (this.max < 0) {
        return null;
      }
      return this.quantity >= this.max ? "quantity__button--disabled" : null;
    },
  },
  watch: {
    max(val) {
      if (this.value >= val) {
        this.value = val;
        this.quantity = this.value;
      }
    },
    quantity(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    onMinus() {
      if (this.disabled === true) {
        return;
      }
      if (this.quantity <= this.min) return;
      this.quantity -= 1;
    },
    onPlus() {
      if (this.disabled === true) {
        return;
      }
      if (this.max >= 0 && this.quantity >= this.max) return;
      this.quantity += 1;
    },
  },
};
