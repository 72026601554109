import { mapActions, mapGetters } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import { KEY_CODES } from "@/constants";
import creatorV3Person from "../../components/creator-v3-person/creator-v3-person.vue";

const MAX_TOTAL_PERSONS = 9;

const stepMeta = {
  name: "people",
  title: "Uczestnicy",
};
export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin],
  components: {
    creatorV3Person,
  },
  data() {
    return {
      ...stepMeta,
      focusedPerson: null,
    };
  },
  computed: {
    ...mapGetters("creatorV3", [
      "adultQuantity",
      "childrenQuantity",
      "personsQuantity",
    ]),
    personIndexes() {
      return [
        ...this.offer.adults.map((i) => `adult-${i.index}`),
        ...this.offer.children.map((i) => `child-${i.index}`),
      ];
    },
    isValid() {
      if (!this.adultQuantity && !this.childrenQuantity) {
        return false;
      }
      if (
        this.childrenQuantity &&
        !this.offer.children.every((el) => !!el.birthDate)
      ) {
        return false;
      }
      return true;
    },
    adultAddDisabled() {
      return this.personsQuantity >= MAX_TOTAL_PERSONS;
    },
    childrenAddDisabled() {
      return this.personsQuantity >= MAX_TOTAL_PERSONS;
    },
  },
  methods: {
    ...mapActions("creatorV3", [
      "insertAdult",
      "insertChild",
      "removeAdult",
      "removeChild",
    ]),
    keyPressed(e) {
      if (e.keyCode !== KEY_CODES.TAB) return;

      if (this.focusedPerson === null) {
        this.focusedPerson = this.personIndexes[0];
      } else {
        const currentIndex = this.personIndexes.indexOf(this.focusedPerson);
        const index =
          currentIndex === this.personIndexes.length - 1 ? 0 : currentIndex + 1;
        this.focusedPerson = this.personIndexes[index];
      }
      this.$refs[this.focusedPerson][0].onEditName({ outer: true });
    },
  },
  created() {
    document.addEventListener("keydown", this.keyPressed);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyPressed);
  },
};
