import guideSection from "@/components/guide/guide-section/guide-section.vue";
import guideSectionTabs from "@/components/guide/guide-section-tabs/guide-section-tabs.vue";

export default {
  props: {
    category: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sections: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    guideSection,
    guideSectionTabs,
  },
  data() {
    return {
      contentScrolledToBottom: false,
    };
  },
  computed: {
    showSectionTabs() {
      return this.sections.length > 1;
    },
    imageClassModifiers() {
      return {
        "guide-category__image": true,
        [`guide-category__image--${this.category}`]: this.category,
      };
    },
  },
  methods: {
    onTabsToggle(data) {
      this.openedTabs = data.opened;
    },
    onContentScroll(e) {
      const el = e.target;
      this.contentScrolledToBottom =
        el.scrollHeight - el.scrollTop - el.clientHeight < 1;
    },
  },
  mounted() {
    this.$refs.container.addEventListener("scroll", this.onContentScroll);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener("scroll", this.onContentScroll);
  },
};
