import { render, staticRenderFns } from "./creator-v3-location-accommodation.vue?vue&type=template&id=2166c663"
import script from "./creator-v3-location-accommodation.js?vue&type=script&lang=js&external"
export * from "./creator-v3-location-accommodation.js?vue&type=script&lang=js&external"
import style0 from "./creator-v3-location-accommodation.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports