import { mapActions } from "vuex";
import { STATIC_PAGES } from "@/store/modules/staticPage";
import socialIcons from "@/components/social-icons/social-icons.vue";
import newsletterForm from "@/components/newsletter-form/newsletter-form.vue";

export default {
  components: {
    socialIcons,
    newsletterForm,
  },
  data() {
    return {
      links: [
        { name: STATIC_PAGES.IDEA, label: "Idea" },
        { name: STATIC_PAGES.PAYMENTS, label: "Opłaty" },
        { name: STATIC_PAGES.PROJECT, label: "Projekty UE" },
        { name: STATIC_PAGES.TERMS, label: "Regulamin" },
        { name: STATIC_PAGES.PRIVACY_POLICY, label: "Prywatność" },
        { name: STATIC_PAGES.ABOUT_US, label: "Kontakt" },
      ],
    };
  },
  methods: {
    ...mapActions("staticPage", ["setStaticPage"]),
  },
};
