/* eslint-disable arrow-body-style */

import { mapActions } from "vuex";
import { planSectionTitle } from "@/helpers/utils";
import tabs from "@/components/tabs/tabs.vue";
import planSegment from "@/components/plan/plan-segment/plan-segment.vue";
import { SEGMENTS, SEGMENTS_MULTIPLE } from "@/constants";
import { getSegmentAmount } from "@/store/modules/plan";
import stepMixin from "@/mixins/step";

export default {
  mixins: [stepMixin],
  props: {
    plan: {
      type: Object,
      required: true,
    },
    segments: {
      type: Array,
      required: true,
    },
  },
  data() {
    const activeIndex = this.segments.indexOf(
      this.segments.find((i) => i.selected)
    );
    return {
      activeTab: activeIndex !== -1 ? activeIndex : null,
      sectionTitle: planSectionTitle({ segments: this.segments }),
      opened: true,
    };
  },
  components: {
    planSegment,
    tabs,
  },
  computed: {
    isMultiple() {
      return SEGMENTS_MULTIPLE.includes(this.segments[0].kind);
    },
    hasTabs() {
      return this.tabItems.length > 1;
    },
    activeSegment() {
      if (this.isMultiple && this.activeTab) {
        return this.segments[this.activeTab] || null;
      }
      return this.segments[0];
    },
    tabItems() {
      const results = this.segments.map((segment, index) => {
        return {
          name: index,
          title: this.getOptionTitle(index),
        };
      });

      if (results.length === 1 && this.isMultiple && !this.plan.preview) {
        results.push({
          name: 1,
          title: this.getOptionTitle(1),
          disabled: true,
        });
      }

      return results;
    },
    tabsClassModifiers() {
      let unchecked = false;

      if (this.activeSegment !== null) {
        unchecked =
          this.activeSegment.skipped || !getSegmentAmount(this.activeSegment);
      }

      return {
        "tabs--unchecked": unchecked,
      };
    },
  },
  methods: {
    ...mapActions("plan", ["selectSegment"]),
    isSegmentActive(index) {
      return this.activeTab === index;
    },
    getOptionTitle(index) {
      if (this.isMultiple) {
        return `Opcja ${index + 1}`;
      } else if (this.segments[0].kind === SEGMENTS.OTHER) {
        return this.segments[0].title;
      }
      return this.segments[0].kind_display;
    },
    onTabChange(index) {
      this.activeTab = index;
      const segment = this.segments[index];
      this.selectSegment({
        selected: segment,
        segments: this.segments,
      });
    },
  },
};
