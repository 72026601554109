import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classModifiers() {
      return {
        "plan-invoice": true,
        "plan-invoice--preview": this.preview,
      };
    },
  },
};
