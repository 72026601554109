import api from "@/services/api";
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickLogin() {
      this.$emit("changeComponent", "accountLogin");
      if (!this.isCreatorV3) {
        this.$router.replace({ name: "home" });
      }
    },
  },
  created() {
    api.users
      .postActivate({ sign: this.$route.params.sign })
      .then(() => {
        this.formErrors = [];
      })
      .catch(() => {
        this.formErrors.push(
          "Błędny link do aktywowania konta lub link wygasł."
        );
      });
  },
};
