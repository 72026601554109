import { mapActions, mapState } from "vuex";
import userTransactionTile from "@/components/user-transaction-tile/user-transaction-tile.vue";
import userTransactionTileV3 from "@/components/v3/user-transaction-tile-v3/user-transaction-tile-v3.vue";

export default {
  props: {
    isCreatorV3: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    userTransactionTile,
    userTransactionTileV3,
  },
  data() {
    return {
      ready: false,
      currentTransaction: null,
    };
  },
  computed: {
    ...mapState("user", ["transactions"]),
    tileComponent() {
      return this.isCreatorV3 ? "userTransactionTileV3" : "userTransactionTile";
    },
  },
  methods: {
    ...mapActions("user", ["getUserTransactions", "clearUserTransactions"]),
    openTile(transaction) {
      this.currentTransaction = transaction;
      this.$emit("tileSelected", true);
    },
    closeTile() {
      this.currentTransaction = null;
      this.$emit("tileSelected", false);
    },
  },
  mounted() {
    this.getUserTransactions().then(() => {
      this.ready = true;
    });
  },
  beforeDestroy() {
    this.clearUserTransactions();
  },
};
