export const STATIC_PAGES = {
  IDEA: "page-idea",
  PAYMENTS: "page-payments",
  PROJECT: "page-project",
  TERMS: "page-terms",
  PRIVACY_POLICY: "page-privacy-policy",
  HOW_IT_WORKS: "page-how-it-works",
  ABOUT_US: "page-about-us",
};

const STATIC_PAGE_MODAL_NAME = "static-pages";

export default {
  namespaced: true,
  state: {
    currentPage: STATIC_PAGES.PAYMENTS,
  },
  mutations: {
    setStaticPage(state, value) {
      state.currentPage = value;
    },
  },
  actions: {
    setStaticPage({ commit, dispatch, rootState }, value) {
      if (value) {
        commit("setStaticPage", value);
      }

      if (rootState.modal.currentModal.name !== STATIC_PAGE_MODAL_NAME) {
        dispatch(
          "modal/setCurrentModal",
          {
            name: STATIC_PAGE_MODAL_NAME,
            options: {},
          },
          { root: true }
        );
      }
    },
  },
};
