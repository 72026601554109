import { mapGetters } from "vuex";
import formMixin from "@/mixins/form";
import moment from "moment";

export default {
  mixins: [formMixin],
  props: {
    isChild: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    birthDate: {
      type: String,
      required: false,
    },
    showRemoveButton: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      editName: false,
    };
  },
  computed: {
    ...mapGetters("creatorV3", ["availableAvatars"]),
    personBirthDate: {
      get() {
        return this.birthDate;
      },
      set(value) {
        this.$emit("update:birthDate", value);
      },
    },
    personName: {
      get() {
        return this.name;
      },
      set(value) {
        this.$emit("update:name", value);
      },
    },
    personAvatar: {
      get() {
        return this.avatar;
      },
      set(value) {
        this.$emit("update:avatar", value);
      },
    },
    label() {
      const prefix = this.isChild ? "Dziecko" : "Dorosły";
      return `${prefix} ${this.index}`;
    },
    avatarClassModifiers() {
      return {
        "creator-v3-person__avatar": true,
        [`creator-v3-person__avatar--${this.personAvatar}`]: this.personAvatar,
        "creator-v3-person__avatar--disabled": this.setAvatarDisabled,
      };
    },
    setAvatarDisabled() {
      return !this.availableAvatars.length;
    },
    personPlaceholder() {
      return this.isChild ? "np. Adaś" : "np. Sylwia";
    },
    disabledDates() {
      const today = moment();
      const target = today.subtract(18, "years");
      return {
        from: moment().toDate(),
        to: target.toDate(),
      };
    },
  },
  methods: {
    setNextAvailableAvatar() {
      if (this.setAvatarDisabled) {
        return;
      }
      this.personAvatar =
        this.availableAvatars.find((item) => item > this.personAvatar) ||
        this.availableAvatars[0];
    },
    onEditName({ outer = false } = {}) {
      this.editName = true;
      setTimeout(() => {
        const $el = this.$refs.name;
        if ($el) {
          $el.focus();
          if (outer) return;
          this.$emit("focus");
        }
      }, 300);
    },
  },
};
