import { getNightSuffixFromStr } from "@/helpers/utils";

export default {
  props: {
    plan: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      getNightSuffixFromStr,
    };
  },
  computed: {
    isAvailable() {
      return this.plan.is_available;
    },
    link() {
      return {
        name: "page-inspiration-details-slug",
        params: {
          sign: this.plan.slug,
        },
      };
    },
    buttonClassModifiers() {
      return {
        "plans-inspirations-tile__button": true,
        "plans-inspirations-tile__button--active": this.isAvailable,
        "plans-inspirations-tile__button--inactive": !this.isAvailable,
      };
    },
  },
};
