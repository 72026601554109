import { mapActions, mapState, mapGetters } from "vuex";
import { getCreatorSteps } from "@/helpers/creatorV3/utils";
import creatorV3Container from "@/pages/page-creator-v3/components/creator-v3-container/creator-v3-container.vue";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import creatorV3StepsSummaryMixin from "@/helpers/creatorV3/stepsSummaryMixin";
import { exportToJsonFile, importFromJsonFile } from "@/helpers/importExport";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";

const stepMeta = {
  name: "summary-page",
  title: "Podsumowanie",
  titleStepOverview: "Podsumowanie",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3StepsSummaryMixin],
  components: {
    alertBoxV3,
    creatorV3Container,
  },
  data() {
    return {
      ...stepMeta,
    };
  },
  computed: {
    ...mapGetters("user", ["isSuperUser"]),
    ...mapState("creatorV3", ["offer"]),
    steps() {
      return getCreatorSteps()
        .filter((step) => !["summary-page"].includes(step.name))
        .map((step) => {
          return {
            ...step,
            component: () =>
              import(
                `./components/creator-v3-summary-${step.name}/creator-v3-summary-${step.name}.vue`
              ),
          };
        });
    },
  },
  methods: {
    ...mapActions("creatorV3", ["setOffer"]),
    exportOffer() {
      exportToJsonFile({
        data: {
          ...this.offer,
        },
      });
    },
    importOffer() {
      importFromJsonFile({ file: this.$refs.importFile.files[0] }).then(
        (data) => {
          console.log(data);
          this.setOffer(data);
          this.$emit("step", 0);
        }
      );
    },
    navigateToStep(stepName) {
      const stepIndex = getCreatorSteps()
        .map((step) => step.name)
        .indexOf(stepName);
      this.$emit("step", stepIndex);
    },
  },
};
