/* eslint-disable no-console */
import { mapGetters } from "vuex";
import { loadScript } from "@/helpers/utils";
import config from "@/config";

const PROVIDER_GOOGLE = "google-oauth2";
const PROVIDER_FACEBOOK = "facebook";

export default {
  mounted() {
    if (this.isGoogleLoginEnabled) {
      this.initGoogle();
    }
    if (this.isFacebookLoginEnabled) {
      this.initFacebook();
    }
  },
  computed: {
    ...mapGetters("app", ["isGoogleLoginEnabled", "isFacebookLoginEnabled"]),
    isEnabled() {
      return this.isGoogleLoginEnabled || this.isFacebookLoginEnabled;
    },
  },
  methods: {
    initGoogle() {
      loadScript(config.authGoogleSdkUrl, "gapi").then((gapi) => {
        if (gapi.load) {
          gapi.load("auth2", () => {
            this.authGoogle = gapi.auth2.init({
              client_id: config.googleAppClientId,
              scope: "https://www.googleapis.com/auth/plus.login",
            });
            this.setupGoogleButton();
          });
        }
      });
    },
    initFacebook() {
      loadScript(config.authFacebookSdkUrl, "FB").then((FB) => {
        if (FB.init) {
          FB.init({
            appId: config.facebookAppClientId,
            cookie: true,
            xfbml: true,
            version: "v3.2",
          });
          this.setupFacebookButton();
        }
      });
    },
    setupGoogleButton() {
      this.authGoogle.attachClickHandler(
        this.$refs.google,
        {},
        (user) => {
          const payload = {
            provider: PROVIDER_GOOGLE,
            accessToken: user.getAuthResponse().access_token,
          };
          this.$emit("signIn", payload);
        },
        (error) => {
          console.log(JSON.stringify(error, undefined, 2));
        }
      );
    },
    setupFacebookButton() {
      this.$refs.facebook.addEventListener("click", () => {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              const payload = {
                provider: PROVIDER_FACEBOOK,
                accessToken: response.authResponse.accessToken,
              };
              this.$emit("signIn", payload);
            } else {
              console.log(response);
            }
          },
          {
            scope: "email,public_profile",
            return_scopes: true,
          }
        );
      });
    },
  },
};
