import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  props: {
    toggles: {
      type: Array,
      default: () => [],
    },
    onUpdate: Function,
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      componentToggles: this.value,
    };
  },
  methods: {
    onToggleChange(name, checked) {
      if (checked && !this.isToggleChecked(name)) {
        this.componentToggles.push(name);
      } else if (!checked && this.isToggleChecked(name)) {
        const foundIndex = this.componentToggles.indexOf(name);
        this.componentToggles.splice(foundIndex, 1);
      }

      if (typeof this.onUpdate !== "undefined") {
        this.onUpdate(this.componentToggles);
      }
    },
    isToggleChecked(name) {
      return this.componentToggles.includes(name);
    },
    classModifiers(name) {
      return {
        "accordion-toggles__toggle": true,
        "accordion-toggles__toggle--selected": this.isToggleChecked(name),
      };
    },
  },
};
