export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Wyszukaj...",
    },
    value: [String, Number],
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        "plans-inspirations-search-input": true,
        [`plans-inspirations-search-input--${this.theme}`]: this.theme,
        "plans-inspirations-search-input--is-filled": this.value,
        "plans-inspirations-search-input--is-focused": this.focus,
      };
    },
  },
  methods: {
    handleBlur(e) {
      setTimeout(() => {
        this.focus = false;
        this.$emit("blur", e.target.name);
      }, 200);
    },
    handleFocus(e) {
      this.focus = true;
      this.$emit("focus", e.target.name);
    },
    updateValue(e) {
      this.$emit("input", e.target.value);
    },
    resetValue() {
      this.$refs.input.value = "";
      this.$emit("input", "");
    },
  },
};
