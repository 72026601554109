import { mapState } from "vuex";
import { DATE_FORMAT_CREATOR_V3, PEOPLE_TYPE } from "@/constants";
import moment from "moment";
moment.locale("pl");

export default {
  props: {
    person: {
      type: Object,
      required: true,
    },
    showAge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
    title() {
      if (this.person.name) {
        return this.person.name;
      }
      if (this.person.type === PEOPLE_TYPE.ADULT) {
        return `Dorosły ${this.person.index}`;
      }
      return `Dziecko ${this.person.index}`;
    },
  },
  methods: {
    getPersonClass(avatar) {
      return {
        "creator-v3-person-tile__avatar": true,
        [`creator-v3-person-tile__avatar--${avatar}`]: true,
      };
    },
    getAge(birthDate) {
      return moment(birthDate).from(
        this.offer.dateFrom
          ? moment(this.offer.dateFrom, DATE_FORMAT_CREATOR_V3)
          : moment(),
        true
      );
    },
  },
};
