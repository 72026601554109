import { mapActions, mapState } from "vuex";
import { STATIC_PAGES } from "@/store/modules/staticPage";
import tabs from "@/components/tabs/tabs.vue";
import pageAboutUs from "@/pages/page-about-us/page-about-us.vue";
import pageIdea from "@/pages/page-idea/page-idea.vue";
import pagePrices from "@/pages/page-prices/page-prices.vue";
import pageEu from "@/pages/page-eu/page-eu.vue";
import pageTerms from "@/pages/page-terms/page-terms.vue";
import pagePrivacyPolicy from "@/pages/page-privacy-policy/page-privacy-policy.vue";
import innerModalCloseButton from "@/components/generics/inner-modal-close-button/inner-modal-close-button.vue";

export default {
  data() {
    return {
      STATIC_PAGES,
      tabItems: [
        { title: "Idea", name: STATIC_PAGES.IDEA, component: "pageIdea" },
        {
          title: "Opłaty",
          name: STATIC_PAGES.PAYMENTS,
          component: "pagePrices",
        },
        {
          title: "Projekty UE",
          name: STATIC_PAGES.PROJECT,
          component: "pageEu",
        },
        {
          title: "Regulamin",
          name: STATIC_PAGES.TERMS,
          component: "pageTerms",
        },
        {
          title: "Prywatność",
          name: STATIC_PAGES.PRIVACY_POLICY,
          component: "pagePrivacyPolicy",
        },
        {
          title: "Kontakt",
          name: STATIC_PAGES.ABOUT_US,
          component: "pageAboutUs",
        },
      ],
    };
  },
  components: {
    pageAboutUs,
    pageIdea,
    pagePrices,
    pageEu,
    pageTerms,
    pagePrivacyPolicy,
    tabs,
    innerModalCloseButton,
  },
  computed: {
    ...mapState("staticPage", ["currentPage"]),
    currentPageComponent() {
      return this.tabItems.find((i) => i.name === this.currentPage).component;
    },
  },
  methods: {
    ...mapActions("staticPage", ["setStaticPage"]),
  },
};
