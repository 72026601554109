import { get } from "lodash";
import { getRoomStepTitle } from "@/pages/page-creator-v3/steps/creator-v3-room/creator-v3-room.js";

export default {
  methods: {
    getStepTitle(step) {
      if (step.name === "room") {
        return getRoomStepTitle().toUpperCase();
      }
      return (
        get(step, "titleStepOverview", "") || get(step, "title", "")
      ).toUpperCase();
    },
  },
};
