import api from "@/services/api";

export default {
  namespaced: true,
  actions: {
    getCreatorDataFromWidget({ commit }, widgetId) {
      return api.advertising
        .getCreatorDataByWidgetId(widgetId)
        .then((response) => response.data);
    },
  },
};
