import formMixin from "@/mixins/form";
import accordionToggles from "@/pages/page-creator-v3/components/creator-v3-accordion/components/accordion-toggles/accordion-toggles.vue";

export default {
  mixins: [formMixin],
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    locations: {},
    district: {},
    onUpdate: Function,
  },
  components: {
    accordionToggles,
  },
  data() {
    return {
      fields: {
        locationsValue: this.locations,
        districtValue: this.district,
      },
    };
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          locations: this.fields.locationsValue,
          district: this.fields.districtValue,
        });
      },
      deep: true,
    },
  },
};
