import { mapActions, mapState } from "vuex";
import appLoader from "@/components/app-loader/app-loader.vue";

const LOADER_REMOVE_DELAY = 250;

export default {
  components: {
    appLoader,
  },
  computed: {
    ...mapState("app", ["loaderCounter"]),
    isLoading() {
      return this.loaderCounter > 0;
    },
  },
  methods: {
    ...mapActions("app", ["incrLoaderCounter", "decrLoaderCounter"]),
    loaderAdd() {
      this.incrLoaderCounter();
    },
    loaderRemove() {
      setTimeout(() => {
        this.decrLoaderCounter();
      }, LOADER_REMOVE_DELAY);
    },
  },
};
