import { mapActions, mapGetters, mapState } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import appReviewTile from "./components/app-review-tile/app-review-tile.vue";

export default {
  components: {
    appReviewTile,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState("app", ["reviews"]),
    ...mapGetters("app", ["isLayoutMobile"]),
    swiperOptions() {
      const autoplay = this.isLayoutMobile
        ? {
            delay: 5000,
            disableOnInteraction: false,
          }
        : false;
      const slidesPerView = this.isLayoutMobile ? 1 : 2;
      const spaceBetween = this.isLayoutMobile ? 0 : 60;
      return {
        slidesPerView,
        touchRatio: 0.2,
        loop: false,
        autoplay,
        spaceBetween,
        slideToClickedSlide: false,
      };
    },
    lastActiveIndex() {
      return Math.ceil(this.reviews.length / 2) - 1;
    },
    isPrevArrowDisabled() {
      return this.activeIndex === 0 || this.reviews.length === 0;
    },
    isNextArrowDisabled() {
      return (
        this.reviews.length === 0 || this.activeIndex === this.lastActiveIndex
      );
    },
    prevButtonModifiers() {
      return {
        "app-reviews__nav-button": true,
        "app-reviews__nav-button--prev": true,
        "app-reviews__nav-button--disabled": this.isPrevArrowDisabled,
      };
    },
    nextButtonModifiers() {
      return {
        "app-reviews__nav-button": true,
        "app-reviews__nav-button--next": true,
        "app-reviews__nav-button--disabled": this.isNextArrowDisabled,
      };
    },
    showPrevButton() {
      return this.activeIndex > 0;
    },
    showNextButton() {
      return this.activeIndex !== this.lastActiveIndex;
    },
  },
  methods: {
    ...mapActions("app", ["getReviews"]),
    prevSlide() {
      if (this.isPrevArrowDisabled) return;
      this.$refs.swiper.swiper.slidePrev();
      this.activeIndex -= 1;
    },
    nextSlide() {
      if (this.isNextArrowDisabled) return;
      this.$refs.swiper.swiper.slideNext();
      this.activeIndex += 1;
    },
  },
  created() {
    this.getReviews({ cache: true });
  },
};
