import { mapActions, mapState } from "vuex";
import formMixin from "@/mixins/form";
import creatorV3AccommodationMixin from "@/helpers/creatorV3/accommodationMixin";
import creatorV3Accordion from "@/pages/page-creator-v3/components/creator-v3-accordion/creator-v3-accordion.vue";
import creatorV3ActionAlert from "@/pages/page-creator-v3/components/creator-v3-action-alert/creator-v3-action-alert.vue";
import { getLocationTitle, isLastLocation } from "@/helpers/creatorV3/utils";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";

export default {
  mixins: [formMixin, creatorV3AccommodationMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      getLocationTitle,
      isLastLocation,
    };
  },
  components: {
    creatorV3Accordion,
    creatorV3ActionAlert,
    creatorV3Tag,
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
  methods: {
    ...mapActions("creatorV3", ["copyLocationAccommodationOptionsToOthers"]),
    onActionAlertClick() {
      this.copyLocationAccommodationOptionsToOthers(this.location);
    },
  },
};
