import { mapActions, mapState } from "vuex";
import planDetailsSideNav from "@/components/plan/details/plan-details-side-nav/plan-details-side-nav.vue";

export default {
  props: {
    step: {
      type: Number,
      default: -1,
    },
    steps: {
      type: Array,
      require: true,
    },
  },
  components: {
    planDetailsSideNav,
  },
  data() {
    return {
      hamburgerActive: false,
      loginActive: false,
    };
  },
  watch: {
    isLoginRequired(isLoginRequired) {
      this.hamburgerActive = true;
      if (isLoginRequired) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.sideNav) {
              this.$refs.sideNav.setLoginActive();
            }
          }, 0);
        });
      }
    },
  },
  computed: {
    ...mapState("user", ["isLoginRequired"]),
    planDetailsMobileHeaderSideModifiers() {
      return {
        "plan-details-mobile-header__side": true,
        "plan-details-mobile-header__side--login-active": this.loginActive,
      };
    },
  },
  methods: {
    ...mapActions("user", ["loginRequired"]),
    onLoginActive(value) {
      this.loginActive = value;
    },
    onStep(data) {
      this.$emit("step", data);
      this.hamburgerActive = false;
    },
    clickOutsideSideNav() {
      if (this.hamburgerActive) {
        this.hamburgerActive = false;
        this.loginRequired(false);
      }
    },
    hamburgerIconClicked() {
      this.hamburgerActive = !this.hamburgerActive;
    },
  },
};
