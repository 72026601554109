import { isEmpty } from "lodash";
import pagePlanMixin from "@/mixins/pagePlan";
import confirmationPageMixin from "@/pages/confirmation/mixin";
import { SEGMENTS } from "@/constants";
import config from "@/config";

export default {
  mixins: [confirmationPageMixin, pagePlanMixin],
  props: {
    sign: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: "Porównaj pakiety ubezpieczeń",
    };
  },
  data() {
    return {
      allianzMaxDisabled: config.allianzMaxDisabled,
    };
  },
  computed: {
    insuranceSegmentMeta() {
      const segment = ((this.currentPlan || {}).segments || []).find(
        (i) => i.kind === SEGMENTS.INSURANCE && !isEmpty(i.meta)
      );
      return segment ? segment.meta : null;
    },
  },
  methods: {
    coverageModifiers(coverage) {
      return {
        "page-plan-insurance-packages__coverage": true,
        "page-plan-insurance-packages__coverage--enabled": coverage.enabled,
      };
    },
  },
  created() {
    this.getPlanBySign(this.sign).catch(() => {
      this.showPlanDoesNotExistModalIfApiReturnsError();
    });
  },
};
