import ls, { KEYS } from "@/helpers/localStorage";

export const MODALS = {
  PLAN_EXPIRED: "plan-expired",
  PLAN_CHANGED: "plan-changed",
  PLAN_GALLERY: "plan-gallery",
  PLAN_ERROR: "plan-error",
  ROOM_GALLERY: "room-gallery",
  GUIDE_INFO: "guide-info",
  STATIC_PAGES: "static-pages",
  ONBOARDING: "onboarding",
  FULL_REVIEW: "full-review",
  CREATOR_STEPS_OVERVIEW: "creator-steps-overview",
  PLAN_INSURANCE_INFO: "plan-insurance-info",
  HINT: "hint",
  DATE_CALENDAR: "date-calendar",
  VALIDATION: "validation",
};

export const HINT_MODAL = {
  NIGHTS_RANGE: "nights_range",
  CAR_OPTIONS: "car_options",
  FLIGHT_OPTIONS: "flight_options",
  TRANSPORT_OPTIONS: "transport_options",
  LOCATION_NIGHTS: "location_nights",
  ACCOMMODATION_OPTIONS: "accommodation_options",
  TOO_MANY_PEOPLE_IN_ONE_ROOM: "too_many_people_in_one_room",
  ROOM_OPTIONS: "room_options",
};

export const VALIDATION_MODAL = {
  DATES_OUT_OF_SEASON: "dates-out-of-season",
  NO_FLIGHT_OR_HOTEL: "no-flight-or-hotel",
  ZERO_NIGHTS: "zero-nights",
  WIDE_NIGHTS_RANGE: "wide-nights-range",
};

export default {
  namespaced: true,
  state: {
    currentModal: {},
    shownModals: [],
  },
  mutations: {
    setCurrentModal(state, { name, options }) {
      state.currentModal = {
        name,
        options,
      };
    },
    clearModal(state) {
      state.currentModal = {};
    },
    setShownModal(state, type) {
      state.shownModals.push(type);
    },
  },
  actions: {
    setCurrentModal({ commit }, { name, options = {} }) {
      commit("setCurrentModal", { name, options });
    },
    clearModal({ commit }) {
      commit("clearModal");
    },
    setHintModal({ dispatch, commit, state }, { text, type }) {
      const showModal =
        !ls.get(KEYS.getModalShownKey(type)) &&
        !state.shownModals.includes(type);
      if (showModal) {
        dispatch("setCurrentModal", {
          name: MODALS.HINT,
          options: {
            text,
            type,
          },
        });
        commit("setShownModal", type);
      }
    },
    setValidationModal({ dispatch, commit, state }, { options }) {
      const showModal = !state.shownModals.includes(options.type);
      if (showModal) {
        dispatch("setCurrentModal", {
          name: MODALS.VALIDATION,
          options,
        });
        commit("setShownModal", options.type);
      }
    },
  },
};
