import { mapActions, mapState } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import formMixin from "@/mixins/form";
import { getLocationTitle } from "@/helpers/creatorV3/utils";
import { HINT_MODAL, MODALS, VALIDATION_MODAL } from "@/store/modules/modal";
import creatorV3AccommodationMixin from "@/helpers/creatorV3/accommodationMixin";
import creatorV3LocationAccommodation from "./components/creator-v3-location-accommodation/creator-v3-location-accommodation.vue";

const stepMeta = {
  name: "accommodation",
  title: "Nocleg",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3AccommodationMixin, formMixin],
  data() {
    return {
      ...stepMeta,
      showAccommodationPanel: false,
      accommodationPanelViewed: false,
      selectedLocationId: null,
    };
  },
  components: {
    creatorV3LocationAccommodation,
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
    selectedLocation() {
      return this.selectedLocationId !== null
        ? this.offer.locations.find((i) => i.id === this.selectedLocationId)
        : null;
    },
    locationTagInfo() {
      return this.offer.locations.map((item) => {
        return {
          id: item.id,
          title: getLocationTitle({ location: item, extended: true }),
          tags: this.getLocationTags(item),
        };
      });
    },
  },
  methods: {
    ...mapActions("creatorV3", ["analyzeOffer"]),
    ...mapActions("modal", [
      "setHintModal",
      "setValidationModal",
      "clearModal",
    ]),
    getLocationTags(location) {
      return this.getAccommodationTags(location);
    },
    onShowAccommodationPanel(selectedLocationId) {
      this.selectedLocationId = selectedLocationId;
      this.showAccommodationPanel = true;
      this.accommodationPanelViewed = true;
    },
    onStepNext() {
      if (!this.offer.withoutHotel && !this.accommodationPanelViewed) {
        this.setHintModal({
          text: "Pamiętaj, że klikając na ‘Wybierz opcje noclegu’ możesz wybrać standard, położenie, wyposażenie i inne opcje!",
          type: HINT_MODAL.ACCOMMODATION_OPTIONS,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return {
            preventNavigation: true,
          };
        }
      }
      if (this.offer.withoutFlight && this.offer.withoutHotel) {
        this.setValidationModal({
          options: {
            type: VALIDATION_MODAL.NO_FLIGHT_OR_HOTEL,
            contentHtml:
              "Wybrano wyjazd bez lotu i bez noclegu. To zazwyczaj dwa najważniejsze elementy każdej podróży! <br/><br/> Czy na pewno tak ma wyglądać Twój wyjazd? <br/><br/>Jeżeli tak, opisz nam szczegóły w segmencie Dodatkowe informacje",
            rightButtonFn: () => {
              this.clearModal();
            },
            rightButtonText: "Rozumiem",
          },
        });
        if (this.currentModal.name === MODALS.VALIDATION) {
          return {
            preventNavigation: true,
          };
        }
      }
    },
  },
  created() {
    this.analyzeOffer();
  },
};
