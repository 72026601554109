import guideSection from "@/components/guide/guide-section/guide-section.vue";

export default {
  props: {
    sections: {
      type: Array,
      required: true,
      validator: (value) => value.length > 0,
    },
  },
  components: {
    guideSection,
  },
  data() {
    return {
      opened: [],
    };
  },
  methods: {
    tabClassModifiers(section) {
      return {
        "guide-section-tabs__tab": true,
        "guide-section-tabs__tab--opened": this.opened.includes(section.id),
      };
    },
    onToggle(section) {
      const open = !this.opened.includes(section.id);
      if (open) {
        this.opened = [...this.opened, section.id];
      } else {
        this.opened = [...this.opened.filter((id) => id !== section.id)];
      }
      this.$emit("toggle", { opened: this.opened });
    },
    shouldShowSection(section) {
      return this.opened.includes(section.id);
    },
  },
};
