export default {
  props: {
    section: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    iconStyleModifiers(entry) {
      return {
        "background-image": `url(${require(`@/assets/images/guide/icons/${entry.icon}.svg`)})`,
      };
    },
    isIconNotEmpty(entry) {
      return entry.icon !== "brak";
    },
  },
};
