export const DEFAULT_COUNTRY = {
  value: "PL",
  label: "Polska",
};
export const countries = [
  {
    code: "BF",
    name: "Burkina Faso",
  },
  {
    code: "AI",
    name: "Anguilla",
  },
  {
    code: "SZ",
    name: "Suazi",
  },
  {
    code: "NR",
    name: "Nauru",
  },
  {
    code: "LR",
    name: "Liberia",
  },
  {
    code: "LS",
    name: "Lesotho",
  },
  {
    code: "GA",
    name: "Gabon",
  },
  {
    code: "TD",
    name: "Czad",
  },
  {
    code: "MW",
    name: "Malawi",
  },
  {
    code: "HT",
    name: "Haiti",
  },
  {
    code: "FK",
    name: "Falklandy (Malwiny)",
  },
  {
    code: "LI",
    name: "Liechtenstein",
  },
  {
    code: "TC",
    name: "Turks i Caicos",
  },
  {
    code: "TL",
    name: "Timor Wschodni",
  },
  {
    code: "SR",
    name: "Surinam",
  },
  {
    code: "LA",
    name: "Laos",
  },
  {
    code: "DM",
    name: "Dominica",
  },
  {
    code: "BJ",
    name: "Benin",
  },
  {
    code: "HN",
    name: "Honduras",
  },
  {
    code: "PG",
    name: "Papua-Nowa Gwinea",
  },
  {
    code: "GW",
    name: "Gwinea Bissau",
  },
  {
    code: "TO",
    name: "Tonga",
  },
  {
    code: "MO",
    name: "Macau",
  },
  {
    code: "CG",
    name: "Republic of the Congo",
  },
  {
    code: "MR",
    name: "Mauretania",
  },
  {
    code: "BZ",
    name: "Belize",
  },
  {
    code: "VC",
    name: "St. Vincent & Grenadines",
  },
  {
    code: "VG",
    name: "Brytyjskie Wyspy Dziewicze",
  },
  {
    code: "ZM",
    name: "Zambia",
  },
  {
    code: "WS",
    name: "Samoa",
  },
  {
    code: "KN",
    name: "Saint Kitts i Nevis",
  },
  {
    code: "SL",
    name: "Sierra Leone",
  },
  {
    code: "MF",
    name: "Saint-Martin",
  },
  {
    code: "LC",
    name: "Saint Lucia",
  },
  {
    code: "CI",
    name: "Côte d’Ivoire",
  },
  {
    code: "NI",
    name: "Nikaragua",
  },
  {
    code: "ML",
    name: "Mali",
  },
  {
    code: "KY",
    name: "Cayman Islands",
  },
  {
    code: "TW",
    name: "Tajwan",
  },
  {
    code: "GD",
    name: "Grenada",
  },
  {
    code: "BN",
    name: "Brunei",
  },
  {
    code: "IM",
    name: "Wyspa Man",
  },
  {
    code: "RW",
    name: "Rwanda",
  },
  {
    code: "GY",
    name: "Gujana",
  },
  {
    code: "SD",
    name: "Sudan",
  },
  {
    code: "FJ",
    name: "Fidżi",
  },
  {
    code: "MZ",
    name: "Mozambik",
  },
  {
    code: "GH",
    name: "Ghana",
  },
  {
    code: "NE",
    name: "Niger",
  },
  {
    code: "HK",
    name: "Hong Kong",
  },
  {
    code: "CM",
    name: "Kamerun",
  },
  {
    code: "MG",
    name: "Madagaskar",
  },
  {
    code: "MN",
    name: "Mongolia",
  },
  {
    code: "BL",
    name: "St. Barthélemy",
  },
  {
    code: "AW",
    name: "Aruba",
  },
  {
    code: "SV",
    name: "Salwador",
  },
  {
    code: "AG",
    name: "Antigua i Barbuda",
  },
  {
    code: "KW",
    name: "Kuwejt",
  },
  {
    code: "PY",
    name: "Paragwaj",
  },
  {
    code: "CD",
    name: "Demokratyczna Republika Konga",
  },
  {
    code: "GN",
    name: "Gwinea",
  },
  {
    code: "CW",
    name: "Curaçao",
  },
  {
    code: "UY",
    name: "Urugwaj",
  },
  {
    code: "QA",
    name: "Katar",
  },
  {
    code: "MY",
    name: "Malezja",
  },
  {
    code: "GL",
    name: "Grenlandia",
  },
  {
    code: "MU",
    name: "Mauritius",
  },
  {
    code: "JM",
    name: "Jamajka",
  },
  {
    code: "BS",
    name: "Bahamy",
  },
  {
    code: "NZ",
    name: "Nowa Zelandia",
  },
  {
    code: "GM",
    name: "Gambia",
  },
  {
    code: "TT",
    name: "Trynidad i Tobago",
  },
  {
    code: "KH",
    name: "Kambodża",
  },
  {
    code: "BA",
    name: "Bośnia i Hercegowina",
  },
  {
    code: "SX",
    name: "Sint Maarten",
  },
  {
    code: "BD",
    name: "Bangladesz",
  },
  {
    code: "SI",
    name: "Słowenia",
  },
  {
    code: "NG",
    name: "Nigeria",
  },
  {
    code: "AF",
    name: "Afganistan",
  },
  {
    code: "BH",
    name: "Bahrain",
  },
  {
    code: "GI",
    name: "Gibraltar",
  },
  {
    code: "SN",
    name: "Senegal",
  },
  {
    code: "BO",
    name: "Boliwia",
  },
  {
    code: "XK",
    name: "Kosovo",
  },
  {
    code: "BY",
    name: "Białoruś",
  },
  {
    code: "GT",
    name: "Gwatemala",
  },
  {
    code: "ZA",
    name: "Republika Południowej Afryki",
  },
  {
    code: "CN",
    name: "Chiny",
  },
  {
    code: "PF",
    name: "Polinezja Francuska",
  },
  {
    code: "SA",
    name: "Arabia Saudyjska",
  },
  {
    code: "RS",
    name: "Serbia",
  },
  {
    code: "SC",
    name: "Seszele",
  },
  {
    code: "TJ",
    name: "Tadżykistan",
  },
  {
    code: "IQ",
    name: "Irak",
  },
  {
    code: "VN",
    name: "Wietnam",
  },
  {
    code: "KG",
    name: "Kirgistan",
  },
  {
    code: "SG",
    name: "Singapur",
  },
  {
    code: "UG",
    name: "Uganda",
  },
  {
    code: "MD",
    name: "Mołdawia",
  },
  {
    code: "OM",
    name: "Oman",
  },
  {
    code: "EC",
    name: "Ekwador",
  },
  {
    code: "SK",
    name: "Słowacja",
  },
  {
    code: "PK",
    name: "Pakistan",
  },
  {
    code: "ID",
    name: "Indonezja",
  },
  {
    code: "NP",
    name: "Nepal",
  },
  {
    code: "LU",
    name: "Luksemburg",
  },
  {
    code: "FO",
    name: "Wyspy Owcze",
  },
  {
    code: "PH",
    name: "Filipiny",
  },
  {
    code: "EE",
    name: "Estonia",
  },
  {
    code: "PA",
    name: "Panama",
  },
  {
    code: "IR",
    name: "Iran",
  },
  {
    code: "DZ",
    name: "Algieria",
  },
  {
    code: "CR",
    name: "Kostaryka",
  },
  {
    code: "CL",
    name: "Chile",
  },
  {
    code: "JP",
    name: "Japonia",
  },
  {
    code: "CV",
    name: "Republika Zielonego Przylądka",
  },
  {
    code: "KE",
    name: "Kenia",
  },
  {
    code: "MK",
    name: "Macedonia Północna",
  },
  {
    code: "LT",
    name: "Litwa",
  },
  {
    code: "BB",
    name: "Barbados",
  },
  {
    code: "KZ",
    name: "Kazachstan",
  },
  {
    code: "AR",
    name: "Argentyna",
  },
  {
    code: "AM",
    name: "Armenia",
  },
  {
    code: "RO",
    name: "Rumunia",
  },
  {
    code: "AL",
    name: "Albania",
  },
  {
    code: "DO",
    name: "Dominikana",
  },
  {
    code: "AU",
    name: "Australia",
  },
  {
    code: "TN",
    name: "Tunezja",
  },
  {
    code: "JO",
    name: "Jordania",
  },
  {
    code: "TZ",
    name: "Tanzania",
  },
  {
    code: "IN",
    name: "Indie",
  },
  {
    code: "AZ",
    name: "Azerbejdżan",
  },
  {
    code: "BR",
    name: "Brazylia",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
  },
  {
    code: "RU",
    name: "Rosja",
  },
  {
    code: "LV",
    name: "Łotwa",
  },
  {
    code: "CA",
    name: "Kanada",
  },
  {
    code: "TH",
    name: "Tajlandia",
  },
  {
    code: "LK",
    name: "Sri Lanka",
  },
  {
    code: "CH",
    name: "Szwajcaria",
  },
  {
    code: "CO",
    name: "Kolumbia",
  },
  {
    code: "EG",
    name: "Egipt",
  },
  {
    code: "FI",
    name: "Finlandia",
  },
  {
    code: "ME",
    name: "Czarnogóra",
  },
  {
    code: "IL",
    name: "Izrael",
  },
  {
    code: "MX",
    name: "Meksyk",
  },
  {
    code: "PE",
    name: "Peru",
  },
  {
    code: "IS",
    name: "Islandia",
  },
  {
    code: "IE",
    name: "Irlandia",
  },
  {
    code: "MA",
    name: "Maroko",
  },
  {
    code: "BG",
    name: "Bułgaria",
  },
  {
    code: "HU",
    name: "Węgry",
  },
  {
    code: "DK",
    name: "Dania",
  },
  {
    code: "CZ",
    name: "Czechy",
  },
  {
    code: "BE",
    name: "Belgia",
  },
  {
    code: "AT",
    name: "Austria",
  },
  {
    code: "GE",
    name: "Gruzja",
  },
  {
    code: "HR",
    name: "Chorwacja",
  },
  {
    code: "AE",
    name: "Zjednoczone Emiraty Arabskie",
  },
  {
    code: "MT",
    name: "Malta",
  },
  {
    code: "TR",
    name: "Turcja",
  },
  {
    code: "NL",
    name: "Holandia",
  },
  {
    code: "SE",
    name: "Szwecja",
  },
  {
    code: "US",
    name: "Stany Zjednoczone",
  },
  {
    code: "CY",
    name: "Cypr",
  },
  {
    code: "UA",
    name: "Ukraina",
  },
  {
    code: "NO",
    name: "Norwegia",
  },
  {
    code: "GR",
    name: "Grecja",
  },
  {
    code: "FR",
    name: "Francja",
  },
  {
    code: "DE",
    name: "Niemcy",
  },
  {
    code: "PT",
    name: "Portugalia",
  },
  {
    code: "GB",
    name: "Wielka Brytania",
  },
  {
    code: "ES",
    name: "Hiszpania",
  },
  {
    code: "IT",
    name: "Włochy",
  },
  {
    code: "PL",
    name: "Polska",
  },
];
