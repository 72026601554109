import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import { VALIDATION_MODAL } from "@/store/modules/modal";

export default {
  components: {
    creatorV3Tag,
    creatorV3Highlight,
  },
  props: {
    title: {
      type: String,
      default: "Uwaga!",
    },
    type: {
      type: String,
      default: VALIDATION_MODAL.GENERIC,
    },
    contentHtml: {
      type: String,
    },
    options: {
      type: Object,
      default: () => {},
    },
    rightButtonText: String,
    rightButtonFn: {
      type: Function,
      default: () => {},
    },
    leftButtonText: String,
    actionsTitle: String,
    leftButtonFn: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      VALIDATION_MODAL,
    };
  },
  computed: {
    classModifier() {
      return {
        "validation-modal": true,
        [`validation-modal--${this.type}`]: true,
      };
    },
  },
};
