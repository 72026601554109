import questionTag from "@/components/question-tag/question-tag.vue";

export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    disabled: Boolean,
    checked: Boolean,
    incorrect: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hint: String,
    theme: {
      type: String,
      default: "",
    },
  },
  components: {
    questionTag,
  },
  computed: {
    classModifiers() {
      return {
        toggle: true,
        [`toggle-${this.theme}`]: this.theme,
        "toggle--disabled": this.disabled,
        "toggle--checked": this.checked,
        "toggle--is-incorrect": this.incorrect,
      };
    },
  },
  methods: {
    onChange(e) {
      this.$emit("change", e.target.checked);
    },
  },
};
