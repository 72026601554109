import { every, flatten } from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
import { HINT_MODAL, MODALS } from "@/store/modules/modal";
import formMixin from "@/mixins/form";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import creatorV3RoomMixin from "@/helpers/creatorV3/roomMixin";
import {
  getLocationTitle,
  isHotelPreferred,
  hasLocationPeopleAssigned,
} from "@/helpers/creatorV3/utils";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import creatorV3LocationRoomConfiguration from "./components/creator-v3-location-room-configuration/creator-v3-location-room-configuration.vue";
import creatorV3LocationAccommodation from "../creator-v3-accommodation/components/creator-v3-location-accommodation/creator-v3-location-accommodation";

export const getRoomStepTitle = () => {
  const allLocationsHaveHotelPreferred = every(
    store.state.creatorV3.offer.locations,
    (l) => l.accommodation.preference === "hotel"
  );
  const allLocationsHaveApartmentPreferred = every(
    store.state.creatorV3.offer.locations,
    (l) => l.accommodation.preference === "apartment"
  );
  if (allLocationsHaveHotelPreferred) {
    return "Pokój";
  }
  if (allLocationsHaveApartmentPreferred) {
    return "Apartament";
  }
  return "Pokój/Apartament";
};
const stepMeta = {
  name: "room",
  get title() {
    return getRoomStepTitle();
  },
};

const SUGGESTED_MAX_ADULTS_IN_HOTEL_ROOM = 4;
const SUGGESTED_MAX_PEOPLE_IN_HOTEL_ROOM = 6;

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, creatorV3RoomMixin, formMixin],
  components: {
    alertBoxV3,
    creatorV3LocationAccommodation,
    creatorV3LocationRoomConfiguration,
  },
  data() {
    return {
      ...stepMeta,
      isHotelPreferred,
      showRoomDetailsPanel: false,
      roomDetailsPanelViewed: false,
      selectedLocationId: null,
    };
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
    ...mapGetters("creatorV3", ["adultQuantity", "personsQuantity"]),
    allLocationsHaveHotelPreferred() {
      return every(
        store.state.creatorV3.offer.locations,
        (l) => l.accommodation.preference === "hotel"
      );
    },
    allLocationsHaveApartmentPreferred() {
      return every(
        store.state.creatorV3.offer.locations,
        (l) => l.accommodation.preference === "apartment"
      );
    },
    selectedLocation() {
      return this.selectedLocationId !== null
        ? this.offer.locations.find((i) => i.id === this.selectedLocationId)
        : null;
    },
    locationTagInfo() {
      return this.offer.locations.map((item) => {
        return {
          id: item.id,
          roomIds: item.rooms.map((i) => i.id).join(),
          title: getLocationTitle({ location: item, extended: true }),
          isHotelPreferred: isHotelPreferred(item),
          tags: this.getLocationTags(item),
        };
      });
    },
    isValid() {
      return every(this.offer.locations, (location) =>
        hasLocationPeopleAssigned(location)
      );
    },
  },
  methods: {
    ...mapActions("creatorV3", ["analyzeOffer"]),
    ...mapActions("modal", ["setHintModal"]),
    onStepNext() {
      if (!this.roomDetailsPanelViewed) {
        const suffix = "pokojów";
        this.setHintModal({
          text: "Pamiętaj, że klikając na ‘Wybierz opcje i liczbę pokojów/apartamentów’ możesz wybrać konfigurację łóżek, rodzaj wyżywienia oraz wyposażenie!",
          type: HINT_MODAL.ROOM_OPTIONS,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return {
            preventNavigation: true,
          };
        }
      }
    },
    getLocationTags(location) {
      return [
        location.rooms.length,
        ...flatten(
          location.rooms.map((room) => this.getRoomTags({ location, room }))
        ),
      ];
    },
    onShowRoomDetailsPanel(selectedLocationId) {
      this.selectedLocationId = selectedLocationId;
      this.showRoomDetailsPanel = true;
      this.roomDetailsPanelViewed = true;
    },
    onOptionsPanelClose() {
      if (
        isHotelPreferred(this.selectedLocation) &&
        this.selectedLocation.rooms.length === 1 &&
        (this.adultQuantity > SUGGESTED_MAX_ADULTS_IN_HOTEL_ROOM ||
          this.personsQuantity > SUGGESTED_MAX_PEOPLE_IN_HOTEL_ROOM)
      ) {
        this.setHintModal({
          text: "Czy na pewno chcesz umieścić wszystkie osoby w jednym pokoju hotelowym?<br/><br/>Hotele rzadko oferują takie pokoje, a jeżeli takimi dysponują, to często są one droższe niż kilka mniejszych pokojów.<br/><br/>Nasza podpowiedź: rozdziel osoby na dwa lub więcej pokojów!",
          type: HINT_MODAL.TOO_MANY_PEOPLE_IN_ONE_ROOM,
        });
        if (this.currentModal.name === MODALS.HINT) {
          return;
        }
      }
      this.showRoomDetailsPanel = false;
    },
  },
  created() {
    this.analyzeOffer();
  },
};
