export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
