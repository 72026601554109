import multiselect from "@alias-npm/vue-multiselect";
import "@alias-npm/vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    incorrect: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    label: {
      type: String,
      required: false,
    },
    labelProperty: {
      type: String,
      required: true,
    },
    trackBy: {
      type: String,
      required: true,
    },
    value: [String, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
    customLabel: {
      type: Function,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showNoResults: {
      type: Boolean,
      default: false,
    },
    tabindex: Number,
    gtm: {
      type: String,
      default: "",
    },
    adjust: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: "",
    },
  },
  computed: {
    classModifiers() {
      return {
        select: true,
        "select--is-opened": this.isOpened,
        "select--has-label": !!this.label,
        "select--disabled": this.disabled,
        [`select--${this.theme}`]: this.theme,
        "select--is-incorrect": this.incorrect,
        "select--is-filled": this.value,
      };
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    onClose(event) {
      this.isOpened = false;
      this.$emit("close", event);
    },
    onOpen(event) {
      this.isOpened = true;
    },
    updateValue(e) {
      this.$emit("input", e);
    },
  },
};
