export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: String,
    checked: Boolean,
    hint: String,
    theme: {
      type: String,
      default: "default",
    },
  },
  computed: {
    classModifiers() {
      return {
        "toggle-v3": true,
        [`toggle-v3--${this.theme}`]: this.theme,
        "toggle-v3--checked": this.checked,
      };
    },
  },
  methods: {
    onChange(e) {
      this.$emit("change", e.target.checked);
    },
  },
};
