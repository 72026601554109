import { isNumber, isNaN } from "lodash";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
    },
    disabled: Boolean,
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    incorrect: Boolean,
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    placeholder: String,
    maxLength: Number,
    max: Number,
    min: Number,
    value: [String, Number],
    theme: {
      type: String,
      default: "",
    },
    onlyDigits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {
    classModifiers() {
      return {
        "number-input-v3": true,
        [`number-input-v3--${this.theme}`]: this.theme,
        "number-input-v3--incorrect": this.incorrect,
        "number-input-v3--has-label": !!this.label,
      };
    },
    inputBoxModifiers() {
      return {
        "number-input-v3__box--is-filled": this.value,
        "number-input-v3__box--is-focused": this.focus,
        "number-input-v3__box--is-disabled": this.disabled,
      };
    },
    hasIcon() {
      return !!this.$slots.icon;
    },
    hasLabelSuffix() {
      return !!this.$slots["label-suffix"];
    },
  },
  methods: {
    arrowTopClick(e) {
      const value = this.value + 1;
      if (isNumber(this.max) && value > this.max) {
        return;
      }
      this.$emit("input", value);
    },
    arrowBottomClick(e) {
      const value = this.value - 1;
      if (isNumber(this.min) && value < this.min) {
        return;
      }
      this.$emit("input", value);
    },
    updateValue(e) {
      const val = Number(e.target.value);
      if (!isNaN(val)) {
        this.$emit("input", val);
      }
    },
    resetValue() {
      this.$refs.input.value = "";
      this.$emit("input", "");
    },
  },
};
