import { flatten } from "lodash";
import store from "@/store";
import { getBedroomsSuffix } from "@/helpers/utils";

export const ROOM_CONFIGURATION_KIND = {
  SINGLE: "single",
  MULTI: "multi",
};

export const isHotelPreferred = (location) =>
  location.accommodation.preference === "hotel";

export const isApartmentPreferred = (location) =>
  location.accommodation.preference === "apartment";

export const getRoomConfigurationKindLabel = ({ kind, location }) => {
  const prefix =
    kind === ROOM_CONFIGURATION_KIND.SINGLE ? "Jeden" : "Więcej niż jeden";
  const suffix = isHotelPreferred(location) ? "pokój" : "apartament";
  return `${prefix} ${suffix}`;
};

export const BED_CONFIGURATION_KIND = {
  ANY: "any",
  DOUBLE: "double",
  TWO: "two",
  DOUBLE_PLUS: "double-plus",
  TWO_PLUS: "two-plus",
};

export const BED_CONFIGURATION_KIND_LABEL = {
  [BED_CONFIGURATION_KIND.ANY]: "Dowolna konfiguracja",
  [BED_CONFIGURATION_KIND.DOUBLE]: "Jedno podwójne",
  [BED_CONFIGURATION_KIND.TWO]: "Dwa pojedyncze",
  [BED_CONFIGURATION_KIND.DOUBLE_PLUS]: "Jedno podwójne + dostawka/i",
  [BED_CONFIGURATION_KIND.TWO_PLUS]: "Dwa pojedyncze + dostawka/i",
};

export const ROOM_EQUIPMENTS = [
  {
    title: "Widok",
    name: "view",
  },
  {
    title: "Taras",
    name: "balcony",
  },
  {
    title: "Cichy pokój",
    name: "silence",
  },
  {
    title: "Sejf",
    name: "safe",
  },
  {
    title: "Mini bar",
    name: "mini-bar",
  },
  {
    title: "Czajnik /ekspres",
    name: "coffee-maker",
  },
  {
    title: "Wanna",
    name: "bath",
  },
  {
    title: "Żelazko",
    name: "iron",
  },
  {
    title: "Aneks kuchenny",
    name: "kitchenette",
  },
  {
    title: "Podwyższony standard",
    name: "higher",
  },
];

export const ROOM_MEALS = [
  {
    title: "Bez wyżywienia",
    name: "nomeal",
    code: "ro",
  },
  {
    title: "Śniadania",
    name: "breakfast",
    code: "bb",
  },
  {
    title: "Śniadania i kolacje",
    name: "half-board",
    code: "hb",
  },
  {
    title: "Śniadania, obiady i kolacje",
    name: "full-board",
    code: "fb",
  },
  {
    title: "All-Inclusive",
    name: "all-inclusive",
    code: "ai",
  },
];

export const getRoomMealsOptions = (location) => {
  if (isApartmentPreferred(location)) {
    return [
      ROOM_MEALS.find((i) => i.name === "nomeal"),
      ROOM_MEALS.find((i) => i.name === "breakfast"),
    ];
  }
  const offer = store.state.creatorV3.offer;
  const settings = store.state.creatorV3.offerSettingsPerLocation;
  const index = offer.locations.map((i) => i.id).indexOf(location.id);
  const params = settings[index];
  return index !== -1 && typeof params !== "undefined"
    ? ROOM_MEALS.filter((i) => params.room_boards.includes(i.code))
    : ROOM_MEALS;
};

export default {
  methods: {
    getRoomAccordionItems({ location, room }) {
      const firstComponent = isHotelPreferred(location)
        ? {
            title: "Łóżka",
            component: "accordionRoomBeds",
            props: {
              bedConfiguration: room.bedConfiguration,
              onUpdate: (val) => {
                room.bedConfiguration = val.bedConfiguration;
              },
              tags: this.getRoomTagsBeds(room),
            },
          }
        : {
            title: "Sypialnie",
            component: "accordionRoomBedrooms",
            props: {
              bedrooms: room.bedrooms,
              onUpdate: (val) => {
                room.bedrooms = val.bedrooms;
              },
              tags: this.getRoomTagsBedrooms(room),
            },
          };
      return [
        firstComponent,
        {
          title: "Wyżywienie",
          component: "accordionToggles",
          props: {
            toggles: getRoomMealsOptions(location),
            onUpdate: (val) => {
              room.meals = val;
            },
            value: room.meals,
            tags: this.getRoomTagsMeals(room),
          },
        },
        {
          title: "Wyposażenie",
          component: "accordionToggles",
          props: {
            toggles: ROOM_EQUIPMENTS,
            onUpdate: (val) => {
              room.equipment = val;
            },
            value: room.equipment,
            tags: this.getRoomTagsEquipment(room),
          },
        },
        {
          title: "Specjalne potrzeby",
          component: "formInputV3",
          props: {
            label:
              "Napisz nam, jeśli zależy Ci na rzeczy, której nie było na liście.",
            placeholder: "np. szlafrok",
            name: "creator-room-special-needs",
            onUpdate: (val) => {
              room.others = val;
            },
            value: room.others,
            tags: this.getRoomTagsOthers(room),
          },
        },
      ];
    },
    getRoomTagsBedrooms(room) {
      return [`${room.bedrooms} ${getBedroomsSuffix(room.bedrooms)}`];
    },
    getRoomTagsBeds(room) {
      return [BED_CONFIGURATION_KIND_LABEL[room.bedConfiguration]];
    },
    getRoomTagsMeals(room) {
      return room.meals.map(
        (item) => ROOM_MEALS.find((type) => type.name === item).title
      );
    },
    getRoomTagsEquipment(room) {
      return room.equipment.map(
        (item) => ROOM_EQUIPMENTS.find((type) => type.name === item).title
      );
    },
    getRoomTagsOthers(room) {
      return [room.others].filter((i) => !!i);
    },
    getRoomTags({ location, room }) {
      return flatten(
        this.getRoomAccordionItems({ location, room }).map(
          (item) => item.props.tags
        )
      );
    },
  },
};
