export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    sign: {
      type: String,
      required: true,
    },
  },
  computed: {
    imageClassModifiers() {
      return {
        "guide-navigation-tile-small__image": true,
        [`guide-navigation-tile-small__image--${this.category}`]: this.category,
      };
    },
    routeTo() {
      if (this.category === "home") {
        return {
          name: "page-plan-guide",
          params: {
            sign: this.sign,
          },
        };
      }
      return {
        name: "page-plan-guide-category",
        params: {
          category: this.category,
          sign: this.sign,
        },
      };
    },
  },
};
