import config from "@/config";

export const EVENTS = {
  CREATOR_STEP_NAVIGATION: "creator_step_navigation",
  CREATOR_SUBMISSION_SUCCESS: "creator_submission_success",
  CREATOR_SUBMISSION_ERROR: "creator_submission_error",
  GENERATE_LEAD: "generate_lead",
};

let localDataLayer = [];

export default {
  makeAnalyticsAllowed() {
    console.log(`GTM TRACKING - initialising. Enabled: ${config.gtm.enabled}`);
    window.dataLayer = window.dataLayer || [];
    localDataLayer.forEach((data) => {
      window.dataLayer.push(data);
    });
    localDataLayer = window.dataLayer;
  },
  send(data) {
    if (!config.gtm.enabled) {
      console.log(`GTM TRACKING - disabled. Payload: ${JSON.stringify(data)}`);
      return;
    }
    localDataLayer.push(data);
  },
  trackCreatorStep({ name, index = 0, previous = null }) {
    this.send({
      event: EVENTS.CREATOR_STEP_NAVIGATION,
      name,
      index,
      previous,
    });
  },
  trackCreatorSubmissionSuccess() {
    this.send({
      event: EVENTS.CREATOR_SUBMISSION_SUCCESS,
    });
    this.send({
      event: EVENTS.GENERATE_LEAD,
    });
  },
  trackCreatorSubmissionError() {
    this.send({
      event: EVENTS.CREATOR_SUBMISSION_ERROR,
    });
  },
};
