import Vue from "vue";
import vClickOutside from "v-click-outside";
import VueLazyload from "vue-lazyload";
import moment from "moment";
import * as Sentry from "@sentry/vue";
import { sync } from "vuex-router-sync";
import store from "@/store";
import router from "@/router";
import app from "@/app/app.vue";
import config from "@/config";
import tagManager from "@/services/gtm";

tagManager.makeAnalyticsAllowed();

Vue.use(VueLazyload, {
  observer: true,
  attempt: 1,
  listenEvents: [
    "scroll",
    "wheel",
    "mousewheel",
    "resize",
    "animationend",
    "transitionend",
    "touchmove",
    "touchend",
  ],
});
Vue.use(vClickOutside);

Vue.filter("date", (val) => {
  return val ? moment(val).format("DD.MM.yyyy") : null;
});

Vue.config.productionTip = false;
sync(store, router);

if (config.sentry.enabled) {
  if (config.sentry.debug) {
    console.log(`Installing sentry at ${config.sentry.dsn}`);
  }
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    debug: config.sentry.debug,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", config.domainFull, /^\//],
      }),
    ],
    sampleRate: 1.0,
    tracesSampleRate: 0,
  });
}
/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(app),
});
