import { flatten } from "lodash";

const CAR_TYPES = [
  {
    title: "Mały",
    name: "small",
  },
  {
    title: "Średni",
    name: "medium",
  },
  {
    title: "Duży",
    name: "big",
  },
  {
    title: "Premium",
    name: "premium",
  },
  {
    title: "SUV",
    name: "suv",
  },
  {
    title: "Kombi",
    name: "combi",
  },
  {
    title: "Rodzinny",
    name: "family",
  },
  {
    title: "Kabriolet",
    name: "cabriolet",
  },
  {
    title: "Hybrydowy / Elektryczny",
    name: "hybrid-electric",
  },
  {
    title: "Sugestia Getaway",
    name: "suggestion",
  },
];

const CAR_EQUIPMENTS = [
  {
    title: "Nawigacja",
    name: "navigation",
  },
  {
    title: "Fotelik dla dziecka",
    name: "baby-seat",
  },
  {
    title: "Dodatkowy kierowca",
    name: "additional-driver",
  },
  {
    title: "Minimum 4 drzwi",
    name: "four-doors",
  },
  {
    title: "Automatyczna skrzynia biegów",
    name: "automatic-transmission",
  },
];

export default {
  methods: {
    getCarAccordionItems(car) {
      return [
        {
          title: "Wiek kierowcy",
          component: "accordionDriverAge",
          props: {
            tags: this.getCarTagsAge(car),
            driverAge30to65: car.driverAge30to65,
            driverAgeCustom: car.driverAgeCustom,
            onUpdate: (val) => {
              car.driverAge30to65 = val.driverAge30to65;
              car.driverAgeCustom = val.driverAgeCustom;
            },
          },
        },
        {
          title: "Rodzaj",
          component: "accordionToggles",
          props: {
            toggles: CAR_TYPES,
            onUpdate: (val) => {
              car.type = val;
            },
            value: car.type,
            tags: this.getCarTagsCarTypes(car),
          },
        },
        {
          title: "Wyposażenie",
          component: "accordionToggles",
          props: {
            toggles: CAR_EQUIPMENTS,
            onUpdate: (val) => {
              car.additions = val;
            },
            value: car.additions,
            tags: this.getCarTagsCarAdditions(car),
          },
        },
        {
          title: "Specjalne potrzeby",
          component: "formInputV3",
          props: {
            label:
              "Napisz nam, jeśli zależy Ci na rzeczy, której nie było na liście.",
            placeholder: "np. bagażnik na narty",
            name: "creator-car-special-needs",
            onUpdate: (val) => {
              car.others = val;
            },
            value: car.others,
            tags: this.getCarTagsOthers(car),
          },
        },
      ];
    },
    getCarTagsAge(car) {
      return car.driverAge30to65 ? ["30-65"] : [car.driverAgeCustom];
    },
    getCarTagsOthers(car) {
      return [car.others];
    },
    getCarTagsCarTypes(car) {
      return car.type.map(
        (item) => CAR_TYPES.find((type) => type.name === item).title
      );
    },
    getCarTagsCarAdditions(car) {
      return car.additions.map(
        (item) => CAR_EQUIPMENTS.find((type) => type.name === item).title
      );
    },
    getCarTags(car) {
      return flatten(
        this.getCarAccordionItems(car).map((item) => item.props.tags)
      );
    },
    isDriverAgeValid(car) {
      return (
        !!car.driverAge30to65 ||
        (!car.driverAge30to65 &&
          !!car.driverAgeCustom &&
          car.driverAgeCustom >= 18 &&
          car.driverAgeCustom < 100)
      );
    },
  },
};
