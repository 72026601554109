import { mapGetters, mapState } from "vuex";
import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import formMixin from "@/mixins/form";
import { REGEXP_EMAIL } from "@/constants";

const stepMeta = {
  name: "contact",
  title: "Dane kontaktowe",
  titleStepOverview: "Kontakt",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, formMixin],
  data() {
    return {
      ...stepMeta,
    };
  },
  computed: {
    ...mapState("user", ["details"]),
    ...mapGetters("user", ["isLoggedIn"]),
    isValid() {
      if (!this.offer.contact.firstName) {
        return false;
      }
      if (!REGEXP_EMAIL.test(this.offer.contact.email)) {
        return false;
      }
      if (!this.offer.acceptTermCondition) {
        return false;
      }
      return true;
    },
    rulesLabel() {
      const terms = this.$router.resolve({ name: "static-terms" }).resolved
        .path;
      const privacyPolicy = this.$router.resolve({
        name: "static-privacy-policy",
      }).resolved.path;
      return `Akceptuję <a href="${terms}" target="_blank">Regulamin</a>\
        i <a href="${privacyPolicy}" target="_blank">Politykę Prywatności</a>`;
    },
  },
  watch: {
    "offer.contact.email"(value) {
      this.offer.contact.email = value.trim();
    },
  },
  created() {
    if (this.isLoggedIn && !this.offer.contact.email) {
      this.offer.contact.firstName = this.details.first_name || "";
      this.offer.contact.email = this.details.email || "";
    }
  },
};
