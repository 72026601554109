import { mapGetters } from "vuex";
import ls, { KEYS } from "@/helpers/localStorage";

export default {
  data() {
    return {
      simple: ls.get(KEYS.HIDE_INSPIRATION_CTA_WIDGET, false),
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
  },
  methods: {
    onClose() {
      this.simple = true;
      ls.set(KEYS.HIDE_INSPIRATION_CTA_WIDGET, true);
    },
  },
};
