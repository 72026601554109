import { mapActions, mapGetters } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { SEGMENTS } from "@/constants";

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    kind: {
      type: String,
      required: false,
      default: "",
    },
    current: {},
  },
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    const index = this.current ? this.images.indexOf(this.current) : 0;
    return {
      swiperOptions: {
        initialSlide: index !== -1 ? index : 0,
        loop: this.images.length >= 2,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutMobile"]),
    showNav() {
      return this.images.length > 1;
    },
    classModifiers() {
      return {
        "plan-segment-gallery-preview": true,
        "plan-segment-gallery-preview--dark": this.kind === SEGMENTS.CAR,
      };
    },
  },
  methods: {
    ...mapActions("modal", ["clearModal"]),
    imageKey(src, index) {
      return `${src}_${index}`;
    },
  },
};
