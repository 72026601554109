import { mapGetters } from "vuex";
import formMixin from "@/mixins/form";
import creatorV3CarMixin from "@/helpers/creatorV3/carMixin";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import creatorV3OptionsPanel from "@/pages/page-creator-v3/components/creator-v3-options-panel/creator-v3-options-panel.vue";
import creatorV3LocationsNights from "../creator-v3-locations-nights/creator-v3-locations-nights.vue";
import creatorV3LocationTransport from "../creator-v3-location-transport/creator-v3-location-transport.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import {
  isLocationRemovable,
  isLocationSwappableToBottom,
  isLocationSwappableToTop,
  isLastLocation,
  isFirstLocation,
  getLocationTitle,
  getLocationNightsDisplay,
} from "@/helpers/creatorV3/utils";
import { TRANSPORT_TO_TYPE_LABEL } from "@/constants";
export default {
  mixins: [creatorV3CarMixin, formMixin],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  components: {
    creatorV3Tags,
    creatorV3LocationTransport,
    creatorV3LocationsNights,
    creatorV3Highlight,
    creatorV3OptionsPanel,
  },
  data() {
    return {
      showNightsPanel: false,
      showTransportPanel: false,
      isLocationRemovable,
      isLocationSwappableToBottom,
      isLocationSwappableToTop,
      isFirstLocation,
      isLastLocation,
      getLocationTitle,
      getLocationNightsDisplay,
    };
  },
  computed: {
    ...mapGetters("creatorV3", ["areLocationNightRangesValid"]),
    tags() {
      const results = [TRANSPORT_TO_TYPE_LABEL[this.location.transport]];
      return this.location.hasCar
        ? [...results, ...this.getCarTags(this.location.car)]
        : results;
    },
  },
  methods: {
    onShowTransportPanel() {
      this.showTransportPanel = true;
      this.$emit("transportPanelShown");
    },
  },
};
