import { FLIGHT_CLASS_LABEL } from "@/constants";

export default {
  computed: {
    tagsCityOfDeparture() {
      return this.offer.cityOfDeparture.split(",").filter((i) => !!i);
    },
    tagsCityOfArrival() {
      return this.offer.cityOfArrival.split(",").filter((i) => !!i);
    },
    tagsFlightClass() {
      return [
        FLIGHT_CLASS_LABEL[this.offer.flightClass],
        this.offer.flightDirectly ? "Lot bezpośredni" : "",
      ].filter((i) => !!i);
    },
    tagsFlightLines() {
      return this.offer.flightLines.split(",").filter((i) => !!i);
    },
    tagsFlight() {
      return [
        ...this.tagsFlightClass,
        ...this.tagsCityOfArrival,
        ...this.tagsFlightLines,
      ];
    },
  },
};
