var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.classModifiers,attrs:{"data-segment":_vm.segment.id}},[(_vm.showTitle)?_c('h4',{staticClass:"plan-segment__title",domProps:{"innerHTML":_vm._s(_vm.segment.title)}}):_vm._e(),_c('div',{staticClass:"plan-segment__gallery"},[(_vm.showGallery)?_c('plan-segment-gallery-v2',{attrs:{"kind":_vm.segment.kind,"theme":"plan","images":_vm.segment.images}}):_vm._e(),(_vm.showGalleryLink || _vm.segment.localisation_link)?_c('div',{staticClass:"grid-x"},[(_vm.showGalleryLink)?_c('div',{staticClass:"cell small-6"},[_c('plan-segment-link',{attrs:{"link":"#","icon":"magnifier","shouldPreventDefault":true},on:{"click":_vm.onGalleryLinkClick}},[_vm._v(" Kliknij aby powiększyć ")])],1):_vm._e(),(_vm.segment.localisation_link)?_c('div',{staticClass:"cell small-6"},[_c('plan-segment-link',{attrs:{"link":_vm.segment.localisation_link,"icon":"position","theme":_vm.localisationLinkTheme}},[_vm._v(" Zobacz na Mapach Google ")])],1):_vm._e()]):_vm._e()],1),(_vm.segment.description)?_c('div',{staticClass:"plan-segment__description",domProps:{"innerHTML":_vm._s(_vm.segment.description)}}):_vm._e(),_c('div',{staticClass:"plan-segment__fields"},[_vm._l((_vm.segment.extra_fields),function(field,index){return [(field.kind === 'text')?[_c('div',{key:`${index}-a`,staticClass:"plan-segment__field-title",attrs:{"data-field":field.name}},[_c('span',{class:_vm.fieldLabelModifiers(field)},[_vm._v(_vm._s(field.label))])]),_c('div',{key:`${index}-b`,staticClass:"plan-segment__field plan-segment__field--input"},[_c('form-input',{attrs:{"incorrect":!!field.error,"required":field.required,"name":field.name,"placeholder":"Podaj informacje"},on:{"input":function($event){return _vm.changeRequiredField({
                value: $event,
                required: field.required,
                segment: _vm.segment,
                fieldIndex: index,
              })}},model:{value:(field.markup.selected),callback:function ($$v) {_vm.$set(field.markup, "selected", $$v)},expression:"field.markup.selected"}}),(field.error)?_c('div',{staticClass:"plan-segment__field-error"},[_vm._v(" Pole wymagane ")]):_vm._e()],1)]:_vm._e(),(field.kind === 'checkbox')?[_c('div',{key:`${index}-a`,staticClass:"plan-segment__field plan-segment__field--margin-top-bottom",attrs:{"data-field":field.name}},[_c('form-toggle',{attrs:{"incorrect":!!field.error,"required":field.required,"name":field.name,"label":field.label},on:{"change":function($event){return _vm.changeRequiredField({
                value: $event,
                required: field.required,
                segment: _vm.segment,
                fieldIndex: index,
              })}},model:{value:(field.markup.selected),callback:function ($$v) {_vm.$set(field.markup, "selected", $$v)},expression:"field.markup.selected"}}),(field.error)?_c('div',{staticClass:"plan-segment__field-error plan-segment__field-error--margin-top"},[_vm._v(" Pole wymagane ")]):_vm._e()],1)]:_vm._e(),(field.kind === 'radio')?[_c('div',{key:`${index}-a`,staticClass:"plan-segment__field-title plan-segment__field-title--radio",attrs:{"data-field":field.name}},[_c('span',{class:_vm.fieldLabelModifiers(field)},[_vm._v(_vm._s(field.label))])]),_c('div',{key:`${index}-b`,staticClass:"plan-segment__field"},[_vm._l((field.markup.options),function(option,radioIndex){return _c('div',{key:radioIndex,staticClass:"plan-segment__field plan-segment__field--radio"},[_c('form-radio',{attrs:{"incorrect":!!field.error,"required":field.required,"name":field.name,"value":option.value,"label":option.label},on:{"change":function($event){return _vm.changeRequiredField({
                  value: $event,
                  required: field.required,
                  segment: _vm.segment,
                  fieldIndex: index,
                })}},model:{value:(field.markup.selected),callback:function ($$v) {_vm.$set(field.markup, "selected", $$v)},expression:"field.markup.selected"}})],1)}),(field.error)?_c('div',{staticClass:"plan-segment__field-error plan-segment__field-error--margin-top"},[_vm._v(" Pole wymagane ")]):_vm._e()],2)]:_vm._e(),(field.kind === 'select')?[_c('div',{key:`${index}-a`,staticClass:"plan-segment__field-title",attrs:{"data-field":field.name}},[_c('span',{class:_vm.fieldLabelModifiers(field)},[_vm._v(_vm._s(field.label))])]),_c('div',{key:`${index}-b`,staticClass:"plan-segment__field plan-segment__field--select"},[_c('form-select',{attrs:{"incorrect":!!field.error,"required":field.required,"options":field.markup.options,"track-by":"label","labelProperty":"label"},on:{"input":function($event){return _vm.changeRequiredField({
                value: $event,
                required: field.required,
                segment: _vm.segment,
                fieldIndex: index,
              })}},model:{value:(field.markup.selected),callback:function ($$v) {_vm.$set(field.markup, "selected", $$v)},expression:"field.markup.selected"}}),(field.error)?_c('div',{staticClass:"plan-segment__field-error"},[_vm._v(" Pole wymagane ")]):_vm._e()],1)]:_vm._e()]})],2),(_vm.segment.link)?_c('plan-segment-link',{attrs:{"link":_vm.segment.link,"icon":"document"}},[_vm._v(" "+_vm._s(_vm.segment.link_title)+" ")]):_vm._e(),(_vm.segment.can_be_deactivated)?_c('div',{staticClass:"plan-segment__field plan-segment__field--exclude"},[_c('form-checkbox',{attrs:{"theme":"big","name":`${_vm.segment.id}_deactivate`,"label":"Wyjazd bez tej usługi"},model:{value:(_vm.segment.skipped),callback:function ($$v) {_vm.$set(_vm.segment, "skipped", $$v)},expression:"segment.skipped"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }