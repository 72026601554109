import { mapActions, mapState } from "vuex";
import modal from "@/components/generics/modal/modal.vue";
import planInfoModal from "@/components/plan/plan-info-modal/plan-info-modal.vue";
import planSegmentGalleryPreviewModal from "@/components/plan/plan-segment-gallery-preview-modal/plan-segment-gallery-preview-modal.vue";
import guideAttachHomeInfo from "@/components/guide/guide-attach-home-info/guide-attach-home-info.vue";
import staticPagesModal from "@/components/static-pages-modal/static-pages-modal.vue";
import onboardingModal from "@/components/onboarding-modal/onboarding-modal.vue";
import fullReviewModal from "@/components/full-review-modal/full-review-modal.vue";
import planInsuranceAgreementModal from "@/components/plan/plan-insurance-agreement-modal/plan-insurance-agreement-modal.vue";
import creatorV3StepsOverview from "@/pages/page-creator-v3/components/creator-v3-steps-overview/creator-v3-steps-overview.vue";
import hintV3Modal from "@/components/v3/hint-v3-modal/hint-v3-modal.vue";
import dateCalendarModal from "@/components/v3/date-calendar-modal/date-calendar-modal.vue";
import dateCalendarModalFooter from "@/components/v3/date-calendar-modal/components/date-calendar-modal-footer/date-calendar-modal-footer.vue";
import validationModal from "@/components/v3/validation-modal/validation-modal.vue";
import { MODALS } from "@/store/modules/modal";

export default {
  components: {
    modal,
    guideAttachHomeInfo,
    planInfoModal,
    planSegmentGalleryPreviewModal,
    staticPagesModal,
    onboardingModal,
    fullReviewModal,
    creatorV3StepsOverview,
    planInsuranceAgreementModal,
    hintV3Modal,
    dateCalendarModal,
    dateCalendarModalFooter,
    validationModal,
  },
  computed: {
    ...mapState("modal", ["currentModal"]),
  },
  data() {
    return {
      MODALS,
    };
  },
  methods: {
    ...mapActions("modal", ["clearModal"]),
    onClose() {
      this.clearModal();
    },
    isCurrent(target) {
      return this.currentModal.name && this.currentModal.name === target;
    },
  },
};
