import uaParser from "ua-parser-js";

const uaParsed = uaParser(window.navigator.userAgent);
export const isStandalone = !!window.navigator.standalone;
export const isIos = uaParsed && uaParsed.os && uaParsed.os.name === "iOS";
export const isMacOs = uaParsed && uaParsed.os && uaParsed.os.name === "Mac OS";
export const isWindows =
  uaParsed && uaParsed.os && uaParsed.os.name.includes("Windows");
export const isAndroid =
  uaParsed && uaParsed.os && uaParsed.os.name === "Android";
export const isIE =
  uaParsed &&
  uaParsed.browser &&
  (uaParsed.browser.name === "IE" || uaParsed.browser.name === "IEMobile");
export const isEdge =
  uaParsed && uaParsed.browser && uaParsed.browser.name === "Edge";
export const iOSWrapper =
  window.navigator.userAgent &&
  window.navigator.userAgent.includes("iOSWrapper");
export const isTablet =
  uaParsed && uaParsed.device && uaParsed.device.type === "tablet";
