function set(key, value, ttl) {
  const ttlMs = ttl * 1000;
  const data = {
    value,
    expires_at: (new Date().getTime() + ttlMs) / 1,
  };
  localStorage.setItem(key.toString(), JSON.stringify(data));
}

function get(key, defaultValue) {
  try {
    const data = JSON.parse(localStorage.getItem(key.toString()));
    if (data !== null) {
      if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
        localStorage.removeItem(key.toString());
      } else {
        return data.value;
      }
    }
  } catch (e) {
    localStorage.removeItem(key.toString());
  }
  return defaultValue || null;
}

function del(key) {
  localStorage.removeItem(key.toString());
}

export default {
  set,
  get,
  del,
};

export const KEYS = {
  SHOW_ONBOARDING: "show-onboarding",
  SHOW_GUIDE_INFO: "show-guide-info",
  TOKEN: "token",
  PLANS_WITH_ACCESS_GRANTED: "plans-with-access-granted",
  HIDE_INSPIRATION_CTA_WIDGET: "hide-inspiration-cta-widget",
  getModalShownKey: (type) => {
    return `hint-modal-${type}:shown`;
  },
};
