import { render, staticRenderFns } from "./question-tag-text.vue?vue&type=template&id=b7b8e650"
import script from "./question-tag-text.js?vue&type=script&lang=js&external"
export * from "./question-tag-text.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports