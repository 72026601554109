import innerModalCloseButton from "@/components/generics/inner-modal-close-button/inner-modal-close-button.vue";

export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  components: {
    innerModalCloseButton,
  },
};
