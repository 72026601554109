export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    sign: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconClassModifiers() {
      return {
        "guide-navigation-tile__icon": true,
        [`guide-navigation-tile__icon--${this.category}`]: this.category,
      };
    },
  },
};
