import { mapActions } from "vuex";
import formMixin from "@/mixins/form";
import confirmationPageMixin from "../mixin";

const STEPS = {
  INITIAL: "INITIAL",
  SUBMITTED: "SUBMITTED",
};

export default {
  mixins: [formMixin, confirmationPageMixin],
  data() {
    return {
      STEPS,
      currentStep: this.$route.query.sign ? STEPS.INITIAL : STEPS.SUBMITTED,
      reasons: ["Zmiana planów", "To był test Getaway", "Za drogo"],
      submit: false,
      form: {
        notes: "",
        reason: "",
      },
    };
  },
  computed: {
    isFormDisabled() {
      return (!this.form.reason && !this.form.notes) || this.submit;
    },
  },
  methods: {
    ...mapActions("plan", ["submitNotInterestedFeedback"]),
    submitFeedback() {
      if (this.submit) return true;
      this.submit = true;

      this.submitNotInterestedFeedback({
        sign: this.$route.query.sign,
        payload: this.form,
      })
        .then(() => {
          this.currentStep = STEPS.SUBMITTED;
          this.$router.replace({ query: null });
        })
        .catch(() => {
          this.submit = false;
        });
    },
  },
};
