import { mapActions, mapState } from "vuex";
import {
  getNightSuffixFromStr,
  stripTags,
  preloadPlanImages,
} from "@/helpers/utils";
import tabs from "@/components/tabs/tabs.vue";
import questionTag from "@/components/question-tag/question-tag.vue";
import planSegmentGalleryV2 from "@/components/plan/plan-segment-gallery-v2/plan-segment-gallery-v2.vue";
import { PLAN_TYPE } from "@/constants";

export default {
  components: {
    planSegmentGalleryV2,
    questionTag,
    tabs,
  },
  props: {
    sign: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    if (!this.currentPlan.id) {
      return {};
    }
    const description = stripTags(this.currentPlan.description);
    const title = `${this.currentPlan.title} - ${this.currentPlan.subtitle}`;
    const image = this.currentPlan.background;
    let link = [];
    if (this.currentPlan.type === PLAN_TYPE.INSPIRATION) {
      const resolved = this.$router.resolve({
        name: "page-inspiration-details-slug",
        params: {
          sign: this.currentPlan.slug,
        },
      });
      link = [
        {
          rel: "canonical",
          href: window.location.origin + resolved.route.path,
        },
      ];
    }
    return {
      title,
      link,
      meta: [
        {
          property: "og:title",
          content: title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: description,
          vmid: "og:description",
        },
        {
          property: "og:image",
          content: image,
          vmid: "og:image",
        },
        {
          name: "description",
          content: description,
          vmid: "description",
        },
        {
          name: "twitter:title",
          content: title,
          vmid: "twitter:title",
        },
        {
          name: "twitter:description",
          content: description,
          vmid: "twitter:description",
        },
        {
          name: "twitter:image",
          content: image,
          vmid: "twitter:image",
        },
      ],
    };
  },
  data() {
    return {
      activeSegmentId: null,
      getNightSuffixFromStr,
    };
  },
  computed: {
    ...mapState("plan", ["currentPlan"]),
    tabItems() {
      return this.currentPlan.segments.map((i) => {
        return {
          name: i.id.toString(),
          title: i.title,
        };
      });
    },
    activeSegment() {
      if (this.activeSegmentId === null) return null;
      return this.currentPlan.segments.find(
        (i) => i.id.toString() === this.activeSegmentId
      );
    },
  },
  methods: {
    ...mapActions("plan", ["getInspirationBySign", "clearPlan"]),
    setActiveTab(value) {
      this.activeSegmentId = value;
    },
    headlineIconModifiers(headline) {
      return {
        "page-inspiration-details__headline-icon": true,
        [`page-inspiration-details__headline-icon--${headline.icon}`]:
          headline.icon,
      };
    },
  },
  watch: {
    currentPlan() {
      preloadPlanImages({ plan: this.currentPlan });
    },
  },
  beforeDestroy() {
    this.clearPlan();
  },
  created() {
    this.getInspirationBySign(this.sign).catch((e) => {
      console.error(e);
      this.$router.push({
        name: "not-found",
        params: {
          pathMatch: this.$route.path.split("/").slice(1),
        },
        query: this.$route.query,
        hash: this.$route.hash,
      });
    });
  },
};
