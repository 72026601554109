import formMixin from "@/mixins/form";
import {
  BED_CONFIGURATION_KIND,
  BED_CONFIGURATION_KIND_LABEL,
} from "@/helpers/creatorV3/roomMixin";

export default {
  mixins: [formMixin],
  props: {
    bedConfiguration: {},
    onUpdate: Function,
  },
  data() {
    return {
      BED_CONFIGURATION_KIND_LABEL,
      choices: [
        BED_CONFIGURATION_KIND.ANY,
        BED_CONFIGURATION_KIND.DOUBLE,
        BED_CONFIGURATION_KIND.TWO,
        BED_CONFIGURATION_KIND.DOUBLE_PLUS,
        BED_CONFIGURATION_KIND.TWO_PLUS,
      ],
      fields: {
        bedConfigurationValue: this.bedConfiguration,
      },
    };
  },
  watch: {
    fields: {
      handler() {
        this.onUpdate({
          bedConfiguration: this.fields.bedConfigurationValue,
        });
      },
      deep: true,
    },
  },
};
