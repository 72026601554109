export default {
  props: {
    isValid: {
      type: Boolean,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      default: false,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    previousArrowModifiers() {
      return {
        "plan-details-arrow-nav__arrow": true,
        "plan-details-arrow-nav__arrow--previous": true,
      };
    },
    nextArrowModifiers() {
      return {
        "plan-details-arrow-nav__arrow": true,
        "plan-details-arrow-nav__arrow--disabled": !this.isValid,
        "plan-details-arrow-nav__arrow--next": true,
      };
    },
  },
  methods: {
    onPrevious() {
      this.$emit("previous");
    },
    onNext() {
      if (!this.isValid) return;
      this.$emit("next");
    },
  },
};
