export default {
  data() {
    return {
      socials: [
        { href: "https://www.facebook.com/getawaypl", name: "facebook" },
        { href: "https://twitter.com/getaway_pl", name: "twitter" },
        { href: "https://www.instagram.com/getawaypl/", name: "instagram" },
        {
          href: "https://www.linkedin.com/company/getaway-pl/",
          name: "linkedin",
        },
      ],
    };
  },
  methods: {
    socialLinkClassModifiers(link) {
      return {
        "social-icons__link": true,
        [`social-icons__link--${link.name}`]: link.name,
      };
    },
  },
};
