import { mapActions } from "vuex";

export default {
  props: {
    isIos: {
      type: Boolean,
      default: false,
    },
    isAndroid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("modal", ["clearModal"]),
    onClose() {
      this.clearModal();
    },
  },
};
