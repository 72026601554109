import { mapActions, mapGetters, mapState } from "vuex";
import { MODALS } from "@/store/modules/modal";
import creatorV3Progress from "../creator-v3-progress/creator-v3-progress.vue";

export default {
  components: {
    creatorV3Progress,
  },
  props: {
    isFormValid: Boolean,
  },
  computed: {
    ...mapGetters("app", ["isInAppFBBrowser"]),
    ...mapState("creatorV3", ["indexes"]),
    ...mapState("modal", ["currentModal"]),
    showPrevBtn() {
      return !this.isOpenStepsModal && this.indexes.current !== 0;
    },
    showNextBtn() {
      return !this.isOpenStepsModal && !this.isLastStep;
    },
    showSubmitBtn() {
      return !this.isOpenStepsModal && this.isLastStep;
    },
    isLastStep() {
      return this.indexes.current === this.indexes.total - 1;
    },
    prevBtnClassModifier() {
      return {
        "creator-v3-footer__prev-step": true,
        "creator-v3-footer__prev-step--hidden": !this.showPrevBtn,
      };
    },
    isOpenStepsModal() {
      return this.currentModal.name === MODALS.CREATOR_STEPS_OVERVIEW;
    },
    classModifier() {
      return {
        "creator-v3-footer": true,
        "creator-v3-footer--fb-browser": this.isInAppFBBrowser,
        "creator-v3-footer--not-overlayed": this.isOpenStepsModal,
        "creator-v3-footer--overlayed": !this.isOpenStepsModal,
      };
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal", "clearModal"]),
    ...mapActions("creatorV3", ["markStepOverviewNavigationHintAsDisplayed"]),
    toggleStepsOverview() {
      if (this.isOpenStepsModal) {
        this.clearModal();
        this.markStepOverviewNavigationHintAsDisplayed(true);
      } else {
        this.setCurrentModal({
          name: MODALS.CREATOR_STEPS_OVERVIEW,
          options: {
            navigateToStepIndexFn: (index) => {
              this.clearModal();
              this.$emit("step", index);
            },
          },
        });
      }
    },
  },
};
