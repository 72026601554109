import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import { FLIGHT_CLASS, FLIGHT_CLASS_LABEL } from "@/constants";

export default {
  mixins: [formMixin],
  data() {
    return {
      FLIGHT_CLASS,
      FLIGHT_CLASS_LABEL,
      choices: [FLIGHT_CLASS.ECONOMIC, FLIGHT_CLASS.BUSINESS],
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
};
