import creatorV3StepMixin from "@/helpers/creatorV3/stepMixin";
import formMixin from "@/mixins/form";

const stepMeta = {
  name: "extra-information",
  title: "Dodatkowe informacje",
  titleStepOverview: "Informacje",
};

export default {
  ...stepMeta,
  mixins: [creatorV3StepMixin, formMixin],
  data() {
    return {
      ...stepMeta,
    };
  },
  computed: {
    isValid() {
      return true;
    },
  },
};
