import { debounce } from "lodash";
import { mapActions, mapGetters } from "vuex";
import multiselect from "@alias-npm/vue-multiselect";
import alertBoxV3 from "../alert-box-v3/alert-box-v3.vue";

const DEBOUNCE_TIME = 500;
const MIN_QUERY_LENGTH = 3;

export default {
  components: {
    multiselect,
    alertBoxV3,
  },
  props: {
    value: String,
  },
  data() {
    return {
      isLoading: false,
      selectedAirlines: [],
      airlines: [],
      maxSelectedAirlines: 5,
      lastQuery: "",
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutDesktop"]),
    showNoResults() {
      return (
        !this.isLoading && (this.lastQuery || "").length >= MIN_QUERY_LENGTH
      );
    },
    classModifiers() {
      return {
        "form-airline-picker-v3": true,
        "form-airline-picker-v3__multiselect": true,
        "form-airline-picker-v3--is-empty": this.selectedAirlines.length === 0,
        "form-airline-picker-v3--is-filled": this.selectedAirlines.length > 0,
      };
    },
    reachedMax() {
      return this.selectedAirlines.length >= this.maxSelectedAirlines;
    },
  },
  methods: {
    ...mapActions("searching", ["getAirlines"]),
    selectedAirlinesHandler() {
      const value = this.selectedAirlines.map((airline) => airline.name).join();
      this.$emit("input", value);
    },
    clearAll() {
      this.selectedAirlines = [];
    },
    remove(index) {
      this.selectedAirlines.splice(index, 1);
    },
    onOpen() {
      const input = this.$refs.multiselect.$el.querySelector(
        ".multiselect__input"
      );
      if (!input) {
        return;
      }
      this.$nextTick(() => {
        input.value = this.lastQuery;
      });
    },
  },
  created() {
    if (this.value) {
      this.selectedAirlines = this.value.split(",").map((flightLine) => {
        return {
          name: flightLine,
        };
      });
    }
    this.asyncFind = debounce(
      (query) => {
        if (!query || query.length < MIN_QUERY_LENGTH) return;
        this.lastQuery = query;
        this.isLoading = true;
        this.getAirlines(query).then((response) => {
          this.airlines = response.items;
          this.isLoading = false;
        });
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
  },
  watch: {
    selectedAirlines: {
      handler: "selectedAirlinesHandler",
    },
  },
};
