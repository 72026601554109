import creatorV3Container from "@/pages/page-creator-v3/components/creator-v3-container/creator-v3-container.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import creatorV3Separator from "@/pages/page-creator-v3/components/creator-v3-separator/creator-v3-separator.vue";
import creatorV3Tags from "@/pages/page-creator-v3/components/creator-v3-tags/creator-v3-tags.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    creatorV3Container,
    creatorV3Highlight,
    creatorV3Separator,
    creatorV3Tags,
  },
  data() {
    return {
      locations: [
        {
          dateStart: moment().add(5, "days"),
          events: [
            {
              label: "wylot",
              location: "Lotnisko Chopina w Warszawie (WAW)",
              timeLabel: this.getTimeOfDay(moment().add(5, "days").hour(12)),
            },
            {
              label: "lądowanie",
              location: "Port lotniczy Miami (MIA)",
              timeLabel: "po południu",
            },
          ],
        },
        {
          dateStart: moment().add(15, "days"),
          events: [
            {
              label: "przejazd do",
              location: "Orlando",
              timeLabel: "1 noc",
            },
          ],
        },
      ],
      totalPrice: 29345.05,
      currency: "PLN",
    };
  },
  computed: {
    ...mapGetters("creatorV3", ["personsQuantity"]),
    pricePerPerson() {
      return Math.round(this.totalPrice / this.personsQuantity, 2);
    },
  },
  methods: {
    getDayOfWeek(date) {
      return moment(date).format("dddd");
    },
    getTimeOfDay(date) {
      const hour = moment(date).hour();
      if (hour <= 6 || hour >= 21) {
        return "w nocy";
      } else if (hour >= 19) {
        return "wieczorem";
      } else if (hour > 12) {
        return "po południu";
      } else if (hour === 12) {
        return "w południe";
      } else {
        return "rano";
      }
    },
  },
};
