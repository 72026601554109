export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredErrors() {
      return [...new Set(this.errors)];
    },
  },
};
