import moment from "moment";
import vueDatepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";
import { DATE_FORMAT } from "@/constants";

export default {
  components: {
    vueDatepicker,
  },
  props: {
    name: String,
    label: {
      type: String,
      required: false,
    },
    value: [String, Date],
    placeholder: {
      type: String,
      default: "dd/mm/rrrr",
    },
    theme: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "year",
    },
    disabledPicker: {
      type: Boolean,
      default: false,
    },
    disabledDates: {},
    required: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pl,
      wasCalendarOpened: false,
    };
  },
  computed: {
    datepickerModifier() {
      return {
        "datepicker-v3": true,
        "datepicker-v3--has-label": !!this.label,
        [`datepicker-v3--${this.theme}`]: this.theme,
      };
    },
    datepickerBoxModifier() {
      return {
        "datepicker-v3__box": true,
        "datepicker-v3--is-filled": this.value,
        [`datepicker-v3--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    updateValue(e) {
      const value = moment(e).format(DATE_FORMAT);
      this.$emit("input", value);
    },
    click() {
      if (this.disabledPicker) {
        this.$emit("click");
      }
    },
    onOpen() {
      if (this.wasCalendarOpened) {
        return;
      }
      const calendars = this.$refs.calendar.$el.querySelectorAll(
        ".datepicker-v3__calendar.vdp-datepicker__calendar"
      );
      const input = this.$refs.calendar.$el.querySelector(
        ".datepicker-v3__value"
      );
      for (const calendar of calendars) {
        const closeButton = document.createElement("button");
        closeButton.innerText = "Zamknij";
        closeButton.classList.add("datepicker-v3__calendar-close-button");
        calendar.appendChild(closeButton);
        closeButton.addEventListener("click", () => {
          calendar.style.display = "none";
          input.blur();
        });
      }
      this.wasCalendarOpened = true;
    },
  },
  mounted() {
    const input = document.querySelector(".datepicker-v3__value");
    if (!input) {
      return;
    }
    input.setAttribute("autocomplete", "off");
  },
};
