import { mapState } from "vuex";
import formMixin from "@/mixins/form";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import { FLIGHT_CLASS, FLIGHT_CLASS_LABEL } from "@/constants";

export default {
  mixins: [formMixin],
  components: {
    creatorV3Highlight,
  },
  data() {
    return {
      FLIGHT_CLASS,
      FLIGHT_CLASS_LABEL,
      maxNumberOfCities: 5,
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer"]),
  },
  methods: {
    getCounterText(cities) {
      return `${cities.split(",").length} / ${this.maxNumberOfCities}`;
    },
  },
};
