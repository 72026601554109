import { stripTags, loadScript, preloadPlanImages } from "@/helpers/utils";
import { mapActions, mapGetters, mapState } from "vuex";
import flowMixin from "@/mixins/flow";
import pagePlanMixin from "@/mixins/pagePlan";
import planPinMixin from "@/mixins/planPin";
import scrollTo from "@/helpers/scrollTo";
import config from "@/config";
import appSimpleHeaderV3 from "@/components/v3/app-simple-header-v3/app-simple-header-v3.vue";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import planOverview from "./components/plan-overview/plan-overview.vue";

export default {
  mixins: [flowMixin, pagePlanMixin, planPinMixin],
  props: {
    sign: {
      type: String,
      required: false,
    },
    transactionId: {
      type: String,
      required: false,
    },
  },
  metaInfo() {
    if (!this.currentPlan.id) {
      return {};
    }
    const description = stripTags(this.currentPlan.description);
    const title = `${this.currentPlan.title}`;
    const image = this.currentPlan.background;
    return {
      title,
      meta: [
        {
          property: "og:title",
          content: title,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: description,
          vmid: "og:descriptio",
        },
        {
          property: "og:image",
          content: image,
          vmid: "og:image",
        },
        {
          name: "description",
          content: description,
          vmid: "description",
        },
        {
          name: "twitter:title",
          content: title,
          vmid: "twitter:title",
        },
        {
          name: "twitter:description",
          content: description,
          vmid: "twitter:description",
        },
        {
          name: "twitter:image",
          content: image,
          vmid: "twitter:image",
        },
      ],
    };
  },
  components: {
    appSimpleHeaderV3,
    creatorV3Highlight,
    planOverview,
  },
  data() {
    return {
      overview: false,
    };
  },
  watch: {
    currentPlan(value) {
      if (value) {
        this.checkPlanAccess(value);
        preloadPlanImages({ plan: value });
      }
    },
  },
  computed: {
    ...mapState("app", ["width"]),
    ...mapGetters("plan", [
      "groupedPlanSegmentsBySection",
      "currentPlanSegments",
    ]),
    classModifiers() {
      return {
        "page-plan-details-v3": true,
        "page-plan-details-v3--no-access": this.guardCheckRequired,
        "page-plan-details-v3--not-available": this.isPlanNotAvailable,
        "page-plan-details-v3--overview": this.overview,
      };
    },
  },
  methods: {
    ...mapActions("plan", ["getTransactionById", "clearTransaction"]),
    ...mapActions("modal", ["setCurrentModal", "clearModal"]),
    showPlanOverview() {
      this.overview = true;
      scrollTo(0);
    },
  },
  created() {
    loadScript(config.captchaUrl, "grecaptcha");
    if (this.sign) {
      this.getPlanBySign(this.sign)
        .then((data) => {
          if (data.is_available) {
            this.setupPlanChecker();
          }
        })
        .catch(() => {
          this.showPlanDoesNotExistModalIfApiReturnsError();
        });
    } else if (this.transactionId) {
      this.getTransactionById(this.transactionId);
    }
  },
  beforeDestroy() {
    this.clearTransaction();
  },
};
