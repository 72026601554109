import { mapActions, mapState, mapGetters } from "vuex";
import creatorV3Highlight from "@/pages/page-creator-v3/components/creator-v3-highlight/creator-v3-highlight.vue";
import formRangeInput from "@/components/generics/range-input/main";
import { getLocationTitle } from "@/helpers/creatorV3/utils";
import { HINT_MODAL } from "@/store/modules/modal";
import alertBoxV3 from "@/components/v3/alert-box-v3/alert-box-v3.vue";
import creatorV3Tag from "@/pages/page-creator-v3/components/creator-v3-tag/creator-v3-tag.vue";

export default {
  components: {
    alertBoxV3,
    creatorV3Highlight,
    creatorV3Tag,
    formRangeInput,
  },
  data() {
    return {
      minRangeInputValue: 1,
      getLocationTitle,
    };
  },
  computed: {
    ...mapGetters("creatorV3", [
      "areLocationNightRangesValid",
      "nightsDisplay",
      "locationsNightsTotalFrom",
      "locationsNightsTotalTo",
    ]),
    ...mapState("creatorV3", ["offer"]),
    maxRangeInputValue() {
      return this.offer.nightsRange[1] - this.offer.locations.length + 1;
    },
  },
  methods: {
    ...mapActions("modal", ["setHintModal"]),
  },
  created() {
    this.setHintModal({
      text: "Możesz tu zmienić preferowaną liczbę noclegów w każdym z wybranych miejsc. Pamiętaj jednak że zakres liczby noclegów musi zgadzać się z zakresem wybranym w kartce Data!\n",
      type: HINT_MODAL.LOCATION_NIGHTS,
    });
  },
};
