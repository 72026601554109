const stepMixin = {
  created() {
    this.validateStep();
  },
  computed: {
    isValid() {
      return true;
    },
  },
  watch: {
    isValid: "validateStep",
  },
  methods: {
    validateStep() {
      this.$emit("validate", this.isValid);
    },
  },
};

export default stepMixin;
