import { mapActions } from "vuex";
import moment from "moment";
import { range } from "lodash";
import { MODALS } from "@/store/modules/modal";

export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOverflowing: false,
    };
  },
  computed: {
    dateFormatted() {
      return moment(this.review.issue_date).locale("pl").format("MMMM Y");
    },
    starRange() {
      return range(0, this.review.stars);
    },
    classModifiers() {
      return {
        "app-review-tile": true,
        "app-review-tile--first": this.isFirst,
        "app-review-tile--lsat": this.isLast,
      };
    },
  },
  methods: {
    ...mapActions("modal", ["setCurrentModal"]),
    checkIsOverflowing() {
      this.$nextTick(() => {
        const el = this.$refs.text;
        if (!el) {
          this.isOverflowing = false;
          return;
        }
        this.isOverflowing = el.clientHeight < el.scrollHeight;
      });
    },
    showFullReviewPopup() {
      this.setCurrentModal({
        name: MODALS.FULL_REVIEW,
        options: {
          review: this.review,
        },
      });
    },
  },
  mounted() {
    this.checkIsOverflowing();
  },
};
